import styled from 'styled-components';
import { DefaultButtonStyle } from "../../styles/GlobalStyles";

const Button = styled.button`
    ${DefaultButtonStyle}
`

const NavButton = (props) => {
    return (
        <Button className={props.className} onClick={props.onClick}>
            {props.children}
        </Button>
    )
}

export default NavButton;