import { Message } from "./message";
import { Timer } from "./timer";

export class BlindTest {
    constructor(dataset, socket, n_music = 10, time_per_music = 20) {
        this.songDataset = dataset;
        this.init('', [], n_music, time_per_music)
        this.timeouts = []
        this.currentAudio = null
        this.currentMusic = null
        this.socket = socket
    }

    init(category, list_players, n_music = 10, time_per_music = 20) {
        // Playlist setting
        this.category = category
        this.n_music = n_music

        // Durations setting
        this.time_per_music = time_per_music
        this.setupGameDuration = 5000
        this.scoreDuration = 5000
        this.musicDuration = this.time_per_music * 1000

        // Scores initialization
        this.scores = {}
        list_players.forEach(playerName => {
            this.scores[playerName] = 0;
        });

        // Music selection
        this.musics = this.sampleMusics(this.n_music)

        this.timer = new Timer(this.time_per_music)
        return this
    }

    sampleMusics(n, musics_to_avoid = []) {
        let tmp_musics = []
        while (tmp_musics.length < n) {
            let sampled_song = this.songDataset[Math.floor(Math.random() * this.songDataset.length)]
            if (!musics_to_avoid.includes(sampled_song) && !tmp_musics.includes(sampled_song)) {
                tmp_musics.push(sampled_song)
            }
        }
        return tmp_musics
    }

    makeProposals(current_music, n = 4) {
        let random_proposals = this.sampleMusics(n, [current_music])
        let position_current = Math.floor(Math.random() * n)
        this.currentMusic = position_current;
        random_proposals[position_current] = current_music
        return random_proposals
    }

    run(callbackTimer, callbackViewer, callbackProposals, callbackScores, callAtTheEnd) {
        let readyMessage = new Message('Ready ?', 5000, callbackViewer)
        readyMessage.showMessage()

        let three = new Message('3', 1000, callbackTimer)
        this.callLater(() => { three.showMessage() }, 1000)

        let two = new Message('2', 1000, callbackTimer)
        this.callLater(() => { two.showMessage() }, 2000)

        let one = new Message('1', 1000, callbackTimer)
        this.callLater(() => { one.showMessage() }, 3000)

        let go = new Message('GO', 1000, callbackViewer)
        this.callLater(() => { go.showMessage() }, 4000)

        let delMsg = new Message('', 1000, callbackTimer)
        this.callLater(() => { delMsg.showMessage() }, 4000)

        for (let i = 0; i < this.n_music; i++) {

            let artist_question = new Message("Quelle est cette musique ? (" + (i + 1) + "/" + this.n_music + ")", this.musicDuration, callbackViewer)
            this.callLater(() => { artist_question.showMessage() }, this.setupGameDuration + i * (this.musicDuration + this.scoreDuration))
            this.play(this.musics[i], i, callbackProposals, callbackScores)
        }
        this.callLater(() => callAtTheEnd(), this.setupGameDuration + this.n_music * (this.musicDuration + this.scoreDuration))
        return this
    }

    play(music, n, callbackProposals, callbackScores) {
        console.log("/songs/" + music['id'] + ".mp3");
        const audio = new Audio("https://play.unboared.com/songs/" + music['id'] + ".mp3");
        this.callLater(() => { callbackProposals(this.makeProposals(music)); this.hideResult(); this.currentAudio = audio; audio.play() }, this.setupGameDuration + n * (this.musicDuration + this.scoreDuration))
        this.callLater(() => {
            audio.pause();
            audio.currentTime = 0;
        }, this.setupGameDuration + (n + 1) * (this.musicDuration + this.scoreDuration))
        this.callLater(() => { this.timer.startTimer() }, this.setupGameDuration + n * (this.musicDuration + this.scoreDuration))
        // this.callLater(() => { this.showResult(music, n) }, this.setupGameDuration + n * (this.musicDuration + this.scoreDuration) + this.musicDuration)
        this.callLater(() => { callbackScores(); this.showResult(music, this.currentMusic) }, this.setupGameDuration + this.musicDuration + n * (this.musicDuration + this.scoreDuration) + 500)
    }

    hideResult() {
        for (let i = 0; i < 4; i++) {
            document.getElementsByClassName(`proposal-${i}`)[0].style.opacity = "0.9"
        }
    }

    showResult(music, proposalID) {
        document.getElementById("timer").innerHTML = `
        <div>
            <img src="${music.picture}" alt="" height="300px"/>
        </div>
        `
        for (let i = 0; i < 4; i++) {
            if (i !== proposalID) {
                document.getElementsByClassName(`proposal-${i}`)[0].style.opacity = "0.2"
            }
        }
    }

    callLater(call, delay) {
        this.timeouts.push(setTimeout(call, delay))
    }

    clearTimeouts() {
        for (var i = 0; i < this.timeouts.length; i++) {
            clearTimeout(this.timeouts[i]);
        }
    }

    clear() {
        this.timer.clear();
        this.clearTimeouts();
        if (this.currentAudio != null) {
            this.currentAudio.pause();
            this.currentAudio.currentTime = 0;
        }
    }


}