import { DISC_SIZE } from '../config/Const'
// import disc1 from '../assets/img/IronSpeedDiscSprite-1.png'
// import disc2 from '../assets/img/IronSpeedDiscSprite-2.png'
// import disc3 from '../assets/img/IronSpeedDiscSprite-3.png'
// import disc4 from '../assets/img/IronSpeedDiscSprite-4.png'
import { getDiscImg } from './discImg'

// const disc_imgs = [disc1, disc2, disc3, disc4]

class Disc {
    constructor(ctx, id, color, discX, senseDiscX, discY, senseDiscY) {
        this.ctx = ctx
        this.id = id
        this.color = color
        this.w = DISC_SIZE
        this.h = DISC_SIZE
        this.x0 = discX
        this.x = this.x0
        this.maxX = 0
        this.y0 = discY
        this.y = this.y0
        this.maxY = 0
        this.senseDiscX = senseDiscX
        this.vx0 = 20
        this.vx = this.senseDiscX * this.vx0
        this.senseDiscY = senseDiscY
        this.vy0 = 20
        this.vy = this.senseDiscY * this.vy0
        this.deviation = 0

        // this.img = new Image()
        // this.img.src = disc_imgs[this.id - 1];
        this.img = getDiscImg(this.color, this.w * 4, this.h)
        this.img.width = this.w * 4
        this.img.height = this.h
        console.log(this.img.width)
        this.img.frames = 4
        this.img.frameIndex = 0
        this.img.onload = () => this.draw()

        this.tick = 0
    }

    draw() {
        this.ctx.drawImage(
            this.img,
            this.img.frameIndex * this.img.width / this.img.frames,
            0,
            this.img.width / this.img.frames,
            this.img.height,
            this.x,
            this.y,
            this.w,
            this.h
        )
    }

    animate() {
        if (this.vx !== 0 || this.vy !== 0) {
            this.tick++
            if (this.tick > 0.4) {
                this.tick = 0
                this.img.frameIndex++
            }
            if (this.img.frameIndex >= this.img.frames) {
                this.img.frameIndex = 0
            }
        }
        this.draw()
    }

    move() {
        this.x += (this.vx + (this.senseDiscY * this.deviation))
        this.y += (this.vy + (this.senseDiscX * this.deviation))
        if ((this.vx > 0 && this.x >= this.maxX) ||
            (this.vx < 0 && this.x <= this.maxX) ||
            (this.vy > 0 && this.y >= this.maxY) ||
            (this.vy < 0 && this.y <= this.maxY)) {
            this.vx = 0
            this.vy = 0
            this.deviation = 0
        }
    }

    resetDisc() {
        this.x = this.x0
        this.y = this.y0
        this.tick = 0
        this.img.frameIndex = 0
        this.vx = this.senseDiscX * this.vx0
        this.vy = this.senseDiscY * this.vy0
        this.deviation = 0
    }
}

export default Disc;

