import { css } from 'styled-components';

export const Flex = ({ alignItem, justifyContent }) => css`
  display: flex;
  align-items: ${alignItem};
  justify-content: ${justifyContent};
`;

export const Overlay = ({ hexaColor }) => css`
  background-color: ${hexaColor}0a;
`

export const CenterDiv = () => css`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

export const BackgroundCover = (backgroundImg) => css`
  background-image: url(${backgroundImg});  
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
`