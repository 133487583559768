import { ReactComponent as ButtonPadLeft } from '../../assets/Button_Left.svg';
import { ReactComponent as ButtonPadRight } from '../../assets/Button_Right.svg';
import { ReactComponent as ButtonPadDown } from '../../assets/Button_Down.svg';
import { ReactComponent as ButtonPadUp } from '../../assets/Button_Up.svg';
import { ReactComponent as ButtonPadOK } from '../../assets/Button_Ok.svg';

const Pad = ({ pressOK, pressUp, pressDown, pressLeft, pressRight }) => {
    return (
        <svg x="-44" width="300" height="320" xmlns="http://www.w3.org/2000/svg">
            <ButtonPadUp onTouchStart={pressUp} x="43.5" width="214" height="121" />
            <ButtonPadLeft onTouchStart={pressLeft} y="43" width="121" height="214" />
            <ButtonPadRight onTouchStart={pressRight} x="180" y="43" width="121" height="214" />
            <ButtonPadDown onTouchStart={pressDown} x="43.5" y="178.7" width="214" height="121" />
            <ButtonPadOK onTouchStart={pressOK} x="113" y="112" width="76" height="76" />
        </svg>
    )
}

export default Pad;