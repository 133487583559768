import { useGamepadCom } from "../../../unboared-com";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
// import SettingsIcon from '@mui/icons-material/Settings';

import { CenteredFlexBox, Header, NavButtonGamepad, UnboaredIcon, InGameNavLayout } from '../../../react-unboared'
import menu_logo from '../assets/img/unblind-test.png'

const Gamepad = () => {
    const { emitAction } = useGamepadCom();

    const nav = (
        <CenteredFlexBox>
            <NavButtonGamepad
                key={"instructions"}
                title="Instructions"
                icon={<UnboaredIcon MaterialIcon={HelpOutlineIcon} size={6} />}
                onClick={() => emitAction("pressInstructions")} />
            <NavButtonGamepad
                key={"play"}
                title="Play"
                icon={<UnboaredIcon MaterialIcon={PlayCircleOutlineIcon} size={6} />}
                onClick={() => emitAction("pressPlay")}
            />
            {/* <NavButtonGamepad
                key={"settings"}
                title="Settings"
                icon={<UnboaredIcon MaterialIcon={SettingsIcon} size={3} />}
                onClick={() => emitAction("pressSettings")}
            /> */}
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header src={menu_logo} />} nav={nav}>
        </InGameNavLayout>
    )
}

export default Gamepad;

