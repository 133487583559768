import create from "zustand"
import { persist } from "zustand/middleware"

const useGamepadLS = create(persist(
    (set) => ({
        users: [],
        addUsers: (user) =>
            set((state) => ({
                users: [
                    ...state.users,
                    { username: user.username, avatar: user.avatar },
                ]
            })),
        updateUsers: (username, new_value) =>
            set((state) => ({
                users: state.users.map((user) => { return (user.username !== username) ? user : new_value }),
            })),
        setUsers: (new_users) => set({ users: new_users }),
        removeUser: (id) =>
            set((state) => ({
                users: state.users.filter((user, index) => id !== index),
            })),
    }),
    {
        name: "gamepad-global", // name of item in the storage (must be unique)
    }));

export default useGamepadLS;
