import React from 'react'
import PropTypes from 'prop-types'
import Alert from '.'

const msgStyle = {
    marginBottom: '1em',
}

const NotAvailableAlert = ({ game }) => {
    const headerMessage = `⌛ ${game} is coming soon.`;

    return (
        <Alert message={headerMessage}>
            <p style={msgStyle}>
                <span className='important' style={{ fontWeight: '1000' }}>
                    {`${game} `}
                </span>
                is not yet available. Be patient, it will come soon.
            </p>
        </Alert>
    )
}

NotAvailableAlert.propTypes = {
    game: PropTypes.string.isRequired,
}

export default NotAvailableAlert
