
import { useEffect } from "react";
import useScreenCom from "./useScreenCom";
import useScreenSession from "./useScreenSession";

const useLocalScenario = (game, scenario, scenarioComponent = null, deps = []) => {
    console.log('[useLocalScenario] Rewrite')
    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);

    useEffect(() => {
        setLocalScenario(game, scenario, scenarioComponent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

const useExternalScenario = (game, scenario, deps = []) => {
    console.log('[useExternalScenario] Rewrite')
    const { changeScenario } = useScreenCom();

    useEffect(() => {
        changeScenario(game, scenario);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

const useScreenScenario = (game, scenario, scenarioComponent = null, deps = []) => {
    console.log('[useScreenScenario] Rewrite')
    useLocalScenario(game, scenario, scenarioComponent, deps)
    useExternalScenario(game, scenario, deps)
}

export { useLocalScenario, useExternalScenario }
export default useScreenScenario;


