import create from "zustand"
import { persist } from "zustand/middleware"
import THEME from "../settings/Theme";

const useTheme = create(persist(
    (set) => ({
        theme: THEME.DEFAULT,
        setTheme: new_theme => set({ theme: new_theme }),
    }),
    {
        name: "_theme", // name of item in the storage (must be unique)
    }
))

export default useTheme;