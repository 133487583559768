import styled from 'styled-components'

import WordCard from './WordCard'
import DetectiveCard from '../DetectiveCard/DetectiveCard';


const StyledCodenamesCard = styled.div`
    position: relative;
    transform-style: preserve-3d;
    // transition: all 0.3s ease;

    &.flipped {
        .front {
            transform: rotateY(180deg);
        }
        .back {
            transform: rotateY(0deg);
        }
    }
    .front,
    .back {
        position: absolute;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        transition: -webkit-transform ease 500ms;
        width:98%;
        height:98%;
        transition: transform ease 500ms;
    }

    .front {
        z-index: 10;
        transform: rotateY(0deg);
    }
    .back {
        transform: rotateY(-180deg);
    }
`


const CodenamesCard = ({ className, word, active, detective, color, revealed, ...props }) => {

    return (
        <StyledCodenamesCard className={className + (revealed ? " flipped" : "")}>
            <WordCard className='front' word={word} active={active} color={color} />
            <DetectiveCard className="back" src={detective} active={active} color={color} />
        </StyledCodenamesCard>
    )
}

export default CodenamesCard;