export class Message {
    constructor(text, time, callback) {
        this.text = text
        this.time = time
        this.callback = callback
    }

    showMessage() {
        this.addMessage()
    }

    addMessage() {
        this.callback(this.text);
    }

    deleteMessage() {
        this.callback("");
    }

}