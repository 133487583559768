import styled from 'styled-components'

import { PLAYER_COLOR } from "../../../../react-unboared"
import { useGamepadSession } from "../../../../unboared-com";

import ConnectedPlayersList from '../Players/ConnectedPlayersList';
import { Flex } from '../../../../react-unboared';

const WaitingTxtStyle = styled.div`
text-align:center;
.main {
    margin:3rem;
}

p{
    font-size: 1.8rem;
}
`

const WaitingTxt = ({ master, ...props }) => {
    return (
        <WaitingTxtStyle {...props}>
            <h1 className='main'><span style={{ color: `${PLAYER_COLOR[master.id]}` }}>{master.username}</span> is the master of the game.</h1>
            <p>Wait or help {master.username} to choose the game!</p>
        </WaitingTxtStyle>
    );
}


const StyledWaitingScreen = styled.div`
    ${Flex({ alignItem: "center", justifyContent: "center" })}
    flex-direction:column;
    // height: 100vh;
    width: 100vw;
    .header {
        height: 30vh;
        margin-top: 10vh;
        width: 80vw;
    }
    .content {
        height: 55vh;
        flex-direction:column;
        ${Flex({ alignItem: "center", justifyContent: "center" })}
        text-align:center;
        .title {
            margin-bottom:1rem;
            font-size: 1.6rem;
        }
        .players {
            border-radius : ${({ theme }) => theme.BORDER_RADIUS};
            padding: 1rem 2rem;
            border : 0.5rem solid rgba(255,255,255,0.1);
            height:100%;
        }
    }
`

const getMaster = (players, masterID) => {
    for (let player of players) {
        if (player.id === masterID) {
            return player;
        }
    }
}

const WaitingScreen = () => {
    const { statusPlayers } = useGamepadSession();

    const master = getMaster(statusPlayers.players, statusPlayers.masterID);

    return (
        <StyledWaitingScreen>
            <div className='header'>
                <WaitingTxt master={master}/>
            </div>
            <div className='content'>
                <p className='title'>Connected players</p>
                <ConnectedPlayersList style={{ width: "80vw" }} className='players' players={statusPlayers.players} masterID={statusPlayers.masterID} />
            </div>
        </StyledWaitingScreen>
    )
}

export default WaitingScreen;