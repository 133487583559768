import styled from 'styled-components';
import {device} from '../..'


const BlurredBG = styled.div`
    position: fixed;
    z-index: 997;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
`

const ColorBG = styled.div`
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: ${({ theme }) => theme.PRIMARY_TEXT_COLOR};
    opacity:0.3;
`

const StyledOverlayView = styled.div`
    position: fixed;
    border-radius: ${({ theme }) => theme.BORDER_RADIUS};

    top : ${({ centered }) => centered ? "50%" : "0"};
    left : ${({ centered }) => centered ? "50%" : "0"};
    ${({ centered }) => centered ? "transform : translate(-50%,-50%);" : null}
    
    
    /* Stay in place */
    z-index: 999;
    
    /* Enable scroll if needed */
    overflow: auto;
    
    background-color: ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
    // box-shadow: 0 0 12px 10px rgba(240, 240, 240);
    
    // /* Fallback color */
    opacity: 0.95;

    // @media ${device.tablet} {
    //     border-radius: 0;
    // }
`

const OverlayView = ({ centered = true, ...props }) => {
    return (
        <>
            <BlurredBG />
            <ColorBG />
            <StyledOverlayView {...props} centered={centered}>
                {props.children}
            </StyledOverlayView>
        </>
    );
};

export default OverlayView;