import StyledUserSetting from './StyledUserSetting.jsx';
import NavWidget from '../components/nav/NavWidget';
import { UserAuthentificationModification } from '../components/authentification/UserAuthentification';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

const UserSetting = ({onClose}) => {
    
    return (
            <StyledUserSetting>
                <NavWidget MaterialIcon={KeyboardReturnIcon} onClick={onClose} />
                <UserAuthentificationModification onSubmitted={onClose}/>
            </StyledUserSetting>
    );
}

export default UserSetting;