import create from "zustand"
import Sound from 'react-sound';
import { UNBOARED_DARK_THEME } from '../..';

const useSoundManager = create(
    (set) => ({
        controlled: false,
        currentSong: UNBOARED_DARK_THEME.PRIMARY_AMBIANCE_SONG,
        position: 0,
        volume: 100,
        playbackRate: 1,
        loop: true,
        playStatus: Sound.status.PLAYING,
        setPosition: (position) => set({ position: position }),
        onPlay: () => set({ playStatus: Sound.status.PLAYING }),
        onPause: () => set({ playStatus: Sound.status.PAUSED }),
        onResume: () => set({ playStatus: Sound.status.PLAYING }),
        onStop: () => set({ playStatus: Sound.status.STOPPED, position: 0 }),
        onVolumeUp: () => set((state) => ({ volume: state.volume >= 100 ? state.volume : state.volume + 10 })),
        onVolumeDown: () => set((state) => ({ volume: state.volume <= 0 ? state.volume : state.volume - 10 })),
        onMute: () => set({ volume: 0 }),
        onToggleLoop: () => set((state) => ({ loop: !state.loop })),
        setSong: (newSong) => set({ currentSong: newSong })
    })
)

export default useSoundManager;