import styled from 'styled-components';
import { useGamepadSession } from '../../../unboared-com';
import GFullscreenButton from '../buttons/GFullscreenButton';
import PauseMenuIcon from '../../menu/PauseMenuIcon';


const StyledGLayoutGame = styled.div`
    // height: 100vh;
    // width: 100vw;

    .fullscreen {
        position: absolute;
        top:1rem;
        left:1rem;
        z-index:99;
    }

`

const GLayoutGame = (props) => {
    const statusPlayers = useGamepadSession(state => state.statusPlayers);
    const id = useGamepadSession(state => state.id);

    // /* Listeners */
    // useListener("statusPlayers", setStatusPlayers);

    return (
        <StyledGLayoutGame>
            {(statusPlayers.masterID === id ? <PauseMenuIcon /> : null)}
            <GFullscreenButton className="fullscreen" size={6} />
            {props.children}
        </StyledGLayoutGame>
    );
}

export default GLayoutGame;