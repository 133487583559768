export const deck = [

    {
        form: 'circlesHexagon',
        color: 'blue',
        img: 'ccc-b.png',
        type: 'normal'
    },

    {
        form: 'circlesHexagon',
        color: 'green',
        img: 'ccc-g.png',
        type: 'normal'
    },

    {
        form: 'circlesHexagon',
        color: 'red',
        img: 'ccc-r.png',
        type: 'normal'
    },

    {
        form: 'circlesHexagon',
        color: 'yellow',
        img: 'ccc-y.png',
        type: 'normal'
    },

    {
        form: 'hexagonCenter',
        color: 'blue',
        img: 'HexagonCenter-b.png',
        type: 'normal'
    },

    {
        form: 'hexagonCenter',
        color: 'green',
        img: 'HexagonCenter-g.png',
        type: 'normal'
    },

    {
        form: 'hexagonCenter',
        color: 'red',
        img: 'HexagonCenter-r.png',
        type: 'normal'
    },

    {
        form: 'hexagonCenter',
        color: 'yellow',
        img: 'HexagonCenter-y.png',
        type: 'normal'
    },

    {
        form: 'hexagonCrossed',
        color: 'blue',
        img: 'HexagonCrossed-b.png',
        type: 'normal'
    },

    {
        form: 'hexagonCrossed',
        color: 'green',
        img: 'HexagonCrossed-g.png',
        type: 'normal'
    },

    {
        form: 'hexagonCrossed',
        color: 'red',
        img: 'HexagonCrossed-r.png',
        type: 'normal'
    },

    {
        form: 'hexagonCrossed',
        color: 'yellow',
        img: 'HexagonCrossed-y.png',
        type: 'normal'
    },

    {
        form: 'hexagonInside',
        color: 'blue',
        img: 'HexagonInside-b.png',
        type: 'normal'
    },

    {
        form: 'hexagonInside',
        color: 'green',
        img: 'HexagonInside-g.png',
        type: 'normal'
    },

    {
        form: 'hexagonInside',
        color: 'red',
        img: 'HexagonInside-r.png',
        type: 'normal'
    },

    {
        form: 'hexagonInside',
        color: 'yellow',
        img: 'HexagonInside-y.png',
        type: 'normal'
    },

    {
        form: 'hexagonOutside',
        color: 'blue',
        img: 'HexagonOutside-b.png',
        type: 'normal'
    },

    {
        form: 'hexagonOutside',
        color: 'green',
        img: 'HexagonOutside-g.png',
        type: 'normal'
    },

    {
        form: 'hexagonOutside',
        color: 'red',
        img: 'HexagonOutside-r.png',
        type: 'normal'
    },

    {
        form: 'hexagonOutside',
        color: 'yellow',
        img: 'HexagonOutside-y.png',
        type: 'normal'
    },

    {
        form: 'hexagonsBgEmpty',
        color: 'blue',
        img: 'HexagonsBgEmpty-b.png',
        type: 'normal'
    },

    {
        form: 'hexagonsBgEmpty',
        color: 'green',
        img: 'HexagonsBgEmpty-g.png',
        type: 'normal'
    },

    {
        form: 'hexagonsBgEmpty',
        color: 'red',
        img: 'HexagonsBgEmpty-r.png',
        type: 'normal'
    },

    {
        form: 'hexagonsBgEmpty',
        color: 'yellow',
        img: 'HexagonsBgEmpty-y.png',
        type: 'normal'
    },

    {
        form: 'hexagonsBgFull',
        color: 'blue',
        img: 'HexagonsBgFull-b.png',
        type: 'normal'
    },

    {
        form: 'hexagonsBgFull',
        color: 'green',
        img: 'HexagonsBgFull-g.png',
        type: 'normal'
    },

    {
        form: 'hexagonsBgFull',
        color: 'red',
        img: 'HexagonsBgFull-r.png',
        type: 'normal'
    },

    {
        form: 'hexagonsBgFull',
        color: 'yellow',
        img: 'HexagonsBgFull-y.png',
        type: 'normal'
    },

    {
        form: 'hexagonsCircle',
        color: 'blue',
        img: 'HexagonsCircle-b.png',
        type: 'normal'
    },

    {
        form: 'hexagonsCircle',
        color: 'green',
        img: 'HexagonsCircle-g.png',
        type: 'normal'
    },

    {
        form: 'hexagonsCircle',
        color: 'red',
        img: 'HexagonsCircle-r.png',
        type: 'normal'
    },

    {
        form: 'hexagonsCircle',
        color: 'yellow',
        img: 'HexagonsCircle-y.png',
        type: 'normal'
    },

    {
        form: 'hexagonsSquare',
        color: 'blue',
        img: 'HexagonsSquare-b.png',
        type: 'normal'
    },

    {
        form: 'hexagonsSquare',
        color: 'green',
        img: 'HexagonsSquare-g.png',
        type: 'normal'
    },

    {
        form: 'hexagonsSquare',
        color: 'red',
        img: 'HexagonsSquare-r.png',
        type: 'normal'
    },

    {
        form: 'hexagonsSquare',
        color: 'yellow',
        img: 'HexagonsSquare-y.png',
        type: 'normal'
    },

    {
        form: 'intertwinedFull',
        color: 'blue',
        img: 'IntertwinedFull-b.png',
        type: 'normal'
    },

    {
        form: 'intertwinedFull',
        color: 'green',
        img: 'IntertwinedFull-g.png',
        type: 'normal'
    },

    {
        form: 'intertwinedFull',
        color: 'red',
        img: 'IntertwinedFull-r.png',
        type: 'normal'
    },

    {
        form: 'intertwinedFull',
        color: 'yellow',
        img: 'IntertwinedFull-y.png',
        type: 'normal'
    },

    {
        form: 'intertwinedInside',
        color: 'blue',
        img: 'IntertwinedInside-b.png',
        type: 'normal'
    },

    {
        form: 'intertwinedInside',
        color: 'green',
        img: 'IntertwinedInside-g.png',
        type: 'normal'
    },

    {
        form: 'intertwinedInside',
        color: 'red',
        img: 'IntertwinedInside-r.png',
        type: 'normal'
    },

    {
        form: 'intertwinedInside',
        color: 'yellow',
        img: 'IntertwinedInside-y.png',
        type: 'normal'
    },

    {
        form: 'intertwinedOutside',
        color: 'blue',
        img: 'IntertwinedOutside-b.png',
        type: 'normal'
    },

    {
        form: 'intertwinedOutside',
        color: 'green',
        img: 'IntertwinedOutside-g.png',
        type: 'normal'
    },

    {
        form: 'intertwinedOutside',
        color: 'red',
        img: 'IntertwinedOutside-r.png',
        type: 'normal'
    },

    {
        form: 'intertwinedOutside',
        color: 'yellow',
        img: 'IntertwinedOutside-y.png',
        type: 'normal'
    },

    {
        form: 'intertwinedSide',
        color: 'blue',
        img: 'IntertwinedSide-b.png',
        type: 'normal'
    },

    {
        form: 'intertwinedSide',
        color: 'green',
        img: 'IntertwinedSide-g.png',
        type: 'normal'
    },

    {
        form: 'intertwinedSide',
        color: 'red',
        img: 'IntertwinedSide-r.png',
        type: 'normal'
    },

    {
        form: 'intertwinedSide',
        color: 'yellow',
        img: 'IntertwinedSide-y.png',
        type: 'normal'
    },

    {
        form: 'squaresHexagon',
        color: 'blue',
        img: 'shs-b.png',
        type: 'normal'
    },

    {
        form: 'squaresHexagon',
        color: 'green',
        img: 'shs-g.png',
        type: 'normal'
    },

    {
        form: 'squaresHexagon',
        color: 'red',
        img: 'shs-r.png',
        type: 'normal'
    },

    {
        form: 'squaresHexagon',
        color: 'yellow',
        img: 'shs-y.png',
        type: 'normal'
    },

    {
        form: 'starBig',
        color: 'blue',
        img: 'StarBig-b.png',
        type: 'normal'
    },

    {
        form: 'starBig',
        color: 'green',
        img: 'StarBig-g.png',
        type: 'normal'
    },

    {
        form: 'starBig',
        color: 'red',
        img: 'StarBig-r.png',
        type: 'normal'
    },

    {
        form: 'starBig',
        color: 'yellow',
        img: 'StarBig-y.png',
        type: 'normal'
    },

    {
        form: 'starBoth',
        color: 'blue',
        img: 'StarBoth-b.png',
        type: 'normal'
    },

    {
        form: 'starBoth',
        color: 'green',
        img: 'StarBoth-g.png',
        type: 'normal'
    },

    {
        form: 'starBoth',
        color: 'red',
        img: 'StarBoth-r.png',
        type: 'normal'
    },

    {
        form: 'starBoth',
        color: 'yellow',
        img: 'StarBoth-y.png',
        type: 'normal'
    },

    {
        form: 'starFull',
        color: 'blue',
        img: 'StarFull-b.png',
        type: 'normal'
    },

    {
        form: 'starFull',
        color: 'green',
        img: 'StarFull-g.png',
        type: 'normal'
    },

    {
        form: 'starFull',
        color: 'red',
        img: 'StarFull-r.png',
        type: 'normal'
    },

    {
        form: 'starFull',
        color: 'yellow',
        img: 'StarFull-y.png',
        type: 'normal'
    },

    {
        form: 'starSmall',
        color: 'blue',
        img: 'StarSmall-b.png',
        type: 'normal'
    },

    {
        form: 'starSmall',
        color: 'green',
        img: 'StarSmall-g.png',
        type: 'normal'
    },

    {
        form: 'starSmall',
        color: 'red',
        img: 'StarSmall-r.png',
        type: 'normal'
    },

    {
        form: 'starSmall',
        color: 'yellow',
        img: 'StarSmall-y.png',
        type: 'normal'
    },

    {
        form: 'specialColors1',
        color: 'color1',
        img: 'SpecialColors.png',
        type: 'color'
    },

    {
        form: 'specialColors2',
        color: 'color2',
        img: 'SpecialColors.png',
        type: 'color'
    },

    {
        form: 'specialColors3',
        color: 'color3',
        img: 'SpecialColors.png',
        type: 'color'
    },

    {
        form: 'specialColors4',
        color: 'color4',
        img: 'SpecialColors.png',
        type: 'color'
    },

    {
        form: 'specialDiscs1',
        color: 'disc1',
        img: 'SpecialDiscs.png',
        type: 'disc'
    },

    {
        form: 'specialDiscs2',
        color: 'disc2',
        img: 'SpecialDiscs.png',
        type: 'disc'
    },

    {
        form: 'specialDiscs3',
        color: 'disc3',
        img: 'SpecialDiscs.png',
        type: 'disc'
    },

    {
        form: 'specialDiscs4',
        color: 'disc4',
        img: 'SpecialDiscs.png',
        type: 'disc'
    }

]