import HomeGamepad from "../Home/Gamepad"
import HomeScreen from "../Home/Screen"
import InstructionsGamepad from "../Instructions/Gamepad"
import InstructionsScreen from "../Instructions/Screen"
import GameGamepad from "../Game/Gamepad"
import GameScreen from "../Game/Screen"
import EndGameGamepad from "../Endgame/Gamepad"
import EndGameScreen from "../Endgame/Screen"

const Scenario = {
    "home": {
        gamepad: <HomeGamepad />,
        screen: <HomeScreen />,
    },
    "instructions": {
        gamepad: <InstructionsGamepad />,
        screen: <InstructionsScreen />,
    },
    "game": {
        gamepad: <GameGamepad />,
        screen: <GameScreen />,
    },
    "endgame": {
        gamepad: <EndGameGamepad />,
        screen: <EndGameScreen />,
    }
}

export default Scenario;