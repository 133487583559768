import { useScreenSession } from "../../unboared-com";

import Scenario from "./config/Scenario";
import UnboaredSpeedStyles from './UnboaredSpeedStyles'

const ScreenApp = () => {
    const scenario = useScreenSession((state) => state.scenario);
    const scenarioComponent = useScreenSession((state) => state.scenarioComponent);
    return (
        <UnboaredSpeedStyles>
            <div className='screen'>
                {(scenarioComponent ? scenarioComponent : Scenario[scenario].screen)}
            </div>
        </UnboaredSpeedStyles>
    )
}

export default ScreenApp;