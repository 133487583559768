import Disc from "./disc"
import { DISC_SIZE } from "../config/Const"
import { PUBLIC_ASSETS } from "../../../config/Const"

import { jungleSounds } from './JungleSound';

class Player {
  constructor(id, infos, discX, senseDiscX, discY, senseDiscY, game, maxWidth, maxHeight) {
    this.id = id
    this.color = infos.color
    this.username = infos.username
    this.maxWidth = maxWidth
    this.maxHeight = maxHeight
    this.playerCards = []
    this.centerCards = []

    this.game = game
    this.disc = new Disc(this.game.ctx, this.id, this.color, discX, senseDiscX, discY, senseDiscY)
    this.timeouts = []
  }

  updateCurrentCard() {
    if (this.centerCards.length > 0) {
      document.getElementById(`cardCenter${this.id}`).style.background = `url(${PUBLIC_ASSETS}images/games/unboared-speed/cards/${this.centerCards[0].img})`//require(`../assets/img/cards/${this.centerCards[0].img}`)
      document.getElementById(`cardCenter${this.id}`).style.backgroundSize = 'cover'
    }
  }

  updateCounterCards() {
    // console.log("## Player (" + this.id + ") - " + this.centerCards.length)
    if (this.centerCards.length === 0) {
      // console.log("--> no cards")
      document.getElementById(`cardCenter${this.id}`).style.background = ""//`url(${PUBLIC_ASSETS}images/games/unboared-speed/cards/${this.centerCards[0].img})`//require(`../assets/img/cards/${this.centerCards[0].img}`)
      document.getElementById(`cardCenter${this.id}`).style.backgroundSize = 'cover'
      document.getElementById(`counterCards${this.id}`).innerText = `${this.playerCards.length}`
      document.getElementById(`counterCardsTable${this.id}`).innerText = `${this.playerCards.length}`
    } else {
      // console.log("--> have cards")
      document.getElementById(`counterCards${this.id}`).innerText = ''
    }
  }

  throwCard() {
    if ((this.game.orderDiscs.length === 0) && (this.game.turn === this.id)) {
      jungleSounds['throw_card']();
      let card = this.playerCards.pop()
      // console.log(`PLAYER${this.id} throw card ${JSON.stringify(card)}`)
      this.centerCards.unshift(card)
      this.updateCurrentCard()
      this.updateCounterCards()
      // console.log(`PLAYER${this.id} throws a card`)
      this.game.checkAllCardsInCenter()
      this.game.updatePropCheck()
      if (this.game.turn === 'discTurn') {
        this.game.playDiscsComputersPlayers()
        this.callLater(() => { this.game.duelDiscs() }, 3000)
      } else {
        this.game.incrementTurn()
        this.game.playDiscsComputersPlayers()
        if (this.game.orderDiscs.length === 0) {
          this.game.playCardNextComputerPlayer()
        }
      }
    } else {
      console.log(`${this.username} it's not your turn`)
    }
  }

  throwDisc() {
    if ((this.centerCards.length === 0 && this.game.turn !== 'discTurn') || this.game.turn === null) {
      console.log(`${this.username} can't throw the disc now`)
      return
    }
    if (!this.game.orderDiscs.includes(this.id)) {
      jungleSounds['throw_disc']();
      this.game.orderDiscs.push(this.id)
      this.disc.maxX = ((this.maxWidth / 2) - (3 * DISC_SIZE / 4)) + (Math.random() * (DISC_SIZE / 2))
      this.disc.maxY = ((this.maxHeight / 2) - (3 * DISC_SIZE / 4)) + (Math.random() * (DISC_SIZE / 2))
      this.disc.deviation = (Math.random() * 2) - 1
      // console.log(`PLAYER${this.id} throws the disc`)
      if ((this.game.orderDiscs.length === 1) && (this.game.turn !== 'discTurn')) {
        this.callLater(() => { this.game.duelCards() }, 3000)
      }
    }
  }


  callLater(call, delay) {
    this.timeouts.push(setTimeout(call, delay))
  }


  clear() {
    for (var i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i]);
    }
  }
}

export default Player;