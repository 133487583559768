import { useGamepadSession } from ".";
import { useDefaultPrototocol } from "../";

const useGamepadCom = () => {
    console.log("[useGamepadCom] Update")

    const { com } = useDefaultPrototocol();
    const { statusConnexion, username, id } = useGamepadSession();

    const emitAction = (name, data = {}) => {
        if (statusConnexion) {
            console.log(`[Communication] Send action(${name}) to room°${statusConnexion.roomID}`);
            data["action"] = name;
            data["player"] = {
                "username": username,
                "id": id
            };
            data["roomID"] = statusConnexion.roomID;
            com.send("action", data);
        }
    }

    const updateProfile = (username, avatar) => {
        if (statusConnexion) {
            console.log(`[Communication] Update profile (${username}/${avatar}) to room°${statusConnexion.roomID}`);
            let data = { "username": username, "avatar": avatar };
            data["roomID"] = statusConnexion.roomID;
            com.send("updateProfile", data);
        }
    }

    const join = (roomID, username, avatar) => {
        console.log(`[Communication] Join room ${roomID}`);
        com.send("join", { "roomID": roomID, 'player': { 'username': username, 'avatar': avatar } });
    }

    const leave = (roomID) => {
        console.log(`[Communication] Leave room ${roomID}`);
        console.log(`[Communication] with com = ${com}`);
        com.send("gamepadLeave", { "roomID": roomID });

    }
    return { join, leave, emitAction, updateProfile }
}


export default useGamepadCom;