import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import useSound from 'use-sound';
import styled from 'styled-components';

import { useListener } from '../../../unboared-com';
import { LAPTOP_BREAKPOINT, TABLET_BREAKPOINT, device } from '../../../react-unboared';

import MoveSound from '../../../react-unboared/assets/sounds/Move.mp3';

import Input from '../input/Input'
import GameCard from '../game-card/GameCard';
import Button, { OutlineButton } from '../button/Button';

import unboaredApi, { category, boardGameType, videoGameType } from '../../../api/unboaredApi';
import ValidateComponent from '../../../screen/home/ValidateComponent';

const CARD_SIZE = 250;
const CARD_SIZE_LAPTOP = 180;
const CARD_SIZE_TABLET = 120;
const GAP_SIZE = 0;


const StyledGameGrid = styled.div`
display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${CARD_SIZE}px, 1fr));
    // gap: 20px;
    margin-bottom: 3rem;
    @media ${device.laptop} {
        grid-template-columns: repeat(auto-fill, minmax(${CARD_SIZE_LAPTOP}px, 1fr));
    }
    @media ${device.tablet} {
        grid-template-columns: repeat(auto-fill, minmax(${CARD_SIZE_TABLET}px, 1fr));
    }
    &__loadmore {
        text-align: center;
    }
`

const StyledSearch = styled.div`
    padding :2rem;
`

const GameGrid = props => {

    const [items, setItems] = useState([]);
    const [selectedGame, setSelectedGame] = useState(0)
    // const gameRef = useRef(selectedGame);

    const gridRef = useRef(null);
    const getNumItemsByRow = () => {
        if (gridRef.current.offsetWidth > LAPTOP_BREAKPOINT) {
            return parseInt(gridRef.current.offsetWidth / (CARD_SIZE + GAP_SIZE));
        }
        else  if (gridRef.current.offsetWidth > TABLET_BREAKPOINT) {
            return parseInt(gridRef.current.offsetWidth / (CARD_SIZE_LAPTOP + GAP_SIZE));
        }
        else {
            return parseInt(gridRef.current.offsetWidth / (CARD_SIZE_TABLET + GAP_SIZE));
        }
    }

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    const { keyword } = useParams();

    useEffect(() => {
        const getList = async () => {
            let response = null;
            if (keyword === undefined) {
                const params = {};
                switch (props.category) {
                    case category.boardgame:
                        // response = await tmdbApi.getGamesList(GameType.upcoming, {params});
                        response = unboaredApi.getBoardGameList(boardGameType.upcoming, { params });
                        break;
                    default:
                        // response = await tmdbApi.getTvList(tvType.popular, {params});
                        response = unboaredApi.getVideoGameList(videoGameType.upcoming, { params });
                }
            } else {
                const params = {
                    query: keyword
                }
                // response = await unboaredApi.search(props.category, {params});
                response = unboaredApi.search(props.category, { params });
            }
            // setItems(response.results);
            // setTotalPage(response.total_pages);
            setItems(response);
            setTotalPage(1);
        }
        getList();
    }, [props.category, keyword]);

    const loadMore = async () => {
        let response = null;
        if (keyword === undefined) {
            const params = {
                page: page + 1
            };
            switch (props.category) {
                case category.boardgame:
                    // response = await tmdbApi.getGamesList(GameType.upcoming, {params});
                    response = unboaredApi.getBoardGameList(boardGameType.upcoming, { params });
                    break;
                default:
                    // response = await tmdbApi.getTvList(tvType.popular, {params});
                    response = unboaredApi.getVideoGameList(videoGameType.upcoming, { params });
            }
        } else {
            const params = {
                page: page + 1,
                query: keyword
            }
            // response = await tmdbApi.search(props.category, { params });
            response = unboaredApi.search(props.category, { params });
        }
        setItems([...items, ...response.results]);
        setPage(page + 1);
    }

    const [playMoveSound] = useSound(MoveSound);

    useListener("left", () => {
        setSelectedGame((prev) => {
            playMoveSound();
            if (prev > 0) {
                return prev - 1;
            }
            return prev;
        })
    }, [playMoveSound])

    useListener("right", () => {
        setSelectedGame((prev) => {
            playMoveSound();
            if (prev + 1 < items.length) {
                return prev + 1;
            }
            return prev;
        })
    }, [items, playMoveSound])

    useListener("up", () => {
        const num_cards_by_row = getNumItemsByRow();
        setSelectedGame((prev) => {
            playMoveSound();
            if (prev >= num_cards_by_row) {
                return prev - num_cards_by_row;
            }
            return prev;
        })
    }, [playMoveSound])

    useListener("down", () => {
        const num_cards_by_row = getNumItemsByRow();
        setSelectedGame((prev) => {
            playMoveSound();
            if (prev + num_cards_by_row < items.length) {
                return prev + num_cards_by_row;
            }
            else {
                return (items.length - 1);
            }
        }, [])
    }, [items, playMoveSound])

    return (
        <StyledSearch>
            <ValidateComponent currentGame={items[selectedGame]}/>
            <div className="section mb-3">
                <GameSearch category={props.category} keyword={keyword} />
            </div>
            <StyledGameGrid ref={gridRef}>
                {
                    items.map((item, i) => <GameCard gameID={i} callbackSetSelected={setSelectedGame} isSelected={selectedGame === i} category={props.category} item={item} key={i} />)
                }
            </StyledGameGrid>
            {
                (page < totalPage) ? (
                    <div className="game-grid__loadmore">
                        <OutlineButton className="small" onClick={loadMore}>Load more</OutlineButton>
                    </div>
                ) : null
            }
        </StyledSearch>
    );
}

const StyledGameSearch = styled.div`
    position: relative;
    width: 100%;
    max-width: 500px;
    input {
        width: 100%;
        padding-right: 8rem;
        background-color: ${({ theme }) => theme.PRIMARY_BUTTON_COLOR};
        color: ${({ theme }) => theme.PRIMARY_TEXT_COLOR};
    }
    button {
        position: absolute;
        right: 0;
        top: 2px;
        @include mobile {
            right: 1px;
            top: 1px;
        }
    }
`

const GameSearch = props => {

    const history = useHistory();

    const [keyword, setKeyword] = useState(props.keyword ? props.keyword : '');

    const goToSearch = useCallback(
        () => {
            if (keyword.trim().length > 0) {
                history.push(`/${category[props.category]}/search/${keyword}`);
            }
        },
        [keyword, props.category, history]
    );

    useEffect(() => {
        const enterEvent = (e) => {
            e.preventDefault();
            if (e.keyCode === 13) {
                goToSearch();
            }
        }
        document.addEventListener('keyup', enterEvent);
        return () => {
            document.removeEventListener('keyup', enterEvent);
        };
    }, [keyword, goToSearch]);

    return (
        <StyledGameSearch>
            <Input
                type="text"
                placeholder="Enter keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
            />
            <Button style={{
                borderWidth: '2px',
                padding: '0.25rem 1.5rem',
                fontSize: '1rem'
            }} onClick={goToSearch}>Search</Button>
        </StyledGameSearch>
    )
}

export default GameGrid;
