import { useScreenSession } from "../../unboared-com";

import Scenario from "./config/Scenario";

const ScreenApp = () => {
    const scenario = useScreenSession((state) => state.scenario);

    return (Scenario[scenario].screen)
}

export default ScreenApp;