import {StyledInGameNavLayout, StyledInGameLayout} from './StyledGamepadPortraitLayout'

const InGameNavLayout = (props) => {
    return (
        <StyledInGameNavLayout className={props.className}>
            <div className="header">
                {props.header}
            </div>
            <div className="content">
                {props.content}
                {props.children}
            </div>
            <div className="nav">
                {props.nav}
            </div>
        </StyledInGameNavLayout>
    )
}


const InGameLayout = (props) => {
    return (
        <StyledInGameLayout className={props.className}>
            <div className="header">
                {props.header}
            </div>
            <div className="content">
                {props.content}
                {props.children}
            </div>
        </StyledInGameLayout>
    )
}


export {InGameNavLayout, InGameLayout};