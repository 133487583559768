import styled from 'styled-components';

export const Button = styled.button`
    border: 4px solid transparent;
    background-color: ${({theme}) => theme.PRIMARY_ACTIVE_BUTTON_COLOR};
    color: ${({theme}) => theme.PRIMARY_TEXT_COLOR};
    border-radius: ${({theme}) => theme.BORDER_RADIUS};
    padding: 0.5rem 1.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    box-shadow: 0px 0px 7px 8px #ff00004d;
    transition: box-shadow 0.3s ease;
    position: relative;
    &:hover {
        box-shadow: 0px 0px 7px 15px #ff00004d;
    }
`

export const OutlineButton = styled.button`
    border: 3px solid ${({theme}) => theme.PRIMARY_TEXT_COLOR};
    background-color: transparent;
    color: ${({theme}) => theme.PRIMARY_TEXT_COLOR};
    box-shadow: unset;
    transition: color 0.3s ease, background-color 0.3s ease;

    &:hover {
        box-shadow: unset;
        color: ${({theme}) => theme.PRIMARY_ACTIVE_BUTTON_COLOR};
        background-color: ${({theme}) => theme.PRIMARY_TEXT_COLOR};
    }
`

export const DisableButton = styled.button`
    border: 4px solid transparent;
    background-color: #4b4855;
    color: ${({theme}) => theme.PRIMARY_TEXT_COLOR};
    border-radius: ${({theme}) => theme.BORDER_RADIUS};
    padding: 0.5rem 1.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    box-shadow: 0px 0px 7px 8px ${({theme}) => theme.PRIMARY_TEXT_COLOR};
    transition: box-shadow 0.3s ease;
    position: relative;
`

export default Button;