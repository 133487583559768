import { createGlobalStyle, css } from "styled-components";
import { device, PRIMARY_COLOR } from "..";

export const DefaultBodyStyle = css`
		background: ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
		color: ${({ theme }) => theme.PRIMARY_TEXT_COLOR};
		font-family: ${({ theme }) => theme.PRIMARY_TEXT_FONT};
		font-weight: 400;
    	line-height: 1.5;
`;


export const DefaultButtonStyle = css`
	border: 0;
	margin: auto;
	position: relative;
	vertical-align: middle;
	padding: 1rem;
	border-radius: ${({ theme }) => theme.BORDER_RADIUS};
	background-color: ${({ theme }) => theme.PRIMARY_BUTTON_COLOR};
	color: ${({ theme }) => theme.PRIMARY_TEXT_COLOR};
	cursor: pointer;
	font-family: ${({ theme }) => theme.PRIMARY_TITLE_FONT};
`;

export const ValidationButtonStyle = css`
	border: 0;
	margin: auto;
	position: relative;
	vertical-align: middle;
	padding: 1rem 1.5rem;
	border-radius: ${({ theme }) => theme.BORDER_RADIUS};
	background-color: ${({ theme }) => theme.PRIMARY_ACTIVE_BUTTON_COLOR};
	color: ${({ theme }) => theme.PRIMARY_TEXT_COLOR};
	cursor: pointer;
	font-family: ${({ theme }) => theme.PRIMARY_TITLE_FONT};
`;

export const DefaultH1Style = css`
    	font-family: ${({ theme }) => theme.PRIMARY_TITLE_FONT};
		font-size : 3rem;
`;

export const DefaultH2Style = css`
    	font-family: ${({ theme }) => theme.PRIMARY_TITLE_FONT};
		font-size : 2rem;
`;

export const DefaultH3Style = css`
    	font-family: ${({ theme }) => theme.SECONDARY_TITLE_FONT};
		font-size : 1.5rem;
`;


export const DefaultPStyle = css`
    	font-family: ${({ theme }) => theme.PRIMARY_TEXT_FONT};
		font-size : 1.25em;
`;

export const DefaultInput = css`
	border-radius: ${({ theme }) => theme.BORDER_RADIUS};
	background-color: ${({ theme }) => theme.PRIMARY_TEXT_COLOR};
	color: ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
	text-align: center;
`;


const GlobalStyles = createGlobalStyle`
	html {
		-ms-touch-action: pan-x;
	    font-size: 100%;
		
	    @media ${device.desktop} {
	        font-size: 80%;
	    }

	    @media ${device.tablet} {
	        font-size: 60%;
	    }
	    @media ${device.mobile} {
	        font-size: 40%;
	    }
	}

	body {
		-webkit-touch-callout: none;
		-webkit-text-size-adjust: none;
		-ms-text-size-adjust: none;
		
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		
		-webkit-highlight: none;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		-webkit-tap-highlight-color: transparent;
		-ms-touch-action: pan-y;
		-ms-content-zooming: none;
		
		input, textarea {
			-webkit-user-select: text;
			-moz-user-select: text;
			-ms-user-select: text;
			user-select: text;
		}

		${DefaultBodyStyle}
	}
	h1 {
		${DefaultH1Style}
	}
	h2 {
		${DefaultH2Style}
	}
	h3 {
		${DefaultH3Style}
	}
	p {
		${DefaultPStyle}
	}
	input {
		${DefaultInput}
	}

	button {
		${DefaultButtonStyle}
	}

	.important {
		color: ${PRIMARY_COLOR};
	}

	.validation-btn {
		${ValidationButtonStyle}
	}
`;

export default GlobalStyles;