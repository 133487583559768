import styled from 'styled-components';
import { useHistory } from 'react-router';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

import { useScreenSession, useListener, useExternalScenario } from '../../../../unboared-com'
import { UnboaredIcon, CenteredFlexBox, InGameNavLayout, Header } from '../../../../react-unboared';

import { GAME_NAME } from '../../config/Const';
import UnboaredSpeedLogo from '../../assets/img/jungle_speed.png';
import NavButton from '../../components/NavButton';

const Screen = ({ winner }) => {
    console.log("[Unblind Test] REWRITE Game")

    const history = useHistory();
    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);


    // Update Scenario to category selection
    let callbackUnboared = () => {
        history.push(`/`);
        setLocalScenario("catalog", "home");
    }

    let callbackHome = () => {
        setLocalScenario(GAME_NAME, "home");
    }

    // Update Scenario to category selection
    useExternalScenario(GAME_NAME, "endgame");

    // Listen press instruction menu button 
    useListener("pressUnboared", callbackUnboared);

    // Listen press play menu button 
    useListener("pressMenu", callbackHome);

    const GameHeader = <Header src={UnboaredSpeedLogo} />;
    const GameNav = (<CenteredFlexBox>
        <NavButton
            key={"backtogames"}
            title="Back to games"
            icon={<UnboaredIcon MaterialIcon={VideogameAssetIcon} size={4} />}
            onClick={callbackUnboared} />
        <NavButton
            key={"restart"}
            title="Restart"
            icon={<UnboaredIcon MaterialIcon={RestartAltIcon} size={4} />}
            onClick={callbackHome} />
    </CenteredFlexBox>);

    return (
        <InGameNavLayout header={GameHeader} nav={GameNav}>
            <ScoreContent>
                <h1 style={{ fontSize: '5rem', color:'black' }}>{winner} wins !!!</h1>
            </ScoreContent>
        </InGameNavLayout>
    );
}

const ScoreContent = styled.div`
    width : 60%;
`

export default Screen;