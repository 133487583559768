import { useEffect, useState } from "react";
import { useScreenCom } from ".";

function basicStateReducer(state, action) {
    return typeof action === 'function' ? action(state) : action
}

const useGameState = (name, init = undefined) => {
    // console.log('[useGameState] Rewrite state';)
    const { sendState } = useScreenCom();
    const [state, setState] = useState();

    const dispatch = (action) => {
        setState((prev) => {
            const nextState = basicStateReducer(prev, action);
            sendState(name, nextState);
            return nextState;
        });
    }

    useEffect(() => {
        dispatch(init);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [state, dispatch];
}

export default useGameState;

