import { useScreenSession } from "../../unboared-com";

import Scenario from "./config/Scenario";
import CodenamesStyles from './CodenamesStyles'

const ScreenApp = () => {
    const scenario = useScreenSession((state) => state.scenario);
    const scenarioComponent = useScreenSession((state) => state.scenarioComponent);
    return (
        <CodenamesStyles>
            <div className="screen">
                {(scenarioComponent ? scenarioComponent : Scenario[scenario].screen)}
            </div>
        </CodenamesStyles>
    )
}

export default ScreenApp;