import 'swiper/swiper.min.css';
import './assets/boxicons-2.0.7/css/boxicons.min.css';
import './App.scss';

import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Routes from './config/GamepadRoutes';
import { useAnalyticsAccessEvent } from './utils/firebase';

const UnboaredGamepad = () => {
    useAnalyticsAccessEvent('gamepad_access');
    return (
        <BrowserRouter>
            <Route render={props => (
                <>
                    <Routes />
                </>
            )} />
        </BrowserRouter>
    );
}


export default UnboaredGamepad;