import { RESPONSE_TIME_COMPUTER_LEVEL1, RESPONSE_TIME_COMPUTER_LEVEL2, RESPONSE_TIME_COMPUTER_LEVEL3, ERROR_DISC_COMPUTER_LEVEL1, ERROR_DISC_COMPUTER_LEVEL2, ERROR_DISC_COMPUTER_LEVEL3 } from "../config/Const"
import IronSpeed from "./ironSpeed"
import Message from "./message"

class UnboaredSpeed {
    constructor(ctx) {
        this.ctx = ctx
        this.game = []
        this.timeouts = []
        this.messages = []
    }

    start(canvas, humanPlayers, levelComputer, callbackEnd) {
        console.log("[UnboaredSpeed] Building for " + humanPlayers.length + " humans")
        if (humanPlayers > 4) {
            console.log("[UnboaredSpeed] Warning : Only four players can play to Unboared Speed")
        }
        
        let ironSpeed;
        switch (levelComputer) {
            case 1:
                ironSpeed = new IronSpeed(this.ctx, humanPlayers, RESPONSE_TIME_COMPUTER_LEVEL1, ERROR_DISC_COMPUTER_LEVEL1, canvas.width, canvas.height, callbackEnd)
                console.log(`GAME · ComputerLevel:${levelComputer} HumanPlayers:${humanPlayers.length}`)
                break;
            case 2:
                ironSpeed = new IronSpeed(this.ctx, humanPlayers, RESPONSE_TIME_COMPUTER_LEVEL2, ERROR_DISC_COMPUTER_LEVEL2, canvas.width, canvas.height, callbackEnd)
                console.log(`GAME · ComputerLevel:${levelComputer} HumanPlayers:${humanPlayers.length}`)
                break;
            case 3:
                ironSpeed = new IronSpeed(this.ctx, humanPlayers, RESPONSE_TIME_COMPUTER_LEVEL3, ERROR_DISC_COMPUTER_LEVEL3, canvas.width, canvas.height, callbackEnd)
                console.log(`GAME · ComputerLevel:${levelComputer} HumanPlayers:${humanPlayers.length}`)
                break;
            default:
                console.log('Error level computer')
        }

        this.game = ironSpeed
        ironSpeed.upground.run()

        this.callLater(() => { ironSpeed.dealCards() }, 1000)
        let messageDeal = this.createMessage('Dealt cards', 1000)
        this.callLater(() => { messageDeal.showMessage() }, 1000)
        let messageStart = this.createMessage('Start PLAYER1', 1000)
        this.callLater(() => { messageStart.showMessage() }, 2000)
        this.callLater(() => { ironSpeed.setStartTurn() }, 4000)

        return this;
    }

    callLater(call, delay) {
        this.timeouts.push(setTimeout(call, delay))
    }

    createMessage(txt, timeout) {
        let msg = new Message(txt, timeout)
        this.messages.push(msg)
        return msg
    }

    clearTimeouts() {
        for (var i = 0; i < this.timeouts.length; i++) {
            clearTimeout(this.timeouts[i]);
        }
    }

    clearMessages() {
        for (var i = 0; i < this.messages.length; i++) {
            this.messages[i].clear();
        }
    }

    clear() {
        this.clearTimeouts();
        this.clearMessages();
        this.game.clear();
    }

}

export default UnboaredSpeed;