import { getAvatarPath } from '../../../Tools';
import { PLAYER_COLOR } from '../../settings/Color';
import StyledPlayerIcon from './StyledPlayerIcon'

const ColorPlayerIcon = ({ avatar, id = 0, size = "6rem", ...props }) => {
    const color = PLAYER_COLOR[id];
    return (
        <StyledPlayerIcon {...props} backgroundColor={color} style={{ width: `${size}`, height: `${size}` }}>
            <img className="playerAvatar" src={getAvatarPath(avatar)} alt="Avatar" />
        </StyledPlayerIcon >
    )
}


export const AdditionalPlayerIcon = ({ num, size = 6, ...props }) => {
    const sizeIcon = `${size}rem`;
    return (
        <StyledPlayerIcon {...props} backgroundColor={'#ffffff41'} style={{ width: `${sizeIcon}`, height: `${sizeIcon}` }}>
            <p className='additionalPlayers'>{`+${num}`}</p>
        </StyledPlayerIcon>
    )
}

export default ColorPlayerIcon;