import styled from 'styled-components';

const StyledUserSettings = styled.div` 
    position: fixed;
    /* Stay in place */
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /* Enable scroll if needed */

    background-color: ${({theme}) => theme.PRIMARY_BACKGROUND_COLOR};

    /* Fallback color */

    padding: 2rem 0rem 2rem 0rem;
    h2 {
        margin: 1rem;
        text-align: center;
        font-size: 2.3rem;
        font-weight: 1000;
    }
`

export default StyledUserSettings;