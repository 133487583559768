import styled from 'styled-components';

export default styled.div`
    padding: 5rem 0 2rem;
    text-align: center;
    margin-bottom: 2rem;
    position: relative;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    h2 {
        position: relative;
        z-index: 9;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to top, ${({theme}) => theme.PRIMARY_BACKGROUND_COLOR}, rgba(0,0,0, 0));
    }
`