import { useState, useEffect } from 'react';
import { isFullscreen as checkFullscreen, toggleFullscreen } from '../Tools';

export function useIsFullscreen() {
    const [isFullscreen, setIsFullscreen] = useState(checkFullscreen());
    useEffect(() => {
        function handleFullscreenChange() {
            setIsFullscreen(checkFullscreen());
        }
        window.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => window.removeEventListener('fullscreenchange', handleFullscreenChange);
    }, []);

    return { isFullscreen };
}


export default function useFullscreen() {
    console.log("[useFullscreen] Rewrite");
    const { isFullscreen } = useIsFullscreen();
    return [isFullscreen, toggleFullscreen];
}
