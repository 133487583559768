import React from 'react'
import PropTypes from 'prop-types'
import Alert from '.'
import { logAnalyticsEvent } from '../../../utils/firebase';

const msgStyle = {
    marginBottom: '1em',
}

const PlayerLeavingAlert = ({ url }) => {
    const headerMessage = `😢 Already leaving ?`;

    const onClick = ()=> {
        logAnalyticsEvent('form_access_screen')
        window.open(url, "_blank");
    }

    return (
        <Alert message={headerMessage}>
            {/* <CenteredFlexBox> */}
            <>
                <p style={msgStyle}>
                    Before you leave, tell us about your experience with Unboared.
                </p>
                <span style={{ fontWeight: '700', fontSize: '1.8rem' }} className="validation-btn" onClick={onClick}>
                    Do it !
                </span>
            </>
            {/* </CenteredFlexBox> */}
        </Alert>
    )
}

PlayerLeavingAlert.propTypes = {
    url: PropTypes.string.isRequired,
}

export default PlayerLeavingAlert
