import styled from "styled-components";
import { device, Flex } from '../../'

const CenteredFlexBox = styled.div`
    margin: auto;
    flex-wrap: wrap;
    width: 30vw;
    ${Flex({ alignItem: "center", justifyContent: "center" })}

    @media ${device.tablet} {
        width: 95vw;
    }
`
export default CenteredFlexBox;