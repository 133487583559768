import { useListener, useScreenSession, useScreenScenario } from '../../../../unboared-com';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { NavButtonGamepad, Header, UnboaredIcon, CenteredFlexBox, InGameNavLayout } from "../../../../react-unboared";

import { GAME_NAME } from '../../config/Const';
import LogoCodenames from '../../assets/img/Logo_Codenames_White.png';

const Screen = () => {
    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);
    
    let callbackHome = () => {
        setLocalScenario(GAME_NAME, "home");
    }

    useScreenScenario(GAME_NAME, "instructions");
    useListener("pressReturn", callbackHome);

    const nav = (
        <CenteredFlexBox>
            <NavButtonGamepad
                key={"home"}
                title="Home"
                icon={<UnboaredIcon MaterialIcon={ArrowCircleLeftOutlinedIcon} size={3} />}
                onClick={callbackHome} />
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header src={LogoCodenames}/>} nav={nav}>
            <CenteredFlexBox>
                <h1>· INSTRUCTIONS ·</h1>
                <ul>
                    <li>-Instruction goes here.</li>
                </ul>
            </CenteredFlexBox>
        </InGameNavLayout>
    );
}

export default Screen;