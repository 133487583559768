import create from "zustand"
import { persist } from "zustand/middleware"

const useAccessToken = create(persist(
    (set) => ({
        token: "",
        setToken: new_token => set({ token: new_token }),
    }),
    {
        name: "unboared-token", // name of item in the storage (must be unique)
    }
))
export default useAccessToken;