
import styles from "./Header.module.scss";
import { LogoBeta } from '../../assets';

const Header = ({ src, ...props }) => {
    const sourceImg = (src ? <img src={src} alt="Unboared" /> :  <img src={LogoBeta} alt="Unboared" />);
    return (
        <div className={`${styles.header} ${props.className}`}>
            {sourceImg}
        </div>
    )
}

export default Header;

