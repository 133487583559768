import QrCodeIcon from '@mui/icons-material/QrCode';

import { useScreenSession } from '../../unboared-com';
import { UnboaredIcon, OverlayView } from '../../react-unboared';

import { QRCodeView, RoomIDView } from './ConnexionView';
import ConnectedPlayersList from '../../games/catalog/components/Players/ConnectedPlayersList'
import StyledConnexionSection, { StyledClose, RelativeView } from './StyledConnexionSection';

const CustomCenter = {
    top: '10vh',
    left: '15vw',
}

const ConnexionSection = ({ callbackClose }) => {
    const statusConnexion = useScreenSession((state) => state.statusConnexion);
    const statusPlayers = useScreenSession((state) => state.statusPlayers);

    return (<>
        <OverlayView centered={false} style={CustomCenter}>

            <StyledConnexionSection>
                <RelativeView>
                    <StyledClose onClick={callbackClose}>
                            <UnboaredIcon size={3} MaterialIcon={QrCodeIcon} />
                            <h3 className="text">close</h3>
                    </StyledClose>
                </RelativeView>
                <h1>Take your phone to start playing </h1>
                <p className='hint'>💡 When all the players are connected, click on <UnboaredIcon className='icon' size={2.5} MaterialIcon={QrCodeIcon} /> to close this</p>
                <div className="connexion-info connexion-method">
                    <h2>Method 1 : Connect with QR Code </h2 >
                    <QRCodeView roomID={statusConnexion.roomID} />
                    <h2 >Method 2 : go on <i>unboared.com</i></h2 >
                    <RoomIDView roomID={statusConnexion.roomID} />
                </div>
                <div className="connexion-info connexion-player">
                    <p>{statusPlayers.players.length} players connected</p>
                    <ConnectedPlayersList players={statusPlayers.players} masterID={statusPlayers.masterID} size={6} />
                </div>
            </StyledConnexionSection>
        </OverlayView>
    </>
    );
}

export default ConnexionSection;