import styled from 'styled-components';

import { COLOR } from '../../../../react-unboared';

const StyledTableResults = styled.table`
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 3px solid ${COLOR.PRIMARY};

    thead {
        font-size: 1.5rem;
        font-weight: 700;
        color: ${COLOR.SECONDARY};
    }

    tbody {
        font-size: 1.2rem;
    }

    thead th:nth-child(1) {
        width: 10%;
    }

    thead th:nth-child(2) {
        width: 20%;
    }

    thead th:nth-child(3) {
        width: 15%;
    }

    thead th:nth-child(4) {
        width: 35%;
    }

    th,
    td {
        padding: 0.2rem;
    }
`

export default StyledTableResults;