import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { useScreenSession, useScreenCom } from '../../unboared-com';

import { category as cate } from '../../api/unboaredApi';
import PageHeader from '../components/page-header/PageHeader';
import GameGrid from '../components/games-collection/GameGrid';


const Catalog = () => {
    const statusConnexion = useScreenSession((state) => state.statusConnexion);

    const { changeScenario } = useScreenCom()

    useEffect(() => {
        if (statusConnexion.roomID !== 0) {
            changeScenario("catalog", "home");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { category } = useParams();

    return (
        <>
            <PageHeader>
                {category === cate.boardgame ? 'All Games' : 'Video Games'}
            </PageHeader>
            {/* <div className="container-fluid">
                <div className="section mb-3"> */}
            <GameGrid category={category} />
            {/* </div>
            </div> */}
        </>
    );
}

export default Catalog;
