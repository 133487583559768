import React from 'react';

// import './page-header.scss';
import Style from './Style';

import bg from '../../../assets/footer-bg.png';

const PageHeader = props => {
    return (
        <Style style={{backgroundImage: `url(${bg})`}}>
            <h2>{props.children}</h2>
        </Style>
    );
}


export default PageHeader;
