const SocketIOConfig = {
    type: 'prod',

    dev: {
        PROTOCOL: "http",
        SERVER: "192.168.1.24",
        PORT: "5000"
    },
    prod: {
        PROTOCOL: "https",
        SERVER: "unboared-server-test.herokuapp.com",
        PORT: ""
    },
}

const getConfig = (protocol = SocketIOConfig) => {
    if (protocol.type === "dev") return protocol.dev
    else if (protocol.type === "prod") return protocol.prod
    else return protocol.prod
}

const useDefaultGame = () => {
    return 'catalog';
}

const useDefaultScenario = () => {
    return 'home';
}

const useDefaultStatusPlayers = () => {
    return { players: [], masterID: 0 };
}

const useDefaultStatusConnexion = () => {
    return { roomID: '0' };
}

const isProduction = () => {
    return (SocketIOConfig.type === 'prod')
}

export { getConfig, isProduction, SocketIOConfig, useDefaultStatusPlayers, useDefaultStatusConnexion, useDefaultGame, useDefaultScenario }