import styled from 'styled-components'
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';


import UserSetting from '../../setting/UserSetting';
import { DARK_COLOR, LIGHT_COLOR, PlayerIcon, UnboaredIcon } from "../../../react-unboared";
import { PLAYER_COLOR } from "../../../react-unboared";
import { useGamepadSession } from "../../../unboared-com";
import Username from '../../../react-unboared/components/user/Username';
import GFullscreenButton from '../buttons/GFullscreenButton';

const StyledCustomizableIcon = styled.div`
    position:absolute;
    padding : 0.8rem;
    top:-20%;
    right:-20%;
    background-color : ${props => props.backgroundColor};
    color:${LIGHT_COLOR};
    border: 0.3rem solid ${DARK_COLOR};
    border-radius : 100%;
`

const StyledProfilNav = styled.div`
    position: fixed;
    top: 3rem;
    left: 50%;
    transform: translate(-50%, -50%);
    width:85vw;
    margin-top: 3rem;
    * {
        vertical-align: middle;
    }
    
    .icon {
        margin-right : 2.2rem;
        position:relative;
        display:inline-block;
    }
    .username {
        display:inline-block;
    }
    
    .fullscreen {
        position:absolute;
        right:0;
        top:50%;
        transform: translate(0%, -50%);
    }
`

const CustomizableIcon = ({ color, ...props }) => {
    return (
        <StyledCustomizableIcon {...props} backgroundColor={color}>
            <UnboaredIcon MaterialIcon={EditIcon} size={2}/>
        </StyledCustomizableIcon >
    );
}

const ProfilNav = () => {
    const { avatar, username, id, statusPlayers } = useGamepadSession();
    const color = PLAYER_COLOR[id];

    const [showSetting, setShowSetting] = useState(false);

    const toggleSetting = () => {
        setShowSetting((prev) => !prev)
    }

    if (showSetting) {
        return <UserSetting onClose={toggleSetting} />
    }

    return (
        <StyledProfilNav>
            <div className='icon'>
                <PlayerIcon avatar={avatar} username={username} id={id} size={"8rem"} onClick={toggleSetting} />
                <CustomizableIcon color={color} />
            </div>
            <Username className="username" username={username} isMaster={statusPlayers.masterID === id} />
            <GFullscreenButton className="fullscreen" size={6}/>
        </StyledProfilNav>
    )
}

export default ProfilNav;