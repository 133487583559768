import React from "react";
import LogoUnboared from '../../assets/logoCropped.png'

const UnboaredIcon = ({ size, MaterialIcon, faIcon, ...props }) => {
  const size_icon = `${size}rem`;

  if (MaterialIcon !== undefined) {
    return <MaterialIcon {...props} sx={{ fontSize: size_icon }} style={{ verticalAlign: "middle" }} />
  }
  else {
    return <img {...props} src={LogoUnboared} style={{ width: size_icon, verticalAlign: "middle" }} alt="Unboared" />
  }
}

export default UnboaredIcon;