import styled from 'styled-components';

const StyledBtn = styled.div`
    margin: auto;
    display: flex;
    width: 45%;
    height: 45%;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.btnColor};
    cursor:pointer;
    opacity : ${props => props.opacity};
`

const ProposalButton = ({ color, disable, ...props }) => {

    return (
        <StyledBtn btnColor={color} opacity={disable ? 0.2 : 0.9} {...props}>
            {props.children}
        </StyledBtn>
    )
}

export default ProposalButton