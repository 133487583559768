export const GAME_NAME = "jungle-speed"

export const Z_KEY = 90
export const X_KEY = 88
export const ARROWDOWN_KEY = 40
export const ARROWRIGHT_KEY = 39
export const DISC_SIZE = window.innerHeight * 0.2
export const RESPONSE_TIME_COMPUTER_LEVEL1 = 1100
export const ERROR_DISC_COMPUTER_LEVEL1 = 0.03
export const RESPONSE_TIME_COMPUTER_LEVEL2 = 700
export const ERROR_DISC_COMPUTER_LEVEL2 = 0.015
export const RESPONSE_TIME_COMPUTER_LEVEL3 = 300
export const ERROR_DISC_COMPUTER_LEVEL3 = 0.005
export const COLOR_PLAYER = ["#ff2453", "#f3ca20", "#1bc65f", "#20abf3"]


export const TEXT_COLOR = '#000000';
export const BACKGROUND_COLOR = '#f3ca20';

