import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
// import SettingsIcon from '@mui/icons-material/Settings';
import { useListener, useScreenSession, useScreenScenario } from '../../../unboared-com';
import { CenteredFlexBox, Header, NavButtonGamepad, UnboaredIcon, InGameNavLayout } from '../../../react-unboared';

import menu_logo from '../assets/img/unblind-test.png'
import { GAME_NAME } from '../config/Const';

const Screen = () => {
    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);

    const callbackInstruction = () => {
        setLocalScenario(GAME_NAME, "instructions");
    }

    // let callbackCategory = () => {
    //     setLocalScenario(GAME_NAME, "category");
    // }

    const callbackPlay = () => {
        setLocalScenario(GAME_NAME, "game");
    }

    // Update Scenario to category selection
    useScreenScenario(GAME_NAME, "home");

    // Listen press instruction menu button 
    useListener("pressInstructions", callbackInstruction);

    // Listen press play menu button 
    useListener("pressPlay", callbackPlay);

    const nav = (
        <CenteredFlexBox>
            <NavButtonGamepad
                key={"instructions"}
                title="Instructions"
                icon={<UnboaredIcon MaterialIcon={HelpOutlineIcon} size={4} />}
                onClick={callbackInstruction} />
            <NavButtonGamepad
                key={"play"}
                title="Play"
                icon={<UnboaredIcon MaterialIcon={PlayCircleOutlineIcon} size={4} />}
                onClick={callbackPlay}
            />
            {/* <NavButtonGamepad
                key={"settings"}
                title="Settings"
                icon={<UnboaredIcon MaterialIcon={SettingsIcon} size={3} />}
                onClick={() => emitAction("pressSettings")}
            /> */}
        </CenteredFlexBox>
    );


    return (
        <InGameNavLayout header={<Header src={menu_logo}/>}>
            {nav}
        </InGameNavLayout>
    )
}

export default Screen;