import 'swiper/swiper.min.css';
import './assets/boxicons-2.0.7/css/boxicons.min.css';
import './App.scss';
import styled from 'styled-components';

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { useScreenCom, useScreenSession, useListener } from './unboared-com';
import { /* useSoundManager,  */useWindowOrientation, AmbianceSound, OverlayView, ChangeOrientation } from './react-unboared/';

import ScreenRoutes from './config/ScreenRoutes';

import { toggleFullscreen } from './Tools';
import useAccessToken from './store/useAccessToken';
import AccessPage from './screen/access/AccessPage'
import FollowPage from './screen/access/FollowPage'
import LaunchPage from './screen/access/LaunchPage';
import { ACCESS_TOKEN, RESTRICT_ACCESS } from './config/Const';
import VideoPlayer from './screen/components/video/VideoPlayer';
import SplashScreen from './assets/video/SplashScreen2.mp4';
import LayoutUnboaredUI from './screen/components/layouts/S_LayoutUnboaredUI';
import LayoutGameScreen from './screen/components/layouts/S_LayoutGame';
import useSystemActions from './hooks/useSystemActions';

import { useAnalyticsAccessEvent, logAnalyticsEvent } from './utils/firebase';
import PlayerLeavingAlert from './screen/components/Alert/PlayerLeavingAlert';

const UnboaredScreenBase = () => {
    console.log("[UnboaredScreenApp] REWRITE App")
    const { showConnexionInfos, toggleShowConnexionInfos } = useSystemActions();

    const game = useScreenSession(state => state.game);
    const { createRoom, deleteRoom } = useScreenCom();
    const statusConnexion = useScreenSession((state) => state.statusConnexion);
    const setStatusConnexion = useScreenSession((state) => state.setStatusConnexion);
    const setStatusPlayers = useScreenSession((state) => state.setStatusPlayers);
    const windowOrientation = useWindowOrientation();

    useEffect(() => {
        console.log("[UnboaredScreenApp] Create room")
        createRoom();
        logAnalyticsEvent('screen_session');

        return () => {
            console.log("[UnboaredScreenApp] Delete room")
            deleteRoom(statusConnexion.roomID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useListener("statusConnexion", setStatusConnexion)
    useListener("statusPlayers", data => {
        if (data.players.length === 0 && !showConnexionInfos) {
            toggleShowConnexionInfos();
        }
        if (data.players.length === 1 && showConnexionInfos) {
            toggleShowConnexionInfos();
        }
        setStatusPlayers(data);
    }, [showConnexionInfos])


    if (windowOrientation === 'PORTRAIT') {
        return <ChangeOrientation to='LANDSCAPE' />
    }

    return (
        <BrowserRouter >
            <Route render={props => (
                <>
                    {(game === "catalog") ? <LayoutUnboaredUI><ScreenRoutes /></LayoutUnboaredUI> : <LayoutGameScreen><ScreenRoutes /></LayoutGameScreen>}
                </>
            )} />
        </BrowserRouter>

    );
}

const LeavableZone = styled.div`
        position:absolute;
        top:0;
        left:0;
        // border: 5px solid red;
        height: 100vh;
        width: 100vw;
`

const UnboaredScreenLeavable = () => {
    /* MOUSE OUT */
    const [alert, setAlert] = useState(undefined);

    const showLeaveMessage = () => {
        if (!alert) {
            setAlert(<PlayerLeavingAlert url={"https://docs.google.com/forms/d/e/1FAIpQLSfcP0kzpL5cTCsJ7wMnLjB5FkLkght_keNLoHpua5Mv-5PTAw/viewform?usp=sf_link"} />);
            setTimeout(() => {
                setAlert(undefined);
            }, 5000);
        }
        return true;
    }

    return (
        <LeavableZone onMouseLeave={() => showLeaveMessage()}>
            {alert && (
                <OverlayView>
                    {alert}
                </OverlayView>
            )}
            <UnboaredScreenBase />
        </LeavableZone>

    );
}

const UnboaredScreen = () => {

    useAnalyticsAccessEvent('screen_access');

    const { token } = useAccessToken();
    const [launched, setLaunched] = useState(false);
    const [splash, setSplash] = useState(true);

    const launchUnboared = () => {
        setLaunched(true);
        toggleFullscreen();
    }

    if (RESTRICT_ACCESS && (token !== ACCESS_TOKEN)) {
        return (<BrowserRouter >
            <Switch>
                <Route exact path="/">
                    <AccessPage onConnect={() => { logAnalyticsEvent('screen_connect'); launchUnboared() }} />
                </Route>
                <Route path="/community">
                    <FollowPage />
                </Route>
            </Switch>
        </BrowserRouter>)
    }
    else {
        if (!launched) {
            return <LaunchPage onClick={launchUnboared}>Launch</LaunchPage>
        }
    }

    if (splash) {
        return <VideoPlayer src={SplashScreen} onEnd={() => setSplash(false)} />
    }


    return <>
        <UnboaredScreenLeavable />
        <AmbianceSound />
    </>;

}

export default UnboaredScreen;