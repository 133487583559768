
import styled from 'styled-components';
import { useState, useEffect } from 'react'

import { useGamepadCom, useGamepadSession } from "../../../unboared-com";
import { PLAYER_COLOR, useWindowOrientation, ChangeOrientation, CenterDiv } from '../../../react-unboared';
import { getUserPosition } from '../../../Tools';
// import Pad from "../components/Pad/Pad";
import Joystick from "../components/Joystick/Joystick";
import BombImage from '../assets/bomb.png';



const StyledGamepad = styled.div`
position: absolute;
width: 98vw;
height: 80vh;
top: 18vh;
padding: 0 ;
display: flex;
.left {
    width: 50%;
    height: 100%;
    #pad {
        color:${({ color }) => color};
        position :absolute;
        bottom : 15%;
        left : 0;
    }
}

.right {
    position :relative;
    // margin: 5%;
    width: 50%;
    height: 100%;
    
    #bomb {
        border-radius : ${({ theme }) => theme.BORDER_RADIUS};
        position :absolute;
        bottom : 5%;
        right : 5%;
        width: 200px;
        height: 200px;
        background-color: ${({ color }) => color}20;
        img {
            ${CenterDiv()}
            height:70%;
        }
    }
}
.centerMsg {
    position:absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%)
}
`

const Gamepad = () => {
    const { emitAction } = useGamepadCom();
    const id = useGamepadSession(state => state.id);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentJoystickState, setCurrentJoystickState] = useState(undefined);
    const statusPlayers = useGamepadSession(state => state.statusPlayers);
    const windowOrientation = useWindowOrientation();

    console.log(currentJoystickState)

    const goDir = (dir) => {
        setCurrentJoystickState((prevDir) => {
            if (prevDir && prevDir !== dir) {
                emitAction('message', { type: 'keyup', value: prevDir })
            }
            if (dir) {
                emitAction('message', { type: 'keydown', value: dir })
            }
            return dir
        })

    }

    useEffect(() => {
        setIsPlaying(getUserPosition(statusPlayers.players, id) < 4)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!isPlaying) {
        return <StyledGamepad color={PLAYER_COLOR[id]}><h1 className='centerMsg' style={{ color: 'black' }}>It is a 4 player game !</h1></StyledGamepad>
    }


    if (windowOrientation === 'PORTRAIT') {
        return <ChangeOrientation to='LANDSCAPE' />
    }


    return (
        <StyledGamepad color={'#FFFFFF'}>
            <div className='left'>
                <div id='pad'>
                    <Joystick
                        size={200}
                        color={"rgb(108, 112, 141)"}

                        onStart={() => console.log("onStart")}
                        onEnd={() => goDir(undefined)}
                        onMove={() => console.log("onMove")}

                        onDir={() => console.log("onDir")}
                        onDirUp={() => goDir('up')}
                        onDirDown={() => goDir('down')}
                        onDirLeft={() => goDir('left')}
                        onDirRight={() => goDir('right')}


                        onPlane={() => console.log("onPlane")}
                        onPlaneUp={() => console.log("onPlaneUp")}
                        onPlaneDown={() => console.log("onPlaneDown")}
                        onPlaneLeft={() => console.log("onPlaneLeft")}
                        onPlaneRight={() => console.log("onPlaneRight")}
                    />
                    {/* <Pad
                        onTouchStartUp={() => emitAction('message', { type: 'keydown', value: 'up' })}
                        onTouchEndUp={() => emitAction('message', { type: 'keyup', value: 'up' })}
                        onTouchStartDown={() => emitAction('message', { type: 'keydown', value: 'down' })}
                        onTouchEndDown={() => emitAction('message', { type: 'keyup', value: 'down' })}
                        onTouchStartLeft={() => emitAction('message', { type: 'keydown', value: 'left' })}
                        onTouchEndLeft={() => emitAction('message', { type: 'keyup', value: 'left' })}
                        onTouchStartRight={() => emitAction('message', { type: 'keydown', value: 'right' })}
                        onTouchEndRight={() => emitAction('message', { type: 'keyup', value: 'right' })}
                    /> */}
                </div>
            </div>
            <div className='right'>
                <div id='bomb' onTouchEnd={() => emitAction('message', { type: 'keyup', value: 'bomb' })}>
                    <img src={BombImage} alt='BOMB' />
                </div>
            </div>
        </StyledGamepad>
    );
}


export default Gamepad;



// import { useGamepadCom } from "../../../unboared-com";
// import { InGameLayout } from "../../../react-unboared";

// import Pad from "../components/Pad/Pad";

// const Gamepad = () => {
//     const { emitAction } = useGamepadCom()

//     return (
//         <InGameLayout header={<></>} >
//             <Pad
//                 onTouchStartOK={() => {}}
//                 onTouchEndOK={() => emitAction('message', { type: 'keyup', value: 'bomb' })}
//                 onTouchStartUp={() => emitAction('message', { type: 'keydown', value: 'up' })}
//                 onTouchEndUp={() => emitAction('message', { type: 'keyup', value: 'up' })}
//                 onTouchStartDown={() => emitAction('message', { type: 'keydown', value: 'down' })}
//                 onTouchEndDown={() => emitAction('message', { type: 'keyup', value: 'down' })}
//                 onTouchStartLeft={() => emitAction('message', { type: 'keydown', value: 'left' })}
//                 onTouchEndLeft={() => emitAction('message', { type: 'keyup', value: 'left' })}
//                 onTouchStartRight={() => emitAction('message', { type: 'keydown', value: 'right' })}
//                 onTouchEndRight={() => emitAction('message', { type: 'keyup', value: 'right' })}
//             />
//         </InGameLayout>
//     )
// }

// export default Gamepad;

