import create from "zustand"

const useConnexionStore = create(
    (set) => ({
        showConnexionInfos: true,
        toggleShowConnexionInfos: () => set((state) => ({ showConnexionInfos: !state.showConnexionInfos }))
    })
)

export default function useConnexionInfos() {
    console.log("[useConnexionInfos] Rewrite");
    const { showConnexionInfos, toggleShowConnexionInfos } = useConnexionStore();

    return [showConnexionInfos, toggleShowConnexionInfos];
}
