import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../../react-unboared';

const BORDER_RADIUS = 30;
const BORDER_SIZE = "0.5rem";


export const StyledGameCard = styled.div`
  padding:1.2rem;
  opacity : ${(props)=> props.opacity};
`

export const StyledGameCardImage = styled.div` 
    z-index: 9;
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 90%;
    border-radius: ${BORDER_RADIUS}px;
    margin-bottom: 1rem;
    border: ${BORDER_SIZE} solid ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
    button {
        font-size: 1.2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
        opacity: 0;
        border-radius: ${BORDER_RADIUS * 0.8}px;
        transition: opacity 0.3s ease;
    }

    // @media (hover: hover) and (pointer: fine) {
    //     &:hover::before {
    //         opacity: 0.8;
    //     }
    //     &:hover button {
    //         transform: translate(-50%, -50%) scale(1);
    //     }
    // }

    &.selectedBtn {

        -ms-transform: scale(1.15);
        /* IE 9 */
        -webkit-transform: scale(1.15);
        /* Safari 3-8 */
        transform: scale(1.15);
        transition-duration: 500ms;
    }
    
    &.enable, &.new {
        border: ${BORDER_SIZE} solid ${({ theme }) => theme.PRIMARY_ACTIVE_BUTTON_COLOR};
    }
    &.disable {
        border: ${BORDER_SIZE} solid #b9b9b9;
    }
`

export const StyledGameCardTitle = styled.h3`
    &.selectedTitle{
        -ms-transform: scale(1.15);
        /* IE 9 */
        -webkit-transform: scale(1.15);
        /* Safari 3-8 */
        transform: scale(1.15);
        transition-duration: 500ms;
    }
    &.enable, &.new {
        color: ${PRIMARY_COLOR};
    }
    &.disable {
        color: #b9b9b9;
    }
`