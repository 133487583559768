import React from 'react';

import { StyledGameCard, StyledGameCardImage, StyledGameCardTitle } from './StyledGameCard';

import apiConfig from '../../../api/apiConfig';
import useSystemActions from '../../../hooks/useSystemActions';

const opacity = {
    "enable": 1.0,
    "new": 1.0,
    "disable": 0.3,
}

/**
 * Unboared card component. This component display a clickable card of a game.
 * @param {dict} item - A dictionnary containing informations about the game.
 * @param {int} gameID - The identifier of the game.
 * @param {boolean} isSelected - If the game is the current selected one.
 */
const GameCard = ({ item, gameID, isSelected, ...props }) => {
    const bg = apiConfig.w500Image(item.poster_path || item.backdrop_path);
    const cardOpacity = opacity[item.status];
    const { toggleShowConnexionInfos } = useSystemActions();

    return (
        <StyledGameCard opacity={cardOpacity} onClick={toggleShowConnexionInfos}>
            <StyledGameCardImage className={(isSelected) ? `selectedBtn ${item.status}` : ""} style={{ backgroundImage: `url(${bg})` }}/>
            <StyledGameCardTitle className={(isSelected) ? `selectedTitle ${item.status}` : ""} >
                {item.title || item.name}
            </StyledGameCardTitle>
        </StyledGameCard>
    );
}

export default GameCard;
