// import { useState } from 'react';
import styled from 'styled-components';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import { useGamepadCom } from '../../unboared-com';
import { UnboaredIcon, InGameLayout, NavButton } from '../../react-unboared'

import styles from './PauseMenu.module.scss';
import NavWidget from '../components/nav/NavWidget';
// import useFullscreen from '../../hooks/useFullscreen';
// import UserSetting from "../setting/UserSetting";

const Option = ({ icon, title, onClick }) => {

    return (
        <NavButton className={styles.option} onClick={onClick}>
            <div className={styles.icon}>
                {icon}
            </div>
            <h2 className={styles.optionTitle}>{title}</h2>
        </NavButton>
    );
}

const PauseMenu = ({ onClose }) => {
    const size_menu_icon = 6;
    const { emitAction } = useGamepadCom();
    // const [isFullscreen, toggleFullscreen] = useFullscreen();

    // const toggleMuteScreen = () => {
    //     emitAction("toggleMute");
    // }

    const goToCatalog = () => {
        emitAction("pressUnboaredGames");
    }

    const restartGame = () => {
        emitAction("pressRestart");
        onClose();
    }

    // const toggleConnexionScreen = () => {
    //     emitAction("pressConnexionInfo");
    // }

    // const [showSetting, setShowSetting] = useState(false);

    // const toggleSetting = () => {
    //     setShowSetting((prev) => !prev)
    //     onClose();
    // }

    // if (showSetting) {
    //     return <UserSetting onClose={toggleSetting} />
    // }

    return (
        <StyledPauseMenu>
            <NavWidget MaterialIcon={KeyboardReturnIcon} onClick={onClose} />

            <InGameLayout header={<h2>Options</h2>}>
                <div className={styles.options}>
                    <Option title={"Resume"} icon={<UnboaredIcon size={size_menu_icon} MaterialIcon={KeyboardReturnIcon} />} onClick={onClose} />
                    <Option title={"Restart"} icon={<UnboaredIcon size={size_menu_icon} MaterialIcon={RestartAltIcon} />} onClick={restartGame} />
                    <Option title={"Main menu"} icon={<UnboaredIcon size={size_menu_icon} MaterialIcon={VideogameAssetIcon} />} onClick={goToCatalog} />
                    {/* <Option title={"Show / Hide Connexion"} icon={<UnboaredIcon size={size_menu_icon} MaterialIcon={QrCode2OutlinedIcon} />} onClick={toggleConnexionScreen} />
                    {FullScreenOption}
                    <Option title={"Mute / Unmute"} icon={<UnboaredIcon size={size_menu_icon} MaterialIcon={VolumeUpIcon} />} onClick={toggleMuteScreen} />
                    <Option title={"User settings"} icon={<UnboaredIcon size={size_menu_icon} MaterialIcon={PersonIcon} />} onClick={toggleSetting} /> */}
                </div>
            </InGameLayout>
        </StyledPauseMenu>
    )
}

const StyledPauseMenu = styled.div`
/* Stay in place */
position: fixed;
z-index: 98;
background-color: ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
height:100vh;

h2 {
    font-size: 4rem;
    font-weight: 1000;
}
`

export default PauseMenu;