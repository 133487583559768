import { Header, InGameLayout, CenteredFlexBox } from '..';
import { FadeLoader } from 'react-spinners';
import React, { useTheme } from 'styled-components';

const UnboaredLoader = (props) => {
    const theme = useTheme();

    const header = (<>
        <CenteredFlexBox >
            <Header />
            <FadeLoader color={theme.PRIMARY_TEXT_COLOR} loading={true} />
        </CenteredFlexBox>
    </>)
    return (
        <InGameLayout header={header}>
            {props.children}
        </InGameLayout>
    )
}

export default UnboaredLoader;