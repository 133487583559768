export const DiscSVG = () => {
    return <svg version="1.1" id="Calque_1"
        xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 300 300">
        <g>
            <path fill="currentColor" className="st0" d="M69.2,149.2c0.15,0.66,0.15,1.31,0,1.97C68.63,150.52,68.61,149.86,69.2,149.2z" />
            <path fill="currentColor" d="M228.41,144.47c-6.6-11.93-13.06-23.94-19.58-35.92c-5.02-9.25-10.04-18.5-15.06-27.75c-1.79-3.3-4.46-5.01-8.32-5
				c-23.91,0.04-47.83,0.06-71.74-0.02c-3.84-0.01-6.44,1.73-8.17,4.86c-5.66,10.26-11.22,20.57-16.81,30.87
				c-6.05,11.14-12.1,22.27-18.16,33.41c-0.73,1.34-1.33,2.73-1.38,4.29c-0.17,0.66-0.18,1.31,0,1.97c-0.03,1.49,0.57,2.8,1.26,4.07
				c3.9,7.22,7.83,14.43,11.75,21.65c4.76,8.76,9.53,17.51,14.29,26.27c3.01,5.53,5.97,11.09,9.04,16.59
				c1.81,3.23,4.48,4.88,8.4,4.86c23.75-0.09,47.51-0.07,71.26-0.02c3.92,0.01,6.71-1.5,8.53-4.98c2.07-3.95,4.25-7.84,6.4-11.76
				c4.78-8.74,9.58-17.46,14.34-26.21c4.65-8.54,9.18-17.15,13.91-25.65C230.56,152.06,230.59,148.41,228.41,144.47z M221.39,151.74
				c-1.79,3.19-3.57,6.38-5.27,9.63c-0.55,1.06-1.19,1.61-2.41,1.37c-3.11-0.62-4.84,0.68-6.26,3.54
				c-3.36,6.75-7.12,13.31-10.72,19.94c-1.82,3.35-3.62,6.71-5.5,10.03c-0.52,0.92-0.73,1.61-0.05,2.59
				c0.86,1.22,1.42,2.66,2.24,3.92c0.53,0.81,0.41,1.32-0.11,2.11c-2.19,3.3-4.3,6.66-6.38,10.03c-0.42,0.69-0.87,0.97-1.68,1.07
				c-2.3,0.28-4.57,0.43-6.89,0.05c-1.48-0.25-3.04-0.19-4.55-0.05c-1.57,0.14-2.74-0.3-3.19-1.81c-0.82-2.78-2.76-3.09-5.31-3.06
				c-10.96,0.12-21.92,0.08-32.87,0.02c-1.62-0.01-2.81,0.25-3.27,1.98c-0.06,0.23-0.23,0.42-0.33,0.64
				c-0.77,1.67-1.92,2.51-3.9,2.24c-2.3-0.31-4.61,0.21-6.92,0.29c-0.64,0.02-1.28,0-1.92,0c-2.12,0-3.67-0.78-4.78-2.75
				c-1.57-2.76-3.41-5.36-5.15-8.02c-0.75-1.14-1.05-2.17-0.06-3.44c0.83-1.05,1.27-2.39,2-3.53c0.45-0.71,0.45-1.23,0.05-1.95
				c-2.04-3.68-4.03-7.39-6.04-11.09c-2.46-4.54-4.92-9.08-7.4-13.61c-1.41-2.58-2.88-5.13-4.28-7.72
				c-0.55-1.01-1.27-1.68-2.47-1.42c-3.38,0.75-5.04-1-6.27-3.88c-1.03-2.4-2.48-4.62-3.76-6.92c-0.51-0.92-0.96-1.76-0.32-2.87
				c1.98-3.46,3.9-6.94,5.77-10.46c0.4-0.75,0.81-1.07,1.65-0.98c0.71,0.07,1.46-0.09,2.15,0.04c2.16,0.43,3.06-0.85,3.93-2.52
				c2.35-4.51,4.83-8.95,7.24-13.42c2.27-4.2,4.53-8.4,6.8-12.59c0.91-1.67,1.75-3.39,2.77-4.99c0.55-0.87,0.57-1.58,0.1-2.41
				c-0.74-1.32-1.39-2.69-2.21-3.95c-0.52-0.8-0.47-1.38,0.03-2.15c2.21-3.4,4.36-6.82,6.52-10.25c0.48-0.77,1.14-0.97,2.02-0.95
				c1.99,0.05,3.99,0.02,5.98,0.02c2,0,4,0.01,6-0.01c0.78-0.01,1.22,0.27,1.7,0.98c0.84,1.24,0.99,3.19,2.4,3.71
				c1.34,0.49,3,0.11,4.52,0.11c10.48,0,20.96-0.04,31.43,0.04c1.8,0.01,3.14-0.23,3.68-2.17c0.12-0.45,0.48-0.83,0.67-1.26
				c0.44-0.97,1.11-1.44,2.24-1.42c4,0.05,8,0.03,11.99,0c0.9-0.01,1.44,0.32,1.92,1.09c2.07,3.29,4.17,6.56,6.34,9.78
				c0.63,0.94,0.66,1.6-0.01,2.54c-0.82,1.16-1.28,2.56-2.1,3.72c-0.69,0.98-0.48,1.69,0.04,2.61c2.91,5.2,5.8,10.42,8.64,15.66
				c2.8,5.16,5.56,10.34,8.27,15.54c0.85,1.63,1.86,2.73,3.86,2.28c2.66-0.6,3.95,0.68,4.99,3.04c1.2,2.74,2.86,5.29,4.37,7.9
				C221.97,149.67,222,150.65,221.39,151.74z"/>
            <g>
                <path fill="currentColor" d="M163.52,130.59c-1.07-2.09-2.38-2.91-4.65-2.88c-6.23,0.08-12.47,0.14-18.7-0.03c-2.32-0.06-3.63,1-4.53,2.8
					c-2.59,5.19-5.58,10.16-8.25,15.3c-2.71,5.2-2.58,3.74-0.07,8.66c2.64,5.16,5.72,10.1,8.28,15.31c0.97,1.98,2.43,3.13,4.92,2.94
					c3.02-0.23,6.07-0.06,9.11-0.06c3.28,0,6.56-0.09,9.83,0.04c1.78,0.07,2.93-0.74,3.69-2.12c3.32-6.05,6.55-12.15,9.88-18.2
					c0.85-1.55,0.85-2.85-0.05-4.44C169.73,142.2,166.51,136.45,163.52,130.59z"/>
                <path fill="currentColor" d="M202.32,162.8c-0.82,0.07-1.24,0.01-1.66-0.81c-1.82-3.53-3.74-7.01-5.68-10.49c-0.53-0.95-0.49-1.74,0.04-2.68
					c1.87-3.33,3.7-6.68,5.47-10.06c0.44-0.83,0.95-1.16,1.86-1.13c1.49,0.05,2.98,0.01,4.84,0.01
					c-5.98-10.99-11.79-21.67-17.64-32.4c-0.95,0.7-1.36,1.39-1.6,2.14c-0.41,1.3-1.34,1.67-2.57,1.65
					c-3.67-0.07-7.35-0.12-11.02-0.18c-1.23-0.02-2.11-0.62-2.75-1.63c-1.93-3.09-3.8-6.21-5.79-9.26c-0.72-1.1-0.81-2.01-0.05-3.06
					c0.32-0.45,0.56-0.96,0.84-1.44c1.18-2.01,1.1-2.16-1.26-2.16c-10.47-0.01-20.95,0-31.42,0c-0.32,0-0.64-0.02-0.96,0.01
					c-1.26,0.09-1.54,0.47-0.85,1.42c0.58,0.79,0.84,1.72,1.39,2.5c0.71,1,0.54,1.8-0.16,2.8c-1.99,2.86-4.02,5.73-5.68,8.79
					c-0.83,1.53-1.8,2.05-3.36,2.06c-3.19,0.03-6.39,0.01-9.57,0.19c-1.95,0.11-3.2-0.48-3.93-2.32c-0.2-0.51-0.4-1.19-1.15-1.54
					c-5.89,10.68-11.76,21.34-17.54,32.43c1.77,0,3.12,0.04,4.46-0.01c1.05-0.04,1.66,0.2,2.2,1.3c1.68,3.41,3.55,6.74,5.45,10.04
					c0.58,1,0.47,1.8-0.07,2.66c-2.07,3.31-3.77,6.82-5.57,10.27c-0.41,0.79-0.78,0.97-1.64,0.89c-1.48-0.13-2.97-0.04-4.91-0.04
					c6,11.08,11.8,21.79,17.69,32.66c0.74-1.17,1.3-2.12,1.93-3.04c0.47-0.68,1.06-1.06,2-1.03c3.11,0.1,6.23,0.23,9.34,0.08
					c2.41-0.12,3.95,0.53,5.14,2.81c1.49,2.87,3.44,5.51,5.3,8.18c0.66,0.94,0.68,1.73,0.14,2.64c-0.52,0.89-0.89,1.85-1.52,2.7
					c-0.47,0.63-0.37,1.23,0.61,1.29c0.4,0.02,0.8,0.02,1.2,0.02c10.47,0,20.95,0.01,31.42,0c2.27,0,2.27-0.02,1.15-2.16
					c-0.29-0.56-0.45-1.22-0.85-1.7c-0.78-0.94-0.52-1.78,0.07-2.6c1.76-2.45,3.41-4.98,4.75-7.68c1.31-2.65,3.39-3.54,6.14-3.45
					c0.32,0.01,0.64,0,0.96,0c2.71-0.03,5.43-0.01,8.14-0.11c1.15-0.04,1.79,0.48,2.21,1.43c0.37,0.86,0.72,1.72,1.74,2.27
					c5.85-10.74,11.64-21.4,17.58-32.31C205.31,162.77,203.8,162.68,202.32,162.8z M175.11,152.61c-2.61,4.91-5.25,9.8-7.97,14.65
					c-0.78,1.38-1.52,2.8-2.25,4.2c-1.21,2.33-3.06,3.09-5.44,3.1c-3.36,0.02-6.71,0-10.07,0c-3.04,0-6.08-0.1-9.11,0.03
					c-3.08,0.13-4.99-1.06-6.37-3.9c-2.15-4.42-4.83-8.59-7.1-12.95c-0.95-1.82-2.06-3.56-2.8-5.51c-0.54-1.43-0.59-2.71,0.02-4.14
					c1.12-2.64,2.66-5.04,4-7.55c2.07-3.86,4.24-7.66,6.28-11.52c1.21-2.29,3-3.25,5.58-3.22c6.63,0.07,13.26,0.04,19.89,0.02
					c2.29-0.01,3.89,0.97,4.98,3c3.38,6.28,6.83,12.53,10.24,18.8C175.89,149.25,175.97,150.98,175.11,152.61z"/>
            </g>
        </g>
    </svg>;
};
