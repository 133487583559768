import { useEffect } from "react";
import { useScreenCom } from "../unboared-com"

import useFullscreen from "./useFullscreen"
import useConnexionInfos from "./useConnexionInfos"

export default function useSystemActions() {
    console.log("[useSystemActions] Rewrite");
    const [isFullscreen, toggleFullscreen] = useFullscreen();
    const [showConnexionInfos, toggleShowConnexionInfos] = useConnexionInfos();
    const { sendState } = useScreenCom();
    useEffect(() => {
        console.log("[useSystemActions] Rewrite effect");
        sendState("system_actions",
            {
                fullscreen: isFullscreen,
                connexionInfos: showConnexionInfos,
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFullscreen, showConnexionInfos])

    return { isFullscreen, toggleFullscreen, showConnexionInfos, toggleShowConnexionInfos };
}
