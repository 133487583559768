import { useState } from 'react';
import { useGamepadSession, useGamepadLS, useGamepadCom } from '../../../unboared-com';
import { InGameLayout } from '../../../react-unboared'

import styles from './UserAuthentification.module.scss'
import AvatarList from '../../../assets/json/avatar.json'

import { sampleFromList } from '../../../Tools';
import AvatarSelection from './AvatarSelection'

const BaseUserAuthentification = ({ username, avatar, submitTxt, labelTxt, onSubmitted, create = true }) => {
    const [localAvatar, setLocalAvatar] = useState(avatar)
    const [localUsername, setLocalUsername] = useState(username)
    const { updateProfile } = useGamepadCom();
    const { setUsername, setAvatar } = useGamepadSession();
    const { updateUsers, addUsers } = useGamepadLS();

    const handleChange = (event) => {
        setLocalUsername(event.target.value);
    }

    const handleSubmit = (event) => {
        console.log(`[System] Creation user \n- USERNAME(${localUsername})\n- AVATAR(${localAvatar})`);
        event.preventDefault();
        setUsername(localUsername);
        setAvatar(localAvatar);
        if (create) {
            addUsers({ "username": localUsername, "avatar": localAvatar });
        }
        else {
            updateUsers(username, { "username": localUsername, "avatar": localAvatar });
        }

        updateProfile(localUsername, localAvatar);

        if (onSubmitted) {
            onSubmitted();
        }
    }

    return (
        <InGameLayout className={styles.container} header={<h1 className={styles.header}>Profile Settings</h1>}>
            <form className={styles.connexionBody} onSubmit={handleSubmit}>
                <label>Click to change avatar</label>
                <AvatarSelection className={styles.avatarSelection} avatar={localAvatar} updateAvatar={setLocalAvatar} />
                <label>{labelTxt}</label>
                <input type="text" maxLength="12" value={localUsername} onChange={handleChange} required />
                <input className="validation-btn" type="submit" value={submitTxt} />
            </form>
        </InGameLayout>
    )
}


const UserAuthentification = ({ onSubmitted }) => {
    const randomAvatar = sampleFromList(AvatarList);
    return <BaseUserAuthentification username="" avatar={randomAvatar} submitTxt="Enter the game" labelTxt="What is your name ?"
        onSubmitted={onSubmitted} />
}

const UserAuthentificationModification = ({ onSubmitted }) => {
    const { username, avatar } = useGamepadSession();
    return <BaseUserAuthentification username={username} avatar={avatar} submitTxt="Save" labelTxt="Change username" create={false} onSubmitted={onSubmitted} />
}

export { UserAuthentification, UserAuthentificationModification };