import { useScreenSession } from ".";
import { useDefaultPrototocol } from "../";

const useScreenCom = () => {
    console.log("[useScreenCom] Update")

    const { com } = useDefaultPrototocol();
    const statusConnexion = useScreenSession((state) => state.statusConnexion);

    const changeScenario = (game, scenario, infos = {}) => {
        console.log(`[Communication] Change scenario game(${game}) scenario(${scenario})`);
        let data = {
            "roomID": statusConnexion.roomID,
            "game": game,
            "scenario": scenario,
            "infos": infos,
        }
        com.send("changeScenario", data);
    }

    const sendState = (name, data) => {
        console.log(`[Communication] Send state to room°${statusConnexion.roomID}\n--> ${name}`);
        let dataGlobal = {
            "roomID": statusConnexion.roomID,
            "state": name,
            "data": data,
        }
        com.send("sendState", dataGlobal);
    }

    const createRoom = () => {
        console.log(`[Communication] Create room`);
        com.send("create_room", {});
    }

    const deleteRoom = (roomID) => {
        console.log(`[Communication] Delete room ${roomID}`);
        com.send("leave_room", { "roomID": roomID });
    }

    return { createRoom, deleteRoom, sendState, changeScenario }
}


export default useScreenCom;