export const AVATAR_API = "https://avatars.dicebear.com/api/"
export const PUBLIC_ASSETS = "https://raw.githubusercontent.com/unboared/public-assets/main/";

export const GAME_ENDPOINT = "/game/";
export const ROOM_ENDPOINT = "/gamepad/";

export const getGameURL = (host, game) => {
    return host + GAME_ENDPOINT + game;
}

export const getRoomURL = (host, room) => {
    return host + ROOM_ENDPOINT + room;
}

export const RESTRICT_ACCESS = true;
export const ACCESS_TOKEN = "WeLoveGames";