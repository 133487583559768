import { animated } from "react-spring";
import styled from 'styled-components'

const StyledDetectiveCard = styled(animated.div)`    

.detective {
    border-radius:1rem;
    border: 0.5rem solid ${props => props.bordercolor};
    position:relative;
    width: 100%;
    height: 100%;
}

.cardLeft {
    position:absolute;
    top : 6%;
    left: 6%;
}

`;

const DetectiveCard = ({ className, active = false, color, src }) => {
    const bordercolor = (active ? (color ? color : "white") : "rgba(0,0,0,0)");
    return (
        <StyledDetectiveCard className={className} bordercolor={bordercolor}>
            <img className="detective show" src={src} alt="" />
        </StyledDetectiveCard>
    )
}

export default DetectiveCard;