import styled from 'styled-components';
import { useState } from 'react';

import { useGamepadCom, useListener } from "../../../unboared-com";
import { PALETTE_COLOR, InGameLayout, CenteredFlexBox, Header } from "../../../react-unboared";

import ProposalButton from "../components/buttons/ProposalButton";

const StyledContent = styled(CenteredFlexBox)`
    height:100%;
    .txt {
        font-size : 6rem;
    }
`
const A_code = 65;

const Gamepad = () => {
    const { emitAction } = useGamepadCom()
    const [vote, setVote] = useState(undefined);
    const [canVote, setCanVote] = useState(false)

    const sendVote = (i) => {
        if (vote === undefined && canVote) {
            setVote(i);
            emitAction('vote', { "vote": i });
        }
    }

    useListener('canVote', (value) => {
        if (value) {
            setVote(undefined);
        }
        setCanVote(value);
    });

    return (
        <InGameLayout header={<Header />}>
            <StyledContent>
                {
                    [0, 1, 2, 3].map((index) => (
                        // console.log();
                        <ProposalButton color={PALETTE_COLOR[index]} disable={(vote !== index) && ((vote !== undefined) || (!canVote))} onClick={() => sendVote(index)}>
                            <h2 className='txt'>{String.fromCharCode(A_code + index)}</h2>
                        </ProposalButton>
                    ))
                }
            </StyledContent>
        </InGameLayout>
    )
}

export default Gamepad;
