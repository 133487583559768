import { useState, useEffect } from 'react';
import useSound from 'use-sound';
import { useListener, useScreenSession, useScreenScenario } from '../../../../unboared-com';

import './game-jungle-speed.scss';
import ScoreBoard from '../../assets/img/JSpeed_Scoreboard.png';
import ThrowCardSound from '../../assets/sounds/ThrowCard.wav';
import ThrowDiscSound from '../../assets/sounds/ThrowDisc.ogg';

import EndGame from '../Endgame/Screen';
import { GAME_NAME } from '../../config/Const';
import UnboaredSpeed from '../../jsx/UnboaredSpeed';
import { JungleSound } from '../../jsx/JungleSound';
import { getUserPosition } from '../../../../Tools';

let game = null;

const Screen = (props) => {
    console.log("[Unboared Speed] REWRITE Game")

    const statusPlayers = useScreenSession((state) => state.statusPlayers);
    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);

    const [activePlayers, setActivePlayers] = useState([]);

    // Sounds
    const [playThrowCardSound] = useSound(ThrowCardSound);
    const [playThrowDiscSound] = useSound(ThrowDiscSound);

    const callAtTheEnd = (winnerName) => {
        setLocalScenario(GAME_NAME, "endgame", <EndGame winner={winnerName} />);
    }

    useEffect(() => {
        console.log("[Unboared Speed] LOAD Game")

        const canvas = document.getElementById("my-canvas")
        canvas.height = window.innerHeight
        canvas.width = 0.6 * window.innerWidth
        const ctx = canvas.getContext("2d")

        setActivePlayers(statusPlayers.players);
        game = new UnboaredSpeed(ctx).start(canvas, statusPlayers.players, 2, callAtTheEnd);

        return () => { console.log("[Unboared Speed] UNLOAD Game"); game.clear(); game = null; };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update Scenario to category selection
    useScreenScenario(GAME_NAME, "game");

    // Listen the players votes 
    useListener("throw_disc", (data) => {
        game.game.players[getUserPosition(activePlayers, data['player']['id'])].throwDisc();
    }, [activePlayers])

    useListener("throw_card", (data) => {
        game.game.players[getUserPosition(activePlayers, data['player']['id'])].throwCard();
    }, [activePlayers])



    return (
        <div className="jungle_speed__container">
            <JungleSound throwCardSound={playThrowCardSound} throwDiscSound={playThrowDiscSound} />
            <canvas id="my-canvas"></canvas>

            <div id="preBoard">
                <div className="score-table">
                    <img src={ScoreBoard} alt='' />
                    <div className='scores'>
                        <h3>{(activePlayers.length > 0 ? activePlayers[0].username : "Figue")} - <span id='counterCardsTable1'>0</span></h3>
                        <h3>{(activePlayers.length > 1 ? activePlayers[1].username : "Melon")} - <span id='counterCardsTable2'>0</span></h3>
                        <h3>{(activePlayers.length > 2 ? activePlayers[2].username : "Courgette")} - <span id='counterCardsTable3'>0</span></h3>
                        <h3>{(activePlayers.length > 3 ? activePlayers[3].username : "Framboise")} - <span id='counterCardsTable4'>0</span></h3>
                    </div>
                </div>
                <div id="board">
                    <div className="board-totem">
                        <div className='logo-totem-center'> </div>
                    </div>
                    <div id="board-1">
                        <h2 id='player1' style={{ backgroundColor: `${(activePlayers.length > 0 ? activePlayers[0].color : 'white')}` }} className='namePlayer'>{(activePlayers.length > 0 ? activePlayers[0].username : "Figue")}</h2>
                        <div id='cardCenter1'>
                            <h3 id='counterCards1'>0</h3>
                        </div>
                    </div>
                    <div id="board-2">
                        <h2 id='player2' style={{ backgroundColor: `${(activePlayers.length > 1 ? activePlayers[1].color : 'white')}` }} className='namePlayer'>{(activePlayers.length > 1 ? activePlayers[1].username : "Melon")}</h2>
                        <div id='cardCenter2'>
                            <h3 id='counterCards2'>0</h3>
                        </div>
                    </div>
                    <div id="board-3">
                        <h2 id='player3' style={{ backgroundColor: `${(activePlayers.length > 2 ? activePlayers[2].color : 'white')}` }} className='namePlayer'>{(activePlayers.length > 2 ? activePlayers[2].username : "Courgette")}</h2>
                        <div id='cardCenter3'>
                            <h3 id='counterCards3'>0</h3>
                        </div>
                    </div>
                    <div id="board-4">
                        <h2 id='player4' style={{ backgroundColor: `${(activePlayers.length > 3 ? activePlayers[3].color : 'white')}` }} className='namePlayer'>{(activePlayers.length > 3 ? activePlayers[3].username : "Framboise")}</h2>
                        <div id='cardCenter4'>
                            <h3 id='counterCards4'>0</h3>
                        </div>
                    </div>
                </div>

            </div>
            <div id="messageViewer"></div>
        </div>
    );
}

export default Screen;