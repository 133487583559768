import { useListener, useScreenSession, useScreenScenario } from '../../../../unboared-com';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { Header, UnboaredIcon, CenteredFlexBox, InGameNavLayout } from "../../../../react-unboared";
import { GAME_NAME } from '../../config/Const';
import SpecialColorCard from '../../assets/img/cards/SpecialColors.png';
import SpecialDiscsCard from '../../assets/img/cards/SpecialDiscs.png';
import UnboaredSpeedLogo from '../../assets/img/jungle_speed.png';
import NavButton from '../../components/NavButton';

const instruction = {
    fontSize: '1.5rem',
    fontStyle: 'italic',
    margin: '0.2rem',
}

const imgStyle = {
    width: '10rem',
}

const Screen = () => {
    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);

    let callbackHome = () => {
        setLocalScenario(GAME_NAME, "home");
    }

    useScreenScenario(GAME_NAME, "instructions");
    useListener("pressReturn", callbackHome);

    const nav = (
        <CenteredFlexBox>
            <NavButton
                key={"home"}
                title="Home"
                icon={<UnboaredIcon MaterialIcon={ArrowCircleLeftOutlinedIcon} size={4} />}
                onClick={callbackHome} />
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header src={UnboaredSpeedLogo} />} nav={nav}>
            <CenteredFlexBox style={{ width: '50vw', textAlign: 'left' }}>
                <h1>· INSTRUCTIONS ·</h1>
                <ul>
                    <li style={instruction}>✴️ Throw a card when it's your turn.</li>
                    <li style={instruction}>✴️ Duel: If you play a card that matches the symbol of another player's top card, be the first to  throw the disc. The loser of the duel takes both players' played cards.</li>
                    <li style={instruction}>✴️ If you make a mistake when throwing the disc, you must take all the cards currently in play.</li>
                    <div style={{ display: 'flex' }}>
                        <img style={imgStyle} src={SpecialColorCard} alt="Special Color Card" />
                        <div style={instruction}>
                            While this card is still exposed, duels are based on matching colors, and symbol duels do not apply.
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <img style={imgStyle} src={SpecialDiscsCard} alt="Special Disc Card" />
                        <div style={instruction}>
                            All players must throw the disc. The slowest player must take all the cards currently in play.
                        </div>
                    </div>
                </ul>
            </CenteredFlexBox>
        </InGameNavLayout>
    );
}

export default Screen;