import styled from 'styled-components';
import { Flex, device } from '../../../react-unboared'

export const StyledHeroSlideItem = styled.div`
.hero-slide__item {
    width: 100%;
    display: flex;
    flex-direction:row;
    height: 50vh;
    
    &__content {
        height: 100%;
        width: 35%;
        padding: 3rem 1rem 0rem 1rem ;

        @media ${device.tablet} {
            width: 55%;
        }
        &__info {
            height:100%;
            @media ${device.tablet} {
                width: 100%;
            }
            &>*~* {
                margin-top: 1.5rem;
            }
            .title {
                font-weight: 700;
                line-height: 1;
            }
            .overview {
                text-align:justify;
            }
            .features {
                font-weight: 700;
                display: flex;
            }
            .btns {
                margin-top: 4rem;
            }
            .btns,
            .title,
            .features,
            .overview {
                opacity: 0;
                transform: translateY(-100px);
                transition: transform 0.4s ease, opacity 0.4s ease;
            }
        }
        &__poster {
            flex: 1;
            // ${Flex({ alignItem: "center", justifyContent: "flex-start" })}
            position: relative;
            // height: 60vh;
            position: relative;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40%;
                height: 100%;
                background-image: linear-gradient(to right, ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR}, rgba(0,0,0,0));
            }
        }
    }
}    
`

export const StyledFeature = styled.div`
    width: 50%;
    .feature-icon {
        margin-right: 1rem;
        padding: 0.7rem;
    }
    .feature-text {
        display: inline-block;
        max-width: 70%;
        vertical-align: middle;
    }
`

export const StyledHeroSlide = styled.div`
    margin-bottom: 1rem;
    * {
        cursor: default;
        font-family: 'Montserrat';
    }
`