import React from "react";
import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";

import UnboaredScreen from "./UnboaredScreen";
import UnboaredGamepad from "./UnboaredGamepad";
import { ThemeProvider } from "styled-components";
import { UNBOARED_DARK_THEME/* , UNBOARED_LIGHT_THEME */ } from "./react-unboared";
import { GlobalFonts, GlobalStyles } from "./react-unboared";

const UnboaredApp = () => {
    console.log("[UnboaredApp] REWRITE App")

    return (
        <ThemeProvider theme={UNBOARED_DARK_THEME}>
            <GlobalFonts />
            <GlobalStyles />
            <BrowserRouter>
                <Switch>
                    <Route path="/gamepad">
                        <UnboaredGamepad />
                    </Route>
                    <Route path="/">
                        <UnboaredScreen />
                    </Route>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default UnboaredApp;