import socketIOClient from "socket.io-client";

export class SocketIO {
    constructor(server, port, protocol) {
        this.type = "SocketIO";
        this.wss = socketIOClient(SocketIO.getURL(protocol, server, port));
    }
    static getURL(protocol, server, port) {
        return `${protocol}://${server}:${port}`
    }
    on(name, callback) {
        this.wss.on(name, callback);
    }
    off(name, callback) {
        this.wss.off(name, callback);
    }
    send(name, data) {
        this.wss.emit(name, data);
    }
}