import styled from 'styled-components'
import { useState, useRef } from 'react';
import { useSpring, animated } from 'react-spring'
import { useGesture } from 'react-use-gesture';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { UserAuthentification } from './UserAuthentification';
import { useGamepadSession, useGamepadLS } from "../../../unboared-com";
import { UnboaredIcon, NamePlayerIcon, Header, InGameLayout, PRIMARY_COLOR } from "../../../react-unboared/";

const StyledButton = styled.button`
        width:12rem;
        height:12rem;
        margin:1rem;
`;

const StyledContainer = styled.div`
    display:flex;
    flex-wrap: wrap;
    padding:0 3rem;
    align-items: center;    
    justify-content: center;
`

const User = ({ username, avatar, onClick }) => {
    return (
        <StyledButton onClick={onClick} >
            <NamePlayerIcon username={username} avatar={avatar} />
        </StyledButton>
    );
}

const DragableItem = ({ binRef, id, ...props }) => {
    const removeUser = useGamepadLS(state => state.removeUser);
    const [{ x, y, scale }, api] = useSpring(() => ({ x: 0, y: 0, scale: 1 }))
    const iconRef = useRef();

    // Set the drag hook and define component movement based on gesture data
    const bind = useGesture({
        onDragEnd: () => {
            binRef.current.style.visibility = `visible`;
            
            let binBound = binRef.current.getBoundingClientRect(),
            iconBound = iconRef.current.getBoundingClientRect();
            let intersect = (iconBound.right > binBound.left && iconBound.left < binBound.right && iconBound.bottom > binBound.top && iconBound.top < binBound.bottom);
            console.log("iconRight (end) " + iconBound.right);
            console.log("binRight (end) " + binBound.right);
            binRef.current.style.visibility = `collapse`;
            intersect && removeUser(id);
        },
        onDrag: ({ down, movement: [mx, my] }) => {
            let binBound = binRef.current.getBoundingClientRect(),
                iconBound = iconRef.current.getBoundingClientRect();
            let intersect = (iconBound.right > binBound.left && iconBound.left < binBound.right && iconBound.bottom > binBound.top && iconBound.top < binBound.bottom);
            api.start({ x: down ? mx : 0, y: down ? my : 0, scale: down ? 1.1 : 1 });
            binRef.current.style.visibility = (down ? `visible` : 'collapse');
            binRef.current.style.color = (down && intersect ? `${PRIMARY_COLOR}` : 'unset');
            console.log("iconRight " + iconBound.right);
            console.log("binRight " + binBound.right);
        },
    })

    return <animated.div ref={iconRef} {...bind()} style={{ x, y, scale, touchAction: 'none' }}>
        {props.children}
    </animated.div>
}

const UserSelection = ({ onSelected, users }) => {
    const { setUsername, setAvatar } = useGamepadSession();
    const [isAdding, setIsAdding] = useState(false);

    const binRef = useRef();

    if (isAdding) {
        return <UserAuthentification onSubmitted={onSelected} />
    }

    let rank = 0;
    let user_display = [];
    for (const user of users) {
        const userItem = (
            <DragableItem key={rank} binRef={binRef} id={rank}>
                <User
                    username={user.username} avatar={user.avatar}
                    onClick={() => { setUsername(user.username); setAvatar(user.avatar); onSelected(); }}
                />
            </DragableItem>
        );
        user_display.push(userItem);
        rank += 1;
    }

    return (
        <InGameLayout header={<Header />}>
            <StyledContainer>
                {user_display}
                <StyledButton onClick={() => setIsAdding(true)}>
                    <UnboaredIcon MaterialIcon={AddIcon} size={10}></UnboaredIcon>
                </StyledButton>
                <StyledButton ref={binRef} style={{ visibility: 'collapse' }}>
                    <UnboaredIcon MaterialIcon={DeleteIcon} size={6}></UnboaredIcon>
                    <h2>Delete</h2>
                </StyledButton>
            </StyledContainer>
        </InGameLayout>
    )
}

export default UserSelection;