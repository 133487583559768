import styled from 'styled-components'
import BackgroundImage from '../../assets/img/CarteMot.png'
import { device } from '../../../../react-unboared';

const StyledWordCard = styled.div`
    
    img {
        border-radius:1rem;
        height:100%;
        width:100%;
        border: 1rem solid ${props => (props.active ? (props.color ? props.color : "white") : "rgba(0,0,0,0)")};
        
        @media ${device.laptop}{
            border: 0.6rem solid ${props => (props.active ? (props.color ? props.color : "white") : "rgba(0,0,0,0)")};
        }
    }
    .word {
        position:absolute;
        top:60%;
        left: 50%;
        color:black;
        font-size:2.5rem;
        transform: translate(-50%, 0%);
        backface-visibility: hidden;

        @media ${device.laptop}{
            font-size:1.2rem;
        }
    }

`
const WordCard = ({ word, active, color, ...props }) => {
    return (
        <StyledWordCard className={props.className} active={active} color={color}>
            <img src={BackgroundImage} alt={word}/>
            <h2 className='word'>{word.toUpperCase()}</h2>
        </StyledWordCard>
    );
}

export default WordCard;