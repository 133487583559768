// import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from "react-router-dom";

import { ACCESS_TOKEN } from '../../config/Const';
import { Header } from '../../react-unboared';
import useAccessToken from '../../store/useAccessToken';
import StyledAccessPage from './StyledAccessPage'

const AccessPage = ({ onConnect, ...props }) => {
    console.log("[AccessPage] Rewrite AccessPage")
    const [code, setCode] = useState("")
    const { setToken } = useAccessToken();
    const [error, setError] = useState(false)

    const handleChange = (event) => {
        setCode(event.target.value);
    }

    const handleSubmit = (event) => {
        console.log(`[System] Join room n°${code}`);
        event.preventDefault();
        if (code === ACCESS_TOKEN) {
            setToken(code);
            onConnect();
        }
        else {
            setError(true);
        }
    }
    return (
        <StyledAccessPage>
            <div className='elements'>
                <Header className='logo' />
                <h1>
                    Let's play !
                </h1>
                <p>With your phones and a computer (or TV), discover a new way to play with your loved ones with Unboared.
                </p>
                <h2>Your access code*</h2>
                <form onSubmit={handleSubmit}>
                    {(error ? <p className='error'>⚠️ Wrong access code.</p> : <></>)}
                    <input className='input' type="text" placeholder='Your access code' value={code} onChange={handleChange} required />
                    <input className={`validation-btn submitAccessCode submitLaunch`} type="submit" value="Launch" />
                    {/* * Join <UnboaredFamilyLink href="https://discord.gg/QsJhQRQeFE">@UnboaredFamily</UnboaredFamilyLink> to preview the app. */}
                </form>
                <p>
                    * You don't have the access code yet ? <Link to='/community' className='important'>Click here to get it !</Link>
                </p>
                {/* <button
                    onClick={() => {
                        logAnalyticsEvent('screen_discord_link');
                        window.open('https://discord.gg/QsJhQRQeFE')
                    }}
                    className='discordBtn'>
                    <CenteredFlexBox className='discordButtonContent'>
                        <img src={discordImg} alt='Discord' />
                        Serveur Discord
                    </CenteredFlexBox>
                </button> */}
            </div>
        </StyledAccessPage >
    )
}

export default AccessPage;