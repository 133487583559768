import styled from 'styled-components'

import { User, AdditionalPlayerIcon } from "../../../../react-unboared"

const ConnectedPlayersListStyle = styled.div`
text-align : left;
// display:flex;
// flex-wrap:wrap;
.user {
    margin: ${props => 0.1 * props.size}rem 0rem;
}
`

const ConnectedPlayersList = ({ players, masterID, maxDisplay = 1000, size = 6, ...props }) => {
    const playersToDisplay = players.slice(0, Math.min(players.length, maxDisplay));
    const numOtherPlayers = players.length - playersToDisplay.length;

    return (
        <ConnectedPlayersListStyle {...props} size={size}>
            {
                playersToDisplay.map((player, index) => {
                    return <User key={index} className='user' user={player} isMaster={masterID === player.id} size={size} />
                })
            }
            {numOtherPlayers > 0 ? <AdditionalPlayerIcon className='user' num={numOtherPlayers} size={size} /> : null}
        </ConnectedPlayersListStyle>
    );
}

export default ConnectedPlayersList;