import React, { useState } from 'react';
import useSound from 'use-sound';

import { useScreenScenario, useListener } from '../../unboared-com';

import HeroSlide from '../components/hero-slide/HeroSlide';
import GameList from '../components/games-collection/GameList';
import MoveSound from '../../react-unboared/assets/sounds/Move.mp3';

import { category, boardGameType } from '../../api/unboaredApi';
import unboaredApi from '../../api/unboaredApi';
import ValidateComponent from './ValidateComponent';

const useGames = () => {
    // const [gameItems, setGameItems] = useState([
    //     {
    //         "id": 1,
    //         "available": true,
    //         "title": "Title",
    //         "short": "short-title",
    //         "category": "Category",
    //         "duration": "Duration",
    //         "minPlayers": "Min Players",
    //         "maxPlayers": "Max Players",
    //         "overview": "A short description",
    //         "poster_path": unboaredLogo,
    //     }
    // ]);

    // useEffect(() => {
    //     const getGames = async () => {
    //         // const params = { page: 1 }
    //         try {
    //             const response = unboaredApi.getBoardGameList()//await tmdbApi.getGamesList(movieType.popular, {params});
    //             setGameItems(response);
    //         } catch {
    //             console.log('[Error] in Hero Slide');
    //         }
    //     }
    //     getGames();
    // }, []);

    return [unboaredApi.getBoardGameList()];
}

const GameSection = ({ title, gameItems, activeGame, callbackSetSelected }) => {
    return (
        <div className="section mb-2">
            <div className="section__header mb-2">
                <h2>{title}</h2>
                {/* <Link to="/boardgame">
                            <OutlineButton className="small">View more</OutlineButton>
                        </Link> */}
            </div>
            <GameList gameItems={gameItems} category={category.boardgame} type={boardGameType.popular} activeGame={activeGame} callbackSetSelected={callbackSetSelected} />
        </div>
    );
}

const HomeContent = ({ gameItems }) => {
    console.log('[UnboaredUI::HomeContent] Rewrite')

    const [selectedGame, setSelectedGame] = useState(0)

    const [playMoveSound] = useSound(MoveSound);

    useListener("left", () => {
        setSelectedGame((prev) => {
            playMoveSound();
            if (prev > 0) {
                return prev - 1;
            }
            return prev;
        })
    }, [playMoveSound])

    useListener("right", () => {
        setSelectedGame((prev) => {
            playMoveSound();
            if (prev + 1 < gameItems.length) {
                return prev + 1;
            }
            return prev;
        })
    }, [playMoveSound])


    return (
        <>
            <ValidateComponent currentGame={gameItems[selectedGame]} />
            <HeroSlide gameItems={gameItems} activeGame={selectedGame} />
            <div className="container-fluid">
                <GameSection title='Games' gameItems={gameItems} activeGame={selectedGame} callbackSetSelected={setSelectedGame} />
                {/* <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2>Top Rated Board Games</h2>
                        <Link to="/boardgame">
                            <OutlineButton className="small">View more</OutlineButton>
                        </Link>
                    </div>
                    <GameList category={category.boardgame} type={boardGameType.top_rated}/>
                </div>

                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2>Trending Video Games</h2>
                        <Link to="/videogame">
                            <OutlineButton className="small">View more</OutlineButton>
                        </Link>
                    </div>
                    <GameList category={category.videogame} type={videoGameType.popular}/>
                </div>

                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2>Top Rated Video Games</h2>
                        <Link to="/videogame">
                            <OutlineButton className="small">View more</OutlineButton>
                        </Link>
                    </div>
                    <GameList category={category.videogame} type={videoGameType.top_rated}/>
                </div> */}
            </div>
        </>
    );
}

const Home = () => {
    console.log('[UnboaredUI::Home] Rewrite')
    const [gameItems] = useGames()
    // const [selectedCategory, setSelectedCategory] = useState("all")

    useScreenScenario("catalog", "home");

    return <HomeContent gameItems={gameItems} />
}

export default Home;
