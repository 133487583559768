import { getAvatarPath } from '../../../Tools';
import { PLAYER_COLOR } from '../../settings/Color';
import useTheme from '../../hooks/useTheme';
import StyledPlayerIcon from './StyledPlayerIcon'

const NamePlayerIcon = ({avatar, username, id = 0, size = "10rem", showUsername = true }) => {
    const { theme } = useTheme();
    const color = PLAYER_COLOR[id];
    const sizeName = `${Math.max(2, 3 - username.length / 6)}rem`;
    return (
        <StyledPlayerIcon style={{ width: `${size}`, height: `${size}` }}>
            <img className="playerAvatar" src={getAvatarPath(avatar)} alt="Avatar" />
            {
                (showUsername) ?
                    <h2 className="playerName"
                        style={
                            (color) ?
                                {
                                    fontSize: sizeName,
                                    color: color,
                                    textShadow: `0px -0.3rem 4px ${theme.PRIMARY_TEXT_COLOR}, 0px -0.3rem 4px ${color}`,
                                }
                                : { fontSize: sizeName }}
                    >
                        {username}
                    </h2>
                    : null
            }
        </StyledPlayerIcon >
    )
}

export default NamePlayerIcon;