import { createGlobalStyle } from 'styled-components';

import OpenSans from './fonts/OpenSans/OpenSans-Regular.ttf';
import KomikaTitle from './fonts/KomikaTitle/KomikaTitle.ttf';
import Montserrat from './fonts/Montserrat/Montserrat-Regular.ttf';
import Eater from './fonts/Eater/Eater-Regular.ttf';
import Creepster from './fonts/Creepster/Creepster-Regular.ttf';
import Nosifer from './fonts/Nosifer/Nosifer-Regular.ttf';

const GlobalFonts = createGlobalStyle`
@font-face {
    font-family: 'Montserrat';
    src: url(${Montserrat}) format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    src: url(${OpenSans}) format('truetype');
}

@font-face {
    font-family: 'KomikaTitle';
    src: url(${KomikaTitle}) format('truetype');
}

@font-face {
    font-family: 'Eater';
    src: url(${Eater}) format('truetype');
}

@font-face {
    font-family: 'Creepster';
    src: url(${Creepster}) format('truetype');
}

@font-face {
    font-family: 'Nosifer';
    src: url(${Nosifer}) format('truetype');
}
`;

export default GlobalFonts;