import React from 'react';
import Sound from 'react-sound';
import useSoundManager from './useSoundManager';

const AmbianceSound = () => {
  const playStatus = useSoundManager((state) => state.playStatus);
  const currentSong = useSoundManager((state) => state.currentSong);
  const position = useSoundManager((state) => state.position);
  const setPosition = useSoundManager((state) => state.setPosition);
  const volume = useSoundManager((state) => state.volume);
  const playbackRate = useSoundManager((state) => state.playbackRate);
  const loop = useSoundManager((state) => state.loop);
  const onStop = useSoundManager((state) => state.onStop);

  return (
    <div>
      {currentSong && (
        <Sound
          url={currentSong}
          playStatus={playStatus}
          playFromPosition={position}
          volume={volume}
          playbackRate={playbackRate}
          loop={loop}
          onLoading={({ bytesLoaded, bytesTotal }) => console.log(`${bytesLoaded / bytesTotal * 100}% loaded`)}
          onLoad={() => console.log('[AmbianceSound] Loaded')}
          onPlaying={({ position }) => {/* console.log('Position', position) */ }}
          onPause={({ position }) => { console.log('Paused at ', position); setPosition(position) }}
          onResume={() => console.log('[AmbianceSound] Resumed')}
          onStop={() => console.log('[AmbianceSound] Stopped')}
          onFinishedPlaying={onStop}
        />
      )}
    </div>
  );
}

export default AmbianceSound;
