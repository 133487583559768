import React, { useEffect, useRef } from 'react';
import SwiperCore, { Navigation } from 'swiper'
import { SwiperSlide, Swiper } from 'swiper/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { device, useWindowDimensions } from '../../../react-unboared';

import GameCard from '../game-card/GameCard';

const StyledGameList = styled.div`
    .swiper-slide {
        // padding: 1rem 1rem;
        @media ${device.tablet} {
            width: 30%;
        }
        @media ${device.mobile} {
            width: 40%;
        }
    }
`

const SwiperBreakpoints = {
    // when window width is >= TABLET_BREAKPOINT
    1024: {
        slidesPerView: 7.5,
    },
    // when window width is >= MOBILE_BREAKPOINT
    600: {
        slidesPerView: 5.5,
    },
    0: {
        slidesPerView: 3.5,
    },
}

const GameList = ({gameItems, activeGame, ...props}) => {
    console.log('[UnboaredUI::GameList] Rewrite')

    SwiperCore.use([Navigation]);

    const items = gameItems;
    // const [items, setItems] = useState([]);
    const swiperRef = useRef(null)
    const { width } = useWindowDimensions();
    
    // useEffect(() => {
    //     const getList = async () => {
    //         let response = null;
    //         const params = {};

    //         if (props.type !== 'similar') {
    //             switch (props.category) {
    //                 case category.boardgame:
    //                     response = unboaredApi.getBoardGameList(props.type, { params });//await tmdbApi.getGamesList(props.type, {params});
    //                     setItems(response);
    //                     break;
    //                 default:
    //                     response = unboaredApi.getVideoGameList(props.type, { params }); //await tmdbApi.getTvList(props.type, { params });
    //                     setItems(response);
    //                 // setItems(response.results);
    //             }
    //         } else {
    //             console.log("ERROR : SIMILAR FONCTIONNALITY DOES NOT EXISTS")
    //             // response = await unboaredApi.similar(props.category, props.id);
    //             // setItems(response.results);
    //         }
    //     };
    //     getList();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);


    useEffect(() => {
        if (swiperRef) {
            if (width > 1024) {
                if (activeGame > 3) {
                    swiperRef.current.swiper.slideTo(activeGame - 3);
                }
                else {
                    swiperRef.current.swiper.slideTo(0);
                }
            }
            else if (width > 600) {
                if (activeGame > 2) {
                    swiperRef.current.swiper.slideTo(activeGame - 2);
                }
                else {
                    swiperRef.current.swiper.slideTo(0);
                }
            }
            else {
                if (activeGame > 1) {
                    swiperRef.current.swiper.slideTo(activeGame - 1);
                }
                else {
                    swiperRef.current.swiper.slideTo(0);
                }
            }
        }
    }, [width, swiperRef, activeGame])

    return (
        <StyledGameList>
            <Swiper
                ref={swiperRef}
                modules={[Navigation]}
                grabCursor={true}
                spaceBetween={0}
                breakpoints={SwiperBreakpoints}
                navigation
            >
                {
                    items.map((item, i) => (
                        <SwiperSlide key={i}>
                            <GameCard gameID={i} isSelected={activeGame === i} item={item} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </StyledGameList>
    );
}

GameList.propTypes = {
    category: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
}

export default GameList;
