// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyCMiw0ST6sRUzcGCjltGE_q36gcxl6XF5k",

  authDomain: "unboared.firebaseapp.com",

  projectId: "unboared",

  storageBucket: "unboared.appspot.com",

  messagingSenderId: "803182974472",

  appId: "1:803182974472:web:8a1f208c65da07792763bd",

  measurementId: "G-82N796KB49"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

export default analytics;