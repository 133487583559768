
import { useGamepadCom } from "../../../../unboared-com";
import { InGameNavLayout, UnboaredIcon, NavButtonGamepad, Header, CenteredFlexBox } from "../../../../react-unboared";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

import LogoCodenames from '../../assets/img/Logo_Codenames_White.png';

const Gamepad = () => {
    const { emitAction } = useGamepadCom();

    const nav = (
        <CenteredFlexBox>
             <NavButtonGamepad
                key={"games"}
                title="Back to games"
                icon={<UnboaredIcon MaterialIcon={VideogameAssetIcon} size={6} />}
                onClick={() => emitAction("pressUnboared")} />
            <NavButtonGamepad
                key={"restart"}
                title="Restart"
                icon={<UnboaredIcon MaterialIcon={RestartAltIcon} size={6} />}
                onClick={() => emitAction("pressHome")}
            />
            {/* <NavButtonGamepad
                key={"continue"}
                title="Continue"
                icon={<UnboaredIcon MaterialIcon={ArrowRightIcon} size={4} />}
                onClick={callbackContinue}
            /> */}
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header src={LogoCodenames} />} nav={nav}>
        </InGameNavLayout>
    )
}

export default Gamepad;
