
import { useState } from 'react';
import { UnboaredLoader, useWindowOrientation, ChangeOrientation } from '../../../../react-unboared';
import { useGamepadSession, useListener, useGamepadCom } from '../../../../unboared-com';
import CardSelection from './CardSelectionGamepad';
import WaitWindows from './WaitWindows';
import Hint from './Hint';

const getHinter = (team, hinterID) => {
    for (let p of team.players) {
        if (p.id === hinterID) {
            return p;
        }
    }
}

const Gamepad = () => {
    const windowOrientation = useWindowOrientation();

    const id = useGamepadSession(state => state.id);
    const { emitAction } = useGamepadCom()
    const [grid, setGrid] = useState(undefined);
    const [gameState, setGameState] = useState(undefined);
    const [teamsInfos, setTeamsInfos] = useState(undefined);
    useListener('teamsInfos', (data) => { setTeamsInfos(data); });
    useListener('gameState', (data) => { setGameState(data); });
    useListener('grid', (data) => { setGrid(data); });
    
    if (!teamsInfos || !gameState || !grid) {
        emitAction("onReady");
        return <UnboaredLoader/>;
    }

    if (windowOrientation === 'LANDSCAPE') {
        return <ChangeOrientation to='PORTRAIT' />
    }    

    let myTeam, opponentTeam;
    if (teamsInfos.team1.players.map((player) => player.id).includes(id)) {
        myTeam = teamsInfos.team1;
        opponentTeam = teamsInfos.team2;
    }
    else {
        myTeam = teamsInfos.team2;
        opponentTeam = teamsInfos.team1;
    }

    const myHinter = getHinter(myTeam, myTeam.hinterID);

    if (gameState.currentTeam === myTeam.name) {
        if (myTeam.hinterID === id) {
            return <Hint grid={grid} team={myTeam} showInputs={((gameState.currentTeam === myTeam.name) && (gameState.choiceLeft === 0))} />;
        }
        else {
            return (gameState.choiceLeft > 0) ? <CardSelection /> : <WaitWindows txt={`Wait for ${myHinter.username} to give you an clue.`} />;
        }
    }
    else {
        if (myTeam.hinterID === id) {
            return <Hint grid={grid} team={myTeam} showInputs={false} />;
        }
        else {
            return <WaitWindows txt={`Wait for the ${opponentTeam.name} team the  to play.`} />;
        }
    }
}

export default Gamepad;

