import { AVATAR_API } from "./config/Const";

export const sampleFromList = (list) => {
    return list[Math.floor(Math.random() * list.length)]
}

export const getAvatarPath = (avatarName) => {
    return `${AVATAR_API}${avatarName}`;
}

/* MANAGING FULLSCREEN */
export const openFullscreen = (elem) => {
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
    }
}

export const closeFullscreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
    }
}

export const isFullscreen = () => {
    return (document.fullscreenElement !== null);
}

export const toggleFullscreen = (elem = document.documentElement) => {
    if (!isFullscreen()) {
        openFullscreen(elem);
    } else {
        closeFullscreen();
    }
}

/* MANAGING SOUND */
// Mute a singular HTML5 element
export const mute = (elem) => {
    elem.muted = true;
    // elem.pause();
}

export const unmute = (elem) => {
    elem.muted = false;
    // elem.pause();
}
export const isMuted = (elem) => {
    return elem.muted;
}

// Try to mute all video and audio elements on the page
export const muteSystem = () => {
    document.querySelectorAll("video, audio").forEach(elem => mute(elem));
}

export const unmuteSystem = () => {
    document.querySelectorAll("video, audio").forEach(elem => unmute(elem));
}
export const isMutedSystem = () => {
    let isMuted = true;
    let audios = document.querySelectorAll("video, audio")
    for (let i = 0; i < audios.length; i++) {
        if (!isMuted(audios[i])) {
            return false
        }
    }
    return true;
}

export const toggleMuteSystem = () => {
    if (!isMutedSystem()) {
        muteSystem();
    } else {
        unmuteSystem();
    }
}


export const getUserFromID = (userLists, id) => {
    let masterPlayer = undefined;
    if (userLists.length > 0) {
        masterPlayer = userLists.reduce((prev, current) => (current.id === id ? current : prev));
    }
    return masterPlayer;
}

export const getUserPosition = (userLists, id) => {
    for (let i = 0; i < userLists.length; i++) {
        if (userLists[i].id === id) {
            return i;
        }
    }
    return undefined;
}