import { InGameLayout, Header } from "../../../../../react-unboared";

const WaitWindows = ({txt}) => {

    return (
        <InGameLayout header={<Header />}>
            <h1>{txt}</h1>
        </InGameLayout>
    )
}

export default WaitWindows;

