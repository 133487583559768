import { useListener, useScreenSession, useScreenScenario } from '../../../unboared-com';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { NavButtonGamepad, Header, UnboaredIcon, CenteredFlexBox, InGameNavLayout } from "../../../react-unboared";

import menu_logo from '../assets/img/unblind-test.png'
import { GAME_NAME } from '../config/Const';


const instruction = {
    fontSize: '1.5rem',
    fontStyle: 'italic',
    margin: '0.2rem',
}

const Screen = () => {
    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);

    let callbackHome = () => {
        setLocalScenario(GAME_NAME, "home");
    }

    useScreenScenario(GAME_NAME, "instructions");
    useListener("pressReturn", callbackHome);

    const nav = (
        <CenteredFlexBox>
            <NavButtonGamepad
                key={"home"}
                title="Home"
                icon={<UnboaredIcon MaterialIcon={ArrowCircleLeftOutlinedIcon} size={6} />}
                onClick={callbackHome} />
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header src={menu_logo} />} nav={nav}>
            <CenteredFlexBox>
                <h1>· INSTRUCTIONS ·</h1>
                <ul>
                    <li style={instruction}>✴️ Find the artist and the title of the music.</li>
                    <li style={instruction}>✴️ You get one point by artist / title correctly found.</li>
                    <li style={instruction}>✴️ You can select the category you like.</li>
                    <li style={instruction}>✴️ Find the artist and the title of the music.</li>
                    <li style={instruction}><h3>✴️ If you have the most points . . . YOU WIN !</h3></li>
                </ul>
            </CenteredFlexBox>
        </InGameNavLayout>
    );
}

export default Screen;