import HomeGamepad from "../scenarios/Home/Gamepad"
import HomeScreen from "../scenarios/Home/Screen"
import InstructionsGamepad from "../scenarios/Instructions/Gamepad"
import InstructionsScreen from "../scenarios/Instructions/Screen"
import GameGamepad from "../scenarios/Game/Gamepad"
import GameScreen from "../scenarios/Game/Screen"
import EndGame from "../scenarios/Endgame/Endgame"
import EndGamepad from "../scenarios/Endgame/Gamepad"

const Scenario = {
    "home": {
        gamepad: <HomeGamepad />,
        screen: <HomeScreen />,
    },
    "game": {
        gamepad: <GameGamepad />,
        screen: <GameScreen />,
    },
    "instructions": {
        gamepad: <InstructionsGamepad />,
        screen: <InstructionsScreen />,
    },
    "endgame": {
        gamepad: <EndGamepad />,
        screen: <EndGame />,
    }
}

export default Scenario;