import HomeGamepad from "../scenarios/Home/Gamepad"
import HomeScreen from "../scenarios/Home/Screen"
import InstructionsGamepad from "../scenarios/Instructions/Gamepad"
import InstructionsScreen from "../scenarios/Instructions/Screen"

const Scenario = {
    "home": {
        gamepad: <HomeGamepad />,
        screen: <HomeScreen />,
    },
    "instructions": {
        gamepad: <InstructionsGamepad />,
        screen: <InstructionsScreen />,
    }
}

export default Scenario;