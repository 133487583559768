import ScreenJungleSpeed from "../games/jungle-speed/ScreenApp";
import ScreenUnblindTest from "../games/blind-test/ScreenApp";
import ScreenUntemplate from "../games/untemplate/ScreenApp";
import ScreenCodenames from "../games/codenames/ScreenApp";
import IframeGame from "../screen/game-engine/IframeGame";

const GameComponent = {
    "untemplate": <ScreenUntemplate/>,
    "codenames": <ScreenCodenames/>,
    "unblind-test": <ScreenUnblindTest/>,
    "jungle-speed": <ScreenJungleSpeed/>,
    "bomber-kitten": <IframeGame title='bomber-kitten' src={/* 'https://unboared-bomber-kitten--pr3-develop-403mx4fv.web.app' *//* 'http://127.0.0.1:8000' */'https://unboared-bomber-kitten.web.app'}/>,
}

export default GameComponent;