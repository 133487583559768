
import { useGamepadCom } from "../../../../unboared-com";
import { InGameNavLayout, UnboaredIcon,  Header, CenteredFlexBox } from "../../../../react-unboared";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

import UnboaredSpeedLogo from '../../assets/img/jungle_speed.png';
import NavButton from "../../components/NavButton";

const Gamepad = () => {
    const { emitAction } = useGamepadCom();

    const nav = (
        <CenteredFlexBox>
            <NavButton
                key={"backtogames"}
                title="Back to games"
                icon={<UnboaredIcon MaterialIcon={VideogameAssetIcon} size={6} />}
                onClick={() => emitAction("pressUnboared")} />
            <NavButton
                key={"restart"}
                title="Restart"
                icon={<UnboaredIcon MaterialIcon={RestartAltIcon} size={6} />}
                onClick={() => emitAction("pressMenu")} />
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header src={UnboaredSpeedLogo} />} nav={nav}>
        </InGameNavLayout>
    )
}

export default Gamepad;
