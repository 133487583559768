import { useGamepadSession } from "../../unboared-com";
import WaitingScreen from "./components/WaitingScreen/WaitingScreen";

import Scenario from "./config/Scenario";

const GamepadApp = () => {
    const { scenario, id, statusPlayers } = useGamepadSession();
    if (id === statusPlayers.masterID) {
        return (Scenario[scenario].gamepad)
    }
    else {
        return <WaitingScreen />
    }
}

export default GamepadApp;
