import useSocketIO from './socketIO';

export const useDefaultPrototocol = useSocketIO;

/* Export protocols config */
export * from './Config';

/* Export gamepad tools */
export * from './gamepad';

/* Export screen tools */
export * from './screen';

/* Export communication protocols */
export * from './socketIO';

/* Export hooks */
export { default as useListener } from "./useListener"