import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useGamepadCom } from "../../../unboared-com";

import CenteredFlexBox from '../../../react-unboared/components/responsive/CenteredFlexBox'
import Header from "../../../react-unboared/components/header/Header";
import NavButtonGamepad from "../../../react-unboared/components/button/NavButtonGamepad";
import UnboaredIcon from "../../../react-unboared/components/icon/UnboaredIcon";
import { InGameNavLayout } from "../../../react-unboared/components/layouts/gamepad/GamepadPortraitLayout";

const Gamepad = () => {
    const { emitAction } = useGamepadCom();

    const nav = (
        <CenteredFlexBox>
            <NavButtonGamepad
                title="Instructions"
                icon={<UnboaredIcon MaterialIcon={ArrowCircleLeftOutlinedIcon} size={6} />}
                onClick={() => emitAction("pressReturn")}
            />
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header />} nav={nav}>
        </InGameNavLayout>
    )
}

export default Gamepad;



