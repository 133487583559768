import { useState } from 'react';
import styled from 'styled-components';
import { Flex, ErrorMessage } from '../../../../../react-unboared';
import { DATA } from '../../../config/Const';
import { count } from "../../../config/Tools";
import CN_Card_Red from '../../../assets/img/CN_Card_Red.png';
import CN_Card_Blue from '../../../assets/img/CN_Card_Blue.png';

import SquareBlack from '../../../assets/img/SquareBlack.png';
import SquareBlue from '../../../assets/img/SquareBlue.png';
import SquareWhite from '../../../assets/img/SquareWhite.png';
import SquareRed from '../../../assets/img/SquareRed.png';
import { useGamepadCom } from '../../../../../unboared-com';

const StyledGridCard = styled.div`
position:relative;
// background-color:yellow;
    .card {
        width: 40vh;
    }
    .grid {
        position : absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width: 30vh;
        .square {
            margin: 0 1%;
            width : 16%;
        }
    }
    
`
const SquareIcon = {
    'blue': SquareBlue,
    'red': SquareRed,
    'black': SquareBlack,
    'white': SquareWhite,
}

const GridCard = ({ grid }) => {
    const CN_Card_Img = (count(grid, 'blue') > count(grid, 'red') ? CN_Card_Blue : CN_Card_Red);

    return (
        <StyledGridCard>
            <img className='card' src={CN_Card_Img} alt="" />
            <div className='grid'>
                {grid.map((color, index) => <img className='square' key={index} src={SquareIcon[color]} alt={color} />)}
            </div>
        </StyledGridCard>
    );
}

const StyledHint = styled.div`
    ${Flex({ alignItem: "center", justifyContent: "center" })}
    flex-direction:column;
    height: 96vh;
    margin:0 3vh;
    text-align:center;
    .header {
        height:15vh;
        // background-color:yellow;
        margin:0 3vh;
        .players {
            font-size:2rem;
        }
    }
    .infos {
        // background-color:blue;
        height:55vh;
        h2 {
            font-size:2.5rem;
            margin:0 2%;
            padding: 2rem 2.5rem;
        }
    }

    .buttons {
        // background-color:red;
        margin:0 2vh;
        height:20vh;
        ${Flex({ alignItem: "center", justifyContent: "center" })}
        flex-wrap:wrap;
        button {
            margin:0.5vh;
            font-size:2.5vh;
            font-weight:600;
            min-width:6vh;
            background-color : #919dbf60
        }
        .active {
            background-color : ${props => props.color};
        }
        .submit {
            font-weight:400;
            font-size:1.5vh;
            width:100%;
        }
    }
`


const Hint = ({ grid, team, showInputs }) => {
    const [active, setActive] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState("");
    const teamInfos = DATA[team.name];
    const { emitAction } = useGamepadCom();

    const submitHint = () => {
        if (active) {
            emitAction('submitHint', { 'word': '', 'number': active });
            setActive(undefined);
        }
        else {
            setErrorMessage("You must select a number.")
        }
    }

    return (
        <StyledHint color={teamInfos.color}>
            <div className='header'>
                <h1 className='teamName'>{teamInfos.name}</h1>
                <div className='players'>
                    {team.players.map((player, index) =>
                        <p key={index}>{player.username}</p>
                    )}
                </div>
            </div>
            <div className='infos'>
                {errorMessage ? <ErrorMessage txt={errorMessage} /> : null}
                <h2>Give your clue and then indicate the number of cards to find.</h2>
                <GridCard grid={grid} />
            </div>
            <div className='buttons'>
                {showInputs ?
                    <>
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((n) => <button className={(active === n ? 'active' : '')} onClick={() => setActive(n)}>{n}</button>)}
                        < button className='submit' onClick={submitHint}>Submit ... </button>
                    </>
                    : null}
            </div>
        </StyledHint >
    );
}

export default Hint;