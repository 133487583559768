import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import SettingsIcon from '@mui/icons-material/Settings'
import { useGamepadCom } from "../../../../unboared-com";
import { Header, UnboaredIcon } from "../../../../react-unboared";
import { NavButtonGamepad, InGameNavLayout, CenteredFlexBox } from "../../../../react-unboared";
import LogoCodenames from '../../assets/img/Logo_Codenames_White.png';

const Gamepad = () => {
    const { emitAction } = useGamepadCom();

    const nav_items = [
        <NavButtonGamepad
            key={"instructions"}
            title="Instructions"
            icon={<UnboaredIcon MaterialIcon={HelpOutlineIcon} size={6} />}
            onClick={() => emitAction("pressInstructions")} />,
        <NavButtonGamepad
            key={"play"}
            title="Play"
            icon={<UnboaredIcon MaterialIcon={PlayCircleOutlineIcon} size={6} />}
            onClick={() => emitAction("pressPlay")}
        />,
        // <NavButtonGamepad
        //     key={"settings"}
        //     title="Settings"
        //     icon={<UnboaredIcon MaterialIcon={SettingsIcon} size={3} />}
        //     onClick={() => emitAction("pressSettings")}
        // />
    ];

    const nav = (
        <CenteredFlexBox>
            {nav_items}
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header src={LogoCodenames}/>} nav={nav}>
        </InGameNavLayout>
    )
}

export default Gamepad;

