import { useRef, useEffect } from "react";
import { useListener, useScreenSession } from "../../unboared-com";

const IframeGame = ({ title, src }) => {

    const statusPlayers = useScreenSession(state => state.statusPlayers);
    const iframeRef = useRef(null);

    const sendMessage = (message) => {
        if (!iframeRef.current) return;
        iframeRef.current.contentWindow.postMessage(message, src);
    };

    useEffect(() => {
        const handler = (e) => {
            if (e.origin !== src) return;
            if (e.data.action === "ready") {
                console.log("HANDLE READY");
                sendMessage({ action:"ready", nplayers: statusPlayers.players.length, statusPlayers:statusPlayers })
            }
        }

        window.addEventListener("message", handler);

        return () => window.removeEventListener("message", handler)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    useListener("message", (data) => {
        sendMessage(data);
    }, [sendMessage]);

    return <iframe ref={iframeRef} title={title} src={src} height={'99%'} width={'100%'} frameBorder="0" />;
}

export default IframeGame;