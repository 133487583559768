import styled from 'styled-components'
import { Username, PlayerIcon } from '..'

const StyledUser = styled.div`
* {
    vertical-align: middle;
}

.icon {
    margin-right : ${(props) => `${0.3 * props.size}`}rem;
    position:relative;
    display:inline-block;
}
.username {
    display:inline-block;
    font-size : ${(props) => `${0.3 * props.size}`}rem};
}
`

const User = ({ user, isMaster, size = 6, ...props }) => {
    return (
        <StyledUser {...props} size={size}>
            <PlayerIcon className='icon' avatar={user.avatar} username={user.username} id={user.id} size={`${size}rem`} />
            <Username className='username' username={user.username} isMaster={isMaster} />
        </StyledUser>
    )
}

export default User;