import { createGlobalStyle } from "styled-components";
import { BackgroundCover } from "../../react-unboared";
import Background from "./assets/img/MainScreen_GameBG.jpg";

const UnboaredSpeedGlobalStyles = createGlobalStyle`
    
    .gamepad, .screen {
        font-family : 'KomikaTitle';
        color: #000000;
        &::after {
            ${BackgroundCover(Background)}
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1;  
        }  
    }
`;

const UnboaredSpeedStyles = (props) => {
    return (
        <>
            <UnboaredSpeedGlobalStyles />
            {props.children}
        </>
    );
}

export default UnboaredSpeedStyles;