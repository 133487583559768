import DetectiveBlue from '../assets/img/LQ/DetectiveBlue.png'
import DetectiveRed from '../assets/img/LQ/DetectiveRed.png'
import DetectiveWhite from '../assets/img/LQ/DetectiveWhite.png'
import DetectiveBlack from '../assets/img/LQ/DetectiveBlack.png'

/* METADATA */
export const GAME_NAME = "codenames";

/* FUNCTIONAL CONFIGS */

/* UI CONFIGS */
export const BORDER_RADIUS = "2rem";

export const COLOR = {
    BLACK: "#0b0b0b",
    WHITE: "#c0b19e",
    RED: "#d87675",
    BLUE: "#47a1d7",
}

export const TEAM = {
    BLUE: {
        name: "Blue Team",
        color: COLOR.BLUE,
        card: DetectiveBlue,
    },
    RED: {
        name: "Red Team",
        color: COLOR.RED,
        card: DetectiveRed,
    },
}

export { DetectiveBlue, DetectiveRed, DetectiveWhite, DetectiveBlack }

export const DATA = {
    "blue": {
        ...TEAM.BLUE,
    },
    "red": {
        ...TEAM.RED,
    },
    "black": {
        color: COLOR.BLACK,
        card: DetectiveBlack,
    },
    "white": {
        color: COLOR.WHITE,
        card: DetectiveWhite,
    }
}