import { useGamepadSession } from '../../../unboared-com';
import { PLAYER_COLOR, PRIMARY_COLOR } from '../../../react-unboared';

import styles from './NavWidget.module.scss'
import UnboaredIcon from '../../../react-unboared/components/icon/UnboaredIcon';

const NavWidget = (props) => {
    const { id } = useGamepadSession();
    const colorStyle = {
        backgroundColor : ((id > 0 && id < PLAYER_COLOR.length) ? `${PLAYER_COLOR[id]}` : `${PRIMARY_COLOR}`)
    }
    return (
        <div className={styles.container} style={colorStyle} onClick={props.onClick}>
            <div className={styles.content}>
                <UnboaredIcon className={styles.icon} MaterialIcon={props.MaterialIcon} size={7}/>
            </div>
        </div>
    )
}
export default NavWidget;
