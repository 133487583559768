import PauseIcon from '@mui/icons-material/Pause';
import { useState } from "react";

import NavWidget from "../components/nav/NavWidget";
import PauseMenu from "./PauseMenu";

const PauseMenuIcon = () => {
    const [ showOptions, setShowOptions ] = useState(false);

    const toggleOptions = () => {
        setShowOptions((prev) => !prev)
    }

    return <>
        {showOptions ? <PauseMenu onClose={toggleOptions} /> : null}
        <NavWidget MaterialIcon={PauseIcon} onClick={toggleOptions} />
    </>
}
export default PauseMenuIcon;
