import styled from 'styled-components';
import { PRIMARY_COLOR, device } from '../../react-unboared';

const StyledConnexionSection = styled.div`
    font-size: 1.3rem;
    font-weight: 1000;
    width: 70vw;
    height: 80vh;
    
    /* Enable scroll if needed */
    background-color: ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
    
    /* Fallback color */
    padding: 2rem;

    h1,h2,h3,
    p {
        margin: 1rem;
        text-align: center;
    }

    .hint {
        color: ${PRIMARY_COLOR};
        font-size: 1.5rem;
    }

    .connexion-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:1rem;
        & > * {
            content-align:center;
            margin: 0.8rem;
        }
    }
    .connexion-method {
        float: left;
        width: 60%;
        border-right: 2px solid;
        @media ${device.tablet} {
            float: none;
            width: 100%;
            border-right: 0px solid;
            border-bottom: 2px solid;
        }

        & > p {
            font-size : 2rem;
        }
    }
    .connexion-player {
        @include tablet {
            width: 100%;
            margin-top: 2rem;
            margin-left: 0%;
        }
    }
    
`

export const RelativeView = styled.div`
    position: relative;
`

export const StyledClose = styled.div`
    position: absolute;
    text-align:center;
    top: 2rem;
    right: 2rem;
    * {
        color: ${PRIMARY_COLOR};
        font-size: 4rem;
        font-weight: bold;
    }

    &:hover,
    &:focus {
        /* Fallback color */
        // text-decoration: none;
        cursor: pointer;
        opacity:0.5;
    }
    .text {
        position:relative;
        font-size: 1.2rem;
        font-weight:1000;
    }
`

export default StyledConnexionSection;