import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useGamepadCom } from "../../../../unboared-com";
import { UnboaredIcon, Header, CenteredFlexBox, InGameNavLayout } from "../../../../react-unboared";
import UnboaredSpeedLogo from '../../assets/img/jungle_speed.png';
import NavButton from '../../components/NavButton';

const Gamepad = () => {
    const { emitAction } = useGamepadCom();

    const nav_items = [
        <NavButton
            title="Instructions"
            icon={<UnboaredIcon MaterialIcon={ArrowCircleLeftOutlinedIcon} size={6} />}
            onClick={() => emitAction("pressReturn")} />
    ];

    const nav = (
        <CenteredFlexBox>
            {nav_items}
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header src={UnboaredSpeedLogo} />} nav={nav}>
        </InGameNavLayout>
    )
}

export default Gamepad;



