
import { useEffect } from "react";
export var jungleSounds = {};

export const JungleSound = ({ throwCardSound, throwDiscSound }) => {

    useEffect(() => {
        jungleSounds['throw_card'] = throwCardSound;
        jungleSounds['throw_disc'] = throwDiscSound;

        return () => { jungleSounds = {} };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [throwCardSound, throwDiscSound]);

    return <></>

}