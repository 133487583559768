import { useListener, useScreenSession, useScreenScenario } from '../../../../unboared-com';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import { GAME_NAME } from '../../config/Const';
import { NavButtonGamepad, Header, UnboaredIcon, CenteredFlexBox, InGameLayout } from "../../../../react-unboared";

const Screen = () => {
    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);

    const callbackInstruction = () => {
        setLocalScenario(GAME_NAME, "instructions");
    }

    const callbackGame = () => {
        setLocalScenario(GAME_NAME, "game");
    }

    const callbackSettings = () => {
        setLocalScenario(GAME_NAME, "settings");
    }


    // Update Scenario to category selection
    useScreenScenario(GAME_NAME, "home");

    // Listen press instruction menu button 
    useListener("pressInstructions", callbackInstruction);

    // Listen press play menu button 
    useListener("pressPlay", callbackGame);

    // Listen press play menu button 
    useListener("pressSettings", callbackSettings);

    const nav = (
        <CenteredFlexBox>
            <NavButtonGamepad
                key={"instructions"}
                title="Instructions"
                icon={<UnboaredIcon MaterialIcon={HelpOutlineIcon} size={4} />}
                onClick={callbackInstruction} />
            <NavButtonGamepad
                key={"play"}
                title="Play"
                icon={<UnboaredIcon MaterialIcon={PlayCircleOutlineIcon} size={4} />}
                onClick={callbackGame}
            />
            <NavButtonGamepad
                key={"settings"}
                title="Settings"
                icon={<UnboaredIcon MaterialIcon={SettingsIcon} size={4} />}
                onClick={callbackSettings}
            />
        </CenteredFlexBox>
    );
    return (
        <InGameLayout header={<Header />}>
            {nav}
        </InGameLayout>
    );
}


export default Screen;