import { useState, useEffect } from 'react';

function getWindowOrientation() {
    let orientation = 'UNKNOWN'
    if (window.matchMedia("(orientation: portrait)").matches) {
        orientation = 'PORTRAIT';
    }
    if (window.matchMedia("(orientation: landscape)").matches) {
        orientation = 'LANDSCAPE';
    }
    return orientation;

}

export default function useWindowOrientation() {
    const [windowOrientation, setWindowOrientation] = useState(getWindowOrientation());
    useEffect(() => {
        function handleOrientationChange() {
            if (windowOrientation === 'PORTRAIT') {
                setWindowOrientation('LANDSCAPE');
            }
            else if (windowOrientation === 'LANDSCAPE') {
                setWindowOrientation('PORTRAIT');
            }
        }
        window.addEventListener("orientationchange", handleOrientationChange);
        return () => window.removeEventListener('orientationchange', handleOrientationChange);
    }, [windowOrientation]);

    return windowOrientation;
}
