class Message {
    constructor(text, time, type, player) {
        this.text = text
        this.time = time
        this.type = type
        this.player = player
        this.timeouts = []
    }

    showMessage() {
        let prevMessage = document.getElementById('popMessage')
        if (prevMessage) {
            this.callLater(() => { this.showMessage() }, 500)
        } else {
            this.addMessage()
            this.callLater(() => { this.deleteMessage() }, this.time)
        }
    }

    addMessage() {
        let messageViewer = document.getElementById('messageViewer')
        let popMessage = document.createElement('div')
        popMessage.setAttribute('id', 'popMessage')
        messageViewer.appendChild(popMessage)
        if (this.type === 'winner') {
            let img = document.createElement('img')
            img.setAttribute('src', `../assets/img/LogoWinner${this.player}.png`)
            img.setAttribute('id', 'imgWinner')
            popMessage.appendChild(img)
        }
        let message = document.createElement('h1')
        message.innerText = this.text
        message.setAttribute('id', 'message')
        popMessage.appendChild(message)
    }

    deleteMessage() {
        let messageViewer = document.getElementById('messageViewer')
        let popMessage = document.getElementById('popMessage')
        if (popMessage) {
            messageViewer.removeChild(popMessage)
        }
    }
    
    callLater(call, delay) {
        this.timeouts.push(setTimeout(call, delay))
    }

    clear() {
        for (let i = 0; i < this.timeouts.length; i++) {
            clearTimeout(this.timeouts[i]);
        }
    }

}

export default Message;