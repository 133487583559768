import { useGamepadSession } from "../../unboared-com";

import Scenario from "./config/Scenario";

const GamepadApp = () => {
    const { scenario } = useGamepadSession();
    return (Scenario[scenario].gamepad)
}

export default GamepadApp;
