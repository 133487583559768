import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useListener } from '../../../unboared-com';
import { Header } from '../../../react-unboared';
import SystemActionsWidget from '../system/S_SystemActionsWidget';

const StyledGameScreen = styled.div`
    // position: absolute;
    height: 100vh;
    width: 100vw;
    // background-color: ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};

    .logo {
        position: absolute;
        width : 12%;
        margin : 2rem;
        cursor:pointer;
        z-index:99;
    }
    
    // .system_actions {
    //     position: absolute;
    //     bottom:2%;
    //     left:2%;
    // }

`

const LayoutGameScreen = (props) => {
    const history = useHistory();
    const goTo = (path) => {
        history.push(path);
    }
    useListener("pressUnboaredGames", () => { goTo("/") });

    return (
        <StyledGameScreen>
            <Link className='logo' to='/'><Header /></Link>
            <SystemActionsWidget className='system_actions' />
            {props.children}
        </StyledGameScreen>
    );
}

export default LayoutGameScreen;