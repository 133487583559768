import AvatarList from '../../../assets/json/avatar.json'
import { sampleFromList } from '../../../Tools';
import { ColorPlayerIcon } from '../../../react-unboared';

const AvatarSelection = ({ avatar, updateAvatar, ...props }) => {
    const callUpdate = () => {
        let newAvatar = sampleFromList(AvatarList);
        if (newAvatar !== avatar) {
            updateAvatar(newAvatar);
        }
        else {
            callUpdate();
        }
    }

    return <ColorPlayerIcon {...props} avatar={avatar} size='20rem' onClick={callUpdate} />
}

export default AvatarSelection;