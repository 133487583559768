import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useScreenScenario, useScreenSession, useListener } from '../../unboared-com';
import { useSoundManager } from '../../react-unboared';

import styles from './GameEngine.module.scss'
import GameComponent from '../../config/ScreenComponent';
import { logAnalyticsEvent } from '../../utils/firebase';

const GameEngine = () => {
    const { game } = useParams();
    console.log("[Game Engine] REWRITE GameEngine");
    console.log(game + " - home");

    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);
    const statusConnexion = useScreenSession((state) => state.statusConnexion);
    const onPause = useSoundManager((state) => state.onPause);

    const restartGame = () => {
        setLocalScenario(game, "home");
    }

    useScreenScenario(game, "home", null, [statusConnexion])
    useListener("pressRestart", restartGame)

    useEffect(() => {
        onPause();
        logAnalyticsEvent(`${game}/access`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let Game = GameComponent[game];
    return (
        <div className={styles.container}>
            {Game}
        </div>
    );
}

export default GameEngine;
