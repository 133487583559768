
import { useGamepadCom } from "../../../unboared-com";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

import { InGameNavLayout, UnboaredIcon, NavButtonGamepad, Header, CenteredFlexBox } from "../../../react-unboared";
import menu_logo from '../assets/img/unblind-test.png'

const Gamepad = () => {
    const { emitAction } = useGamepadCom();

    const nav = (
        <CenteredFlexBox>
            <NavButtonGamepad
                title="Back to games"
                icon={<UnboaredIcon MaterialIcon={VideogameAssetIcon} size={6} />}
                onClick={() => emitAction("pressUnboared")} />
            <NavButtonGamepad
                title="Restart"
                icon={<UnboaredIcon MaterialIcon={RestartAltIcon} size={6} />}
                onClick={() => emitAction("pressMenu")}
            />
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header src={menu_logo}/>} nav={nav}>
        </InGameNavLayout>
    )
}

export default Gamepad;
