import { createGlobalStyle } from "styled-components";
import { GlobalFonts, BackgroundCover } from "../../react-unboared";

import BackgroundGamepad from "./assets/img/CN_Fond_Smartphone.jpg"
import BackgroundScreen from "./assets/img/CN_Fond_Screen.jpg"

const CodenamesGlobalStyles = createGlobalStyle`
	* {
		font-family : 'Montserrat';
	}
    .gamepad {
        &::after {
            ${BackgroundCover(BackgroundGamepad)}
            opacity: 0.2;
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1;  
        }  
    }
    
    .screen {
        &::after {
            ${BackgroundCover(BackgroundScreen)}
            content: "";
            opacity: 0.6;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1;  
        }   
    }
`;

const CodenamesStyles = (props) => {
    return (
        <>
            <GlobalFonts />
            <CodenamesGlobalStyles />
            {props.children}
        </>
    );
}

export default CodenamesStyles;