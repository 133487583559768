import styled from 'styled-components'
import { PRIMARY_COLOR, UnboaredIcon } from '../..';

import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import QrCodeIcon from '@mui/icons-material/QrCode';


const StyleSystemActionButton = styled.span`
    display:inline;
    ${(props) => (props.isActive ? `color:${PRIMARY_COLOR}` : "")}
`

const SystemActionButton = ({ size, isActive, onIcon, offIcon, onClick, ...props }) => {
    return (
        <StyleSystemActionButton
            {...props}
            isActive={isActive} onClick={onClick}
            color={props.color} backgroundColor={props.backgroundColor} activeColor={props.activeColor}
        >
            <UnboaredIcon size={size} MaterialIcon={(isActive ? onIcon : offIcon)} />
        </StyleSystemActionButton>
    );
}


export const MuteButton = ({ size, isActive, onClick, ...props }) => {
    return (
        <SystemActionButton
            {...props}
            onIcon={VolumeOffIcon} offIcon={VolumeUpIcon}
            isActive={isActive}
            size={size}
            onClick={onClick}
        />
    );
}

export const FullscreenButton = ({ size, isActive, onClick, ...props }) => {
    return (
        <SystemActionButton
            {...props}
            onIcon={FullscreenExitIcon} offIcon={FullscreenIcon}
            isActive={isActive}
            size={size}
            onClick={onClick}
        />
    );
}


export const FavoriteButton = ({ size, isActive, onClick, ...props }) => {
    return (
        <SystemActionButton
            {...props}
            onIcon={FavoriteIcon} offIcon={FavoriteBorderIcon}
            isActive={isActive}
            size={size}
            onClick={onClick}
        />
    );
}


export const ConnexionButton = ({ size, isActive, onClick, ...props }) => {
    return (
        <SystemActionButton
            {...props}
            onIcon={QrCodeIcon} offIcon={QrCodeIcon}
            isActive={isActive}
            size={size}
            onClick={onClick}
        />
    );
}



export default SystemActionButton;
