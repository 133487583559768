import { useEffect } from "react";
import { useDefaultPrototocol } from ".";


const useListener = (name, func, listDeps = []) => {
    const { com } = useDefaultPrototocol();

    const fun_log = (data)=>{
        console.log(`[useListener] Receive data ${JSON.stringify(data)}`)
        func(data)
    }
   
    useEffect(() => {
        console.log("[useListener(" + com.type + ")] " + name)
        com.on(name, fun_log);
        return () => {
            com.off(name, fun_log);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, listDeps);
};

export default useListener;