import { useScreenSession } from "../../unboared-com";

import Scenario from "./config/Scenario";

const ScreenApp = () => {
    const scenario = useScreenSession((state) => state.scenario);
    const scenarioComponent = useScreenSession((state) => state.scenarioComponent);

    return (scenarioComponent ? scenarioComponent : Scenario[scenario].screen);
}

export default ScreenApp;