import create from "zustand"

import { SocketIO } from "./SocketIO"
import { SocketIOConfig, getConfig } from "../Config";

const Config = getConfig(SocketIOConfig);

const useSocketIO = create(set => ({
    com: new SocketIO(Config.SERVER, Config.PORT, Config.PROTOCOL),
}))

export default useSocketIO;