import React, { useRef, useEffect } from 'react';

import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { UnboaredIcon, NavButton, FavoriteButton } from '../../../react-unboared';

// import Modal, { ModalContent } from '../modal/Modal';
import apiConfig from '../../../api/apiConfig';

import './hero-slide.scss';
import { StyledHeroSlide, StyledFeature, StyledHeroSlideItem } from './StyledHeroSlide';

import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
// import PlayCircleFilledTwoToneIcon from '@mui/icons-material/PlayCircleFilledTwoTone';
// import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';

const HeroSlide = ({ gameItems, activeGame }) => {
    console.log("[HeroSlide] Rewrite");

    SwiperCore.use([Pagination]);

    const swiperRef = useRef(null)

    useEffect(() => {
        if (swiperRef !== null) {
            // swiperRef.current.swiper.autoplay.stop()
            swiperRef.current.swiper.slideTo(activeGame);
        }
    }, [swiperRef, activeGame])

    return (
        <StyledHeroSlide>
            <Swiper
                ref={swiperRef}
                grabCursor={true}
                spaceBetween={0}
                slidesPerView={1}
                speed={1}
                allowTouchMove={false}
                // autoplay={{ delay: 3500 }}
                pagination={{ clickable: true }}
            >
                {
                    gameItems.map((item, i) => (
                        <SwiperSlide key={i}>
                            {({ isActive }) => (
                                <HeroSlideItem item={item} className={`${isActive ? 'active' : ''}`} />
                            )}
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            {/* {
                gameItems.map((item, i) => <TrailerModal key={i} item={item} />)
            } */}
        </StyledHeroSlide>
    );
}

const GameFeature = ({ icon, text }) => {
    return (
        <StyledFeature>
            <NavButton className="feature-icon">
                <UnboaredIcon MaterialIcon={icon} size="2.4" />
            </NavButton>
            <h3 className="feature-text">{text}</h3>
        </StyledFeature>
    );
}

const HeroSlideItem = props => {

    const item = props.item;

    const background = apiConfig.originalImage(item.backdrop_path ? item.backdrop_path : item.poster_path);

    // const setModalActive = async () => {
    //     const modal = document.querySelector(`#modal_${item.id}`);

    //     // const videos = await tmdbApi.getVideos(category.movie, item.id);

    //     // if (videos.results.length > 0) {
    //     //     const videSrc = 'https://www.youtube.com/embed/' + videos.results[0].key;
    //     //     modal.querySelector('.modal__content > iframe').setAttribute('src', videSrc);
    //     // } else {
    //     //     modal.querySelector('.modal__content').innerHTML = 'No trailer';
    //     // }

    //     modal.classList.toggle('active');
    // }

    return (
        <StyledHeroSlideItem>
            <div
                className={`hero-slide__item ${props.className}`}
            >
                <div className="hero-slide__item__content container">
                    <div className="hero-slide__item__content__info">
                        <h1 className="title">{item.title}</h1>
                        <p className="overview">{item.overview}</p>
                        <div className="features">
                            <GameFeature icon={GroupOutlinedIcon} text={`${item.minPlayers}-${item.maxPlayers} players`} />
                            <GameFeature icon={WatchLaterIcon} text={item.duration} />
                        </div>

                        <div className="features btns">
                            <StyledFeature>
                                <svg style={{ marginRight: '1rem', verticalAlign: 'middle' }} width="50" height="50" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="38" cy="38" r="38" fill="white" fillOpacity="0.2" />
                                    <path d="M30 23V53L54 38L30 23Z" fill="white" />
                                </svg>
                                <h3 className="feature-text">Play this game</h3>
                            </StyledFeature>
                            {/* <GameFeature icon={GroupOutlinedIcon} text={`${item.minPlayers}-${item.maxPlayers} players`} /> */}
                            <StyledFeature>
                                <FavoriteButton style={{ marginRight: '1rem', verticalAlign: 'middle' }} size={3.5} isActive={true} />
                                <h3 className="feature-text">Loved game</h3>
                            </StyledFeature>
                        </div>
                    </div>
                </div>
                <div className="hero-slide__item__content__poster" style={{ backgroundImage: `url(${background})` }}>
                </div>
            </div >
        </StyledHeroSlideItem>

    )
}

// const TrailerModal = props => {
//     const item = props.item;

//     const iframeRef = useRef(null);

//     const onClose = () => iframeRef.current.setAttribute('src', '');

//     return (
//         <Modal active={false} id={`modal_${item.id}`}>
//             <ModalContent onClose={onClose}>
//                 <iframe ref={iframeRef} width="100%" height="500px" title="trailer"></iframe>
//             </ModalContent>
//         </Modal>
//     )
// }

export default HeroSlide;

export { /* TrailerModal,  */HeroSlideItem }
