import styled from 'styled-components';
import React, { useEffect, useRef } from 'react';

const StyledVideoPlayer = styled.video`
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const VideoPlayer = ({src, onEnd}) => {
  const vidRef = useRef(null);
  useEffect(() => {
    vidRef.current.play();
  }, [])
  return (
    <StyledVideoPlayer ref={vidRef} onEnded={() => onEnd()}>
      <source src={src} type="video/mp4" />
    </StyledVideoPlayer>
  )
}

export default VideoPlayer;