import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Home from '../screen/home/Home';
import Catalog from '../screen/search/Catalog';
import GameEngine from '../screen/game-engine/GameEngine';

const ScreenRoutes = () => {
    return (
        <Switch>
            <Route
                path='/:category/search/:keyword'
                component={Catalog}
            />
            <Route
                path='/game/:game'
                component={GameEngine}
            />
            <Route
                path='/:category'
                component={Catalog}
            />
            <Route
                path='/'
                exact
                component={Home}
            />
        </Switch>
    );
}


export default ScreenRoutes;
