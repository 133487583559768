import { useEffect } from 'react';
import analytics from './analytics';
import { isProduction } from '../../unboared-com';
import { logEvent } from 'firebase/analytics';

export const logAnalyticsEvent = (eventName, data = {}) => {
  if (isProduction()) {
    logEvent(analytics, eventName, data);
  }
  else {
    console.log(`[ANALYTICS] logEvent - ${eventName}`);
  }
}

export const useAnalyticsAccessEvent = (eventName, data = {}) => {
  useEffect(() => {
    logAnalyticsEvent(eventName, data);
    // eslint-disable-next-line
  }, []);
}
