/***********************/
/* RESPONSIVE SETTINGS */
/***********************/

export const MOBILE_BREAKPOINT = 600;
export const TABLET_BREAKPOINT = 1024;
export const LAPTOP_BREAKPOINT = 2560;
export const DESKTOP_BREAKPOINT = 3500;

const BREAKPOINT = {
    mobile: MOBILE_BREAKPOINT,
    tablet: TABLET_BREAKPOINT,
    laptop: LAPTOP_BREAKPOINT,
    desktop: DESKTOP_BREAKPOINT,
}

export const device = {
    mobile: `only screen and (max-width: ${BREAKPOINT.mobile}px)`,
    tablet: `only screen and (max-width: ${BREAKPOINT.tablet}px)`,
    laptop: `only screen and (max-width: ${BREAKPOINT.laptop}px)`,
    desktop: `only screen and (max-width: ${BREAKPOINT.desktop}px)`
};

export default BREAKPOINT;