import styled from 'styled-components';
import { useHistory } from 'react-router';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

import { useScreenSession, useListener, useExternalScenario } from '../../../unboared-com'
import { UnboaredIcon, NavButtonGamepad, CenteredFlexBox, InGameNavLayout, Header } from '../../../react-unboared';

import menu_logo from '../assets/img/unblind-test.png'
import { GAME_NAME } from '../config/Const';
import TableResults from './Results/TableResults';

const EndGame = ({ scores, players }) => {
    console.log("[Unblind Test] REWRITE Game")

    const history = useHistory();
    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);
    console.log(JSON.stringify(scores));


    // Update Scenario to category selection
    let callbackUnboared = () => {
        history.push(`/`);
        setLocalScenario("catalog", "home");
    }

    let callbackHome = () => {
        setLocalScenario(GAME_NAME, "home");
    }

    // Update Scenario to category selection
    useExternalScenario(GAME_NAME, "endgame");

    // Listen press instruction menu button 
    useListener("pressUnboared", callbackUnboared);

    // Listen press play menu button 
    useListener("pressMenu", callbackHome);

    const GameHeader = <Header src={menu_logo} />;
    const GameNav = (<CenteredFlexBox>
        <NavButtonGamepad
            title="Back to games"
            icon={<UnboaredIcon MaterialIcon={VideogameAssetIcon} size={6} />}
            onClick={callbackUnboared} />
        <NavButtonGamepad
            title="Restart"
            icon={<UnboaredIcon MaterialIcon={RestartAltIcon} size={6} />}
            onClick={callbackHome}
        />
    </CenteredFlexBox>);

    return (
        <InGameNavLayout header={GameHeader} nav={GameNav}>
            <ScoreContent>
                <TableResults scores={scores} players={players} />
            </ScoreContent>
        </InGameNavLayout>
    );
}

const ScoreContent = styled.div`
    width : 60%;
`

export default EndGame;