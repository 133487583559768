import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useGamepadCom } from "../../../../unboared-com";
import { Header, UnboaredIcon } from "../../../../react-unboared";
import { InGameNavLayout, CenteredFlexBox } from "../../../../react-unboared";
import UnboaredSpeedLogo from '../../assets/img/jungle_speed.png';
import NavButton from '../../components/NavButton';

const Gamepad = () => {
    const { emitAction } = useGamepadCom();

    const nav_items = [
        <NavButton
            key={"instructions"}
            title="Instructions"
            icon={<UnboaredIcon MaterialIcon={HelpOutlineIcon} size={6} />}
            onClick={() => emitAction("pressInstructions")} />,
        <NavButton
            key={"play"}
            title="Play"
            icon={<UnboaredIcon MaterialIcon={PlayCircleOutlineIcon} size={6} />}
            onClick={() => emitAction("pressPlay")}
        />
        // <NavButton
        //     key={"settings"}
        //     title="Settings"
        //     icon={<UnboaredIcon MaterialIcon={SettingsIcon} size={3} />}
        //     onClick={() => emitAction("pressSettings")}
        // />
    ];

    const nav = (
        <CenteredFlexBox>
            {nav_items}
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header src={UnboaredSpeedLogo} />} nav={nav}>
        </InGameNavLayout>
    )
}

export default Gamepad;

