
import GamepadUntemplate from "../games/untemplate/GamepadApp";
import GamepadCatalog from "../games/catalog/GamepadApp";
import GamepadUnblindTest from "../games/blind-test/GamepadApp";
import GamepadCodenames from "../games/codenames/GamepadApp";
import GamepadJungleSpeed from "../games/jungle-speed/GamepadApp";
import GamepadBomberkitten from "../games/bomber-kitten/GamepadApp";

const GamepadComponent = {
    "catalog": <GamepadCatalog />,
    "untemplate": <GamepadUntemplate />,
    "codenames": <GamepadCodenames />,
    "unblind-test": <GamepadUnblindTest />,
    "jungle-speed": <GamepadJungleSpeed />,
    "bomber-kitten": <GamepadBomberkitten />,
}

export default GamepadComponent;