import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useGamepadCom } from "../../../../unboared-com";

import { UnboaredIcon, Header, NavButtonGamepad, CenteredFlexBox, InGameNavLayout } from "../../../../react-unboared";
import LogoCodenames from '../../assets/img/Logo_Codenames_White.png';

const Gamepad = () => {
    const { emitAction } = useGamepadCom();

    const nav_items = [
        <NavButtonGamepad
            title="Instructions"
            icon={<UnboaredIcon MaterialIcon={ArrowCircleLeftOutlinedIcon} size={6} />}
            onClick={() => emitAction("pressReturn")} />
    ];

    const nav = (
        <CenteredFlexBox>
            {nav_items}
        </CenteredFlexBox>
    );

    return (
        <InGameNavLayout header={<Header src={LogoCodenames}/>} nav={nav}>
        </InGameNavLayout>
    )
}

export default Gamepad;



