import styled from 'styled-components';
import { useListener } from '../../../unboared-com';
import { MuteButton, FullscreenButton, ConnexionButton, useSoundManager, Flex } from '../../../react-unboared';
import useSystemActions from '../../../hooks/useSystemActions';
import ConnexionSection from '../../connexion/ConnexionSection';
import { toggleMuteSystem } from '../../../Tools';

const StyledSystemActionWidget = styled.div`
        position : fixed;
        z-index: 9;
        left:1%;
        bottom:5%;
        ${Flex({ alignItem: "center", justifyContent: "unset" })}
        background-color:${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
        padding : 0.8rem;
        border-radius: ${({ theme }) => theme.BORDER_RADIUS};
        box-shadow: 0px 0px 0.4rem 0.01rem ${({ theme }) => theme.PRIMARY_TEXT_COLOR};
        * {
            cursor:pointer;
        }
        &>*~* {
            margin-left: 2rem;
        }
`

const S_SystemActionsWidget = (props) => {
    console.log('[S_SystemActionsToolbar] Rewrite');

    const sizeIcon = 3;

    const { isFullscreen, toggleFullscreen, showConnexionInfos, toggleShowConnexionInfos } = useSystemActions();
 
    const volume = useSoundManager(state => state.volume);

    useListener("pressConnexionInfos", toggleShowConnexionInfos);
    useListener("pressMute", toggleMuteSystem);
    useListener("pressFullscreen", () => toggleFullscreen(document.documentElement));

    return (
        <div>
            {showConnexionInfos ? <ConnexionSection callbackClose={toggleShowConnexionInfos} /> : null}
            <StyledSystemActionWidget {...props}>

                <FullscreenButton
                    isActive={isFullscreen}
                    size={sizeIcon}
                    onClick={() => toggleFullscreen(document.documentElement)}
                />
                <MuteButton
                    isActive={volume === 0}
                    size={sizeIcon}
                    onClick={toggleMuteSystem}
                />
                <ConnexionButton
                    isActive={showConnexionInfos}
                    size={sizeIcon}
                    onClick={toggleShowConnexionInfos}
                />
            </StyledSystemActionWidget>
        </div>
    );

}

export default S_SystemActionsWidget;