import COLOR from "./Color"

const LIGHT_COLOR_THEME = {
    mode: 'light',
    PRIMARY_BACKGROUND_COLOR: COLOR.LIGHT,
    PRIMARY_TEXT_COLOR: COLOR.DARK,
    SECONDARY_TEXT_COLOR: COLOR.PRIMARY,
    PRIMARY_BUTTON_COLOR: "rgba(0, 0, 0, 0.1)",
    SECONDARY_BUTTON_COLOR: 'unset',
    PRIMARY_ACTIVE_BUTTON_COLOR: COLOR.PRIMARY,
    SECONDARY_ACTIVE_BUTTON_COLOR: 'unset',
}

const DARK_COLOR_THEME = {
    /* COLORS */
    mode: 'dark',
    PRIMARY_BACKGROUND_COLOR: COLOR.DARK,
    PRIMARY_TEXT_COLOR: COLOR.LIGHT,
    SECONDARY_TEXT_COLOR: COLOR.PRIMARY,
    PRIMARY_BUTTON_COLOR: "rgba(255, 255, 255, 0.1)",
    SECONDARY_BUTTON_COLOR: "unset",
    PRIMARY_ACTIVE_BUTTON_COLOR: COLOR.PRIMARY,
    SECONDARY_ACTIVE_BUTTON_COLOR: COLOR.PRIMARY,
}

const UNBOARED_DESIGN_THEME = {
    /* FONTS */
    PRIMARY_TITLE_FONT: 'Montserrat',
    SECONDARY_TITLE_FONT: 'Montserrat',
    PRIMARY_TEXT_FONT: 'OpenSans',
    SECONDARY_TEXT_FONT: 'OpenSans',

    /* BOXES */
    BORDER_RADIUS: '1.5rem',
    BOX_SHADOW: 'unset',
}

const UNBOARED_SONG_THEME = {
    /* SONGS */
    PRIMARY_AMBIANCE_SONG: 'https://raw.githubusercontent.com/unboared/public-assets/master/sounds/Ambiance.mp3',
    SECONDARY_AMBIANCE_SONG: 'https://raw.githubusercontent.com/unboared/public-assets/master/sounds/Ambiance.mp3',
    PRIMARY_MOVE_SONG: 'https://raw.githubusercontent.com/unboared/public-assets/master/sounds/Move.mp3',
    SECONDARY_MOVE_SONG: 'https://raw.githubusercontent.com/unboared/public-assets/master/sounds/Move.mp3',
    PRIMARY_VALIDATE_SONG: 'https://raw.githubusercontent.com/unboared/public-assets/master/sounds/Validate.mp3',
    SECONDARY_VALIDATE_SONG: 'https://raw.githubusercontent.com/unboared/public-assets/master/sounds/Validate.mp3',
}


export const UNBOARED_DARK_THEME = Object.assign({}, UNBOARED_DESIGN_THEME, DARK_COLOR_THEME, UNBOARED_SONG_THEME);
export const UNBOARED_LIGHT_THEME = Object.assign({}, UNBOARED_DESIGN_THEME, LIGHT_COLOR_THEME, UNBOARED_SONG_THEME);

const THEME = {
    DEFAULT: UNBOARED_DARK_THEME,
    LIGHT: UNBOARED_LIGHT_THEME,
    DARK: UNBOARED_DARK_THEME,
}

export default THEME;