// import { useEffect } from 'react';

import { CenteredFlexBox, Header } from '../../react-unboared';
import discordImg from '../../assets/img/discord.png';
import StyledAccessPage from './StyledAccessPage'

import { logAnalyticsEvent } from '../../utils/firebase';

const FollowPage = ({ onConnect, ...props }) => {
    console.log("[FollowPage] Rewrite FollowPage")

    return (
        <StyledAccessPage>
            <div className='elements'>
                <Header className='logo' />
                <h1>
                    Get your access code !
                </h1>
                <p>Join our community to get the code and be informed about Unboared news.
                </p>
                <h2>By email</h2>
                <form method="POST"
                    action="https://b952e082.sibforms.com/serve/MUIEAAg-Tqyodqdtt4icyucfOpEoOnjvvq2Z2CejMSRQiZRKw7SzqoUbpCPswb82tEFcEQLZQPG8DdC-guVsSOLiF38TZwC6p8v_tImxap9RI9KzhO9mQt6L5Zzhp5K3Mm36052TEhMLx2_d_288QCVBTAq2V380pCaY5vORSSlqh4BLqLOSXEYZ8AJkSIDJZ026_a5ePQEqtPAo"
                    data-type="subscription">
                    <input className="input" type="text" id="EMAIL" name="EMAIL" autoComplete="off" placeholder="Your email"
                        data-required="true" required />
                    <input className={`validation-btn submitAccessCode submitLaunch`} type="submit" value="Notify me" />
                </form>
                <h2>With Discord</h2>
                <button
                    onClick={() => {
                        logAnalyticsEvent('screen_discord_link');
                        window.open('https://discord.gg/QsJhQRQeFE')
                    }}
                    className='discordBtn'>
                    <CenteredFlexBox className='discordButtonContent'>
                        <img src={discordImg} alt='Discord' />
                        Serveur Discord
                    </CenteredFlexBox>
                </button>
            </div>
        </StyledAccessPage >
    )
}

export default FollowPage;