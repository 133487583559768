import QRCode from "react-qr-code";
import { GridLoader } from 'react-spinners';
import { getRoomURL } from '../../config/Const';
// import { PRIMARY_COLOR } from '../../react-unboared';



const StyleQRCodeView = {
    backgroundColor: "white",
    padding: "16px"
}

var host = window.location.protocol + '//' +  window.location.host; 

/**
 * Display the QRCode related to a room identifier.
 * @param {int} roomID - The identifier of the room.
 * 
 */
const QRCodeView = ({ roomID, ...props }) => {
    if (roomID === '0') {
        return <GridLoader color={"#ffffff"} loading={true} size={80} css={{ width: 270 }} />
    }

    return (
        <div {...props} style={StyleQRCodeView}>
            <QRCode size={256} value={getRoomURL(host, roomID)} />
        </div>
    );
}

const StyleRoomIDView = {
    fontSize: "2rem",
    fontWeight:'700',
    padding: "16px",
}


/**
 * Display the room identifier in a box.
 * @param {int} roomID - The identifier of the room.
 * 
 */
const RoomIDView = ({ roomID, ...props }) => {
    return (
        <button {...props} className='validation-btn' style={StyleRoomIDView} onClick={() => window.open(getRoomURL(host, roomID), '_blank')}>
            {(roomID === '0') ? '...' : roomID}
        </button>
    );
}

export { QRCodeView, RoomIDView }