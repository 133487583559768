import create from "zustand"
import { useDefaultGame, useDefaultScenario, useDefaultStatusPlayers, useDefaultStatusConnexion } from "..";

const useScreenSession = create(
    (set) => ({
        game: useDefaultGame(),
        scenario: useDefaultScenario(),
        scenarioComponent: null,
        statusPlayers: useDefaultStatusPlayers(),
        statusConnexion: useDefaultStatusConnexion(),
        setStatusPlayers: new_status => {
            set({ statusPlayers: new_status })
        },
        setStatusConnexion: new_status => set({ statusConnexion: new_status }),
        setLocalScenario: (game, scenario, scenarioComponent = null) => {
            set({ game: game, scenario: scenario, scenarioComponent: scenarioComponent });
        }
    })
)

export default useScreenSession;