import { useHistory } from 'react-router';
import { GAME_NAME, DATA } from '../../config/Const';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VideogameAsset from '@mui/icons-material/VideogameAsset';
import styled from 'styled-components';

import { useExternalScenario, useScreenSession, useListener } from '../../../../unboared-com'
import { NavButtonGamepad, OverlayView, UnboaredIcon, CenteredFlexBox } from '../../../../react-unboared';
import DetectiveCard from '../../components/DetectiveCard/DetectiveCard';

const StyledEndgame = styled.div`
    padding:2rem;
    text-align:center;
    .message {
        margin-bottom:4rem;
    }
    .card {
        width: 50%;
    }
`;

const EndGame = ({ team }) => {
    console.log("[Codenames] REWRITE endgame")

    const history = useHistory();
    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);

    // Update Scenario to category selection
    let callbackUnboared = () => {
        history.push(`/`);
        setLocalScenario("catalog", "home");
    }

    let callbackHome = () => {
        setLocalScenario(GAME_NAME, "home");
    }

    let callbackContinue = () => {
        setLocalScenario(GAME_NAME, "home");
    }

    // Update Scenario to category selection
    useExternalScenario(GAME_NAME, "endgame");

    // Listen press instruction menu button 
    useListener("pressUnboared", callbackUnboared);

    // Listen press play menu button 
    useListener("pressHome", callbackHome);

    // Listen press play menu button 
    useListener("pressContinue", callbackContinue);

    const nav = (
        <CenteredFlexBox>
            <NavButtonGamepad
                key={"games"}
                title="Back to games"
                icon={<UnboaredIcon MaterialIcon={VideogameAsset} size={4} />}
                onClick={callbackUnboared} />
            <NavButtonGamepad
                key={"restart"}
                title="Restart"
                icon={<UnboaredIcon MaterialIcon={RestartAltIcon} size={4} />}
                onClick={callbackHome}
            />
            {/* <NavButtonGamepad
                key={"continue"}
                title="Continue"
                icon={<UnboaredIcon MaterialIcon={ArrowRightIcon} size={4} />}
                onClick={callbackContinue}
            /> */}
        </CenteredFlexBox>
    );
    return (
        <OverlayView>
            <StyledEndgame>
                <CenteredFlexBox>
                        <DetectiveCard className='card' src={DATA[team.name].card} alt='' />
                    <div className='message'>
                        <h1>Team <span style={{color:`${DATA[team.name].color}`}}>{team.name}</span> wins !</h1>
                        {team.players.map(player => <h2 className='player'>{player.username}</h2>)}
                    </div>
                    {nav}
                </CenteredFlexBox>
            </StyledEndgame>
        </OverlayView>
    );
}

export default EndGame;