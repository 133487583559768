


const Pad = ({pressOK, pressUp, pressDown, pressLeft, pressRight}) => {
    return (
        <svg x="-44" width="300" height="320" xmlns="http://www.w3.org/2000/svg">
            <svg onClick={pressUp} x="44" width="214" height="121" viewBox="0 0 214 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_1_52" fill="white">
                    <path fillRule="evenodd" clipRule="evenodd" d="M164.402 11.4181C182.601 18.9563 199.137 30.0052 213.066 43.934L136.698 120.302C129.098 112.701 118.598 108 107 108C95.402 108 84.902 112.701 77.3015 120.302L0.933952 43.934C14.8627 30.0052 31.3986 18.9563 49.5974 11.4181C67.7963 3.87987 87.3017 4.46154e-06 107 0C126.698 -4.46153e-06 146.204 3.87986 164.402 11.4181Z" />
                </mask>
                <path fillRule="evenodd" clipRule="evenodd" d="M164.402 11.4181C182.601 18.9563 199.137 30.0052 213.066 43.934L136.698 120.302C129.098 112.701 118.598 108 107 108C95.402 108 84.902 112.701 77.3015 120.302L0.933952 43.934C14.8627 30.0052 31.3986 18.9563 49.5974 11.4181C67.7963 3.87987 87.3017 4.46154e-06 107 0C126.698 -4.46153e-06 146.204 3.87986 164.402 11.4181Z" fill="white" fillOpacity="0.1" />
                <path d="M213.066 43.934L215.894 46.7624L218.723 43.934L215.894 41.1055L213.066 43.934ZM164.402 11.4181L165.933 7.72254L164.402 11.4181ZM136.698 120.302L133.87 123.13L136.698 125.958L139.527 123.13L136.698 120.302ZM77.3015 120.302L74.4731 123.13L77.3015 125.958L80.1299 123.13L77.3015 120.302ZM0.933952 43.934L-1.89448 41.1056L-4.7229 43.934L-1.89447 46.7624L0.933952 43.934ZM49.5974 11.4181L48.0667 7.72257L48.0667 7.72257L49.5974 11.4181ZM107 0L107 -4L107 0ZM215.894 41.1055C201.594 26.8053 184.617 15.4618 165.933 7.72254L162.872 15.1136C180.585 22.4508 196.68 33.205 210.238 46.7624L215.894 41.1055ZM139.527 123.13L215.894 46.7624L210.238 41.1055L133.87 117.473L139.527 123.13ZM107 112C117.495 112 126.989 116.249 133.87 123.13L139.527 117.473C131.207 109.153 119.701 104 107 104V112ZM80.1299 123.13C87.011 116.249 96.5055 112 107 112V104C94.2986 104 82.7931 109.153 74.4731 117.473L80.1299 123.13ZM-1.89447 46.7624L74.4731 123.13L80.1299 117.473L3.76238 41.1056L-1.89447 46.7624ZM48.0667 7.72257C29.3826 15.4618 12.4057 26.8054 -1.89448 41.1056L3.76238 46.7624C17.3197 33.2051 33.4146 22.4508 51.1282 15.1136L48.0667 7.72257ZM107 -4C86.7764 -4 66.7509 -0.0166624 48.0667 7.72257L51.1282 15.1136C68.8417 7.77641 87.827 4 107 4L107 -4ZM165.933 7.72254C147.249 -0.0166809 127.224 -4 107 -4L107 4C126.173 4 145.158 7.77639 162.872 15.1136L165.933 7.72254Z" fill="#070219" mask="url(#path-1-inside-1_1_52)" />
                <path d="M88.64 73.64L107 55.32L125.36 73.64L131 68L107 44L83 68L88.64 73.64Z" fill="white" />
            </svg>

            <svg onClick={pressLeft} y="42" width="121" height="214" viewBox="0 0 121 214" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_1_53" fill="white">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.4181 49.5974C18.9563 31.3986 30.0052 14.8627 43.934 0.933929L120.302 77.3015C112.701 84.902 108 95.402 108 107C108 118.598 112.701 129.098 120.301 136.698L43.934 213.066C30.0052 199.137 18.9563 182.601 11.4181 164.402C3.87986 146.204 -1.17496e-05 126.698 -1.52588e-05 107C-1.52588e-05 87.3017 3.87985 67.7963 11.4181 49.5974Z" />
                </mask>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.4181 49.5974C18.9563 31.3986 30.0052 14.8627 43.934 0.933929L120.302 77.3015C112.701 84.902 108 95.402 108 107C108 118.598 112.701 129.098 120.301 136.698L43.934 213.066C30.0052 199.137 18.9563 182.601 11.4181 164.402C3.87986 146.204 -1.17496e-05 126.698 -1.52588e-05 107C-1.52588e-05 87.3017 3.87985 67.7963 11.4181 49.5974Z" fill="white" fillOpacity="0.1" />
                <path d="M43.934 0.933929L46.7624 -1.8945L43.934 -4.72293L41.1055 -1.8945L43.934 0.933929ZM11.4181 49.5974L7.72254 48.0667L7.72254 48.0667L11.4181 49.5974ZM120.302 77.3015L123.13 80.1299L125.958 77.3015L123.13 74.4731L120.302 77.3015ZM120.301 136.698L123.13 139.527L125.958 136.698L123.13 133.87L120.301 136.698ZM43.934 213.066L41.1056 215.894L43.934 218.723L46.7624 215.894L43.934 213.066ZM11.4181 164.402L15.1136 162.872L15.1136 162.872L11.4181 164.402ZM-1.52588e-05 107L-4.00002 107L-4.00002 107L-1.52588e-05 107ZM41.1055 -1.8945C26.8053 12.4057 15.4618 29.3826 7.72254 48.0667L15.1136 51.1282C22.4508 33.4146 33.205 17.3197 46.7624 3.76236L41.1055 -1.8945ZM123.13 74.4731L46.7624 -1.8945L41.1055 3.76236L117.473 80.1299L123.13 74.4731ZM112 107C112 96.5055 116.249 87.0109 123.13 80.1299L117.473 74.4731C109.153 82.793 104 94.2985 104 107H112ZM123.13 133.87C116.249 126.989 112 117.494 112 107H104C104 119.701 109.153 131.207 117.473 139.527L123.13 133.87ZM46.7624 215.894L123.13 139.527L117.473 133.87L41.1056 210.238L46.7624 215.894ZM7.72255 165.933C15.4618 184.617 26.8053 201.594 41.1056 215.894L46.7624 210.238C33.2051 196.68 22.4508 180.585 15.1136 162.872L7.72255 165.933ZM-4.00002 107C-4.00001 127.224 -0.0166818 147.249 7.72255 165.933L15.1136 162.872C7.77639 145.158 3.99999 126.173 3.99998 107L-4.00002 107ZM7.72254 48.0667C-0.0166885 66.7509 -4.00002 86.7764 -4.00002 107H3.99998C3.99998 87.827 7.77639 68.8417 15.1136 51.1282L7.72254 48.0667Z" fill="#070219" mask="url(#path-1-inside-1_1_53)" />
                <path d="M67.64 125.36L49.32 107L67.64 88.64L62 83L38 107L62 131L67.64 125.36Z" fill="white" />
            </svg>
            <svg onClick={pressRight} x="180" y="42" width="121" height="214" viewBox="0 0 121 214" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_1_55" fill="white">
                    <path fillRule="evenodd" clipRule="evenodd" d="M109.582 164.402C102.044 182.601 90.9948 199.137 77.066 213.066L0.69849 136.698C8.29899 129.098 13 118.598 13 107C13 95.402 8.29899 84.902 0.698471 77.3015L77.066 0.93396C90.9948 14.8627 102.044 31.3986 109.582 49.5975C117.12 67.7963 121 87.3017 121 107C121 126.698 117.12 146.204 109.582 164.402Z" />
                </mask>
                <path fillRule="evenodd" clipRule="evenodd" d="M109.582 164.402C102.044 182.601 90.9948 199.137 77.066 213.066L0.69849 136.698C8.29899 129.098 13 118.598 13 107C13 95.402 8.29899 84.902 0.698471 77.3015L77.066 0.93396C90.9948 14.8627 102.044 31.3986 109.582 49.5975C117.12 67.7963 121 87.3017 121 107C121 126.698 117.12 146.204 109.582 164.402Z" fill="white" fillOpacity="0.1" />
                <path d="M77.066 213.066L74.2376 215.894L77.066 218.723L79.8945 215.894L77.066 213.066ZM109.582 164.402L113.277 165.933L113.277 165.933L109.582 164.402ZM0.69849 136.698L-2.12994 133.87L-4.95836 136.698L-2.12994 139.527L0.69849 136.698ZM0.698471 77.3015L-2.12996 74.4731L-4.95838 77.3015L-2.12996 80.1299L0.698471 77.3015ZM77.066 0.93396L79.8944 -1.89447L77.066 -4.72289L74.2376 -1.89447L77.066 0.93396ZM109.582 49.5975L105.886 51.1282L105.886 51.1282L109.582 49.5975ZM121 107L117 107L117 107L121 107ZM79.8945 215.894C94.1947 201.594 105.538 184.617 113.277 165.933L105.886 162.872C98.5492 180.585 87.795 196.68 74.2376 210.238L79.8945 215.894ZM-2.12994 139.527L74.2376 215.894L79.8945 210.238L3.52692 133.87L-2.12994 139.527ZM9 107C9 117.495 4.75109 126.989 -2.12994 133.87L3.52692 139.527C11.8469 131.207 17 119.701 17 107H9ZM-2.12996 80.1299C4.75108 87.011 9 96.5055 9 107H17C17 94.2986 11.8469 82.7931 3.5269 74.4731L-2.12996 80.1299ZM74.2376 -1.89447L-2.12996 74.4731L3.5269 80.1299L79.8944 3.76239L74.2376 -1.89447ZM113.277 48.0667C105.538 29.3826 94.1946 12.4058 79.8944 -1.89447L74.2376 3.76239C87.7949 17.3197 98.5492 33.4147 105.886 51.1282L113.277 48.0667ZM125 107C125 86.7764 121.017 66.7509 113.277 48.0667L105.886 51.1282C113.224 68.8417 117 87.827 117 107H125ZM113.277 165.933C121.017 147.249 125 127.224 125 107L117 107C117 126.173 113.224 145.158 105.886 162.872L113.277 165.933Z" fill="#070219" mask="url(#path-1-inside-1_1_55)" />
                <path d="M53.36 88.64L71.68 107L53.36 125.36L59 131L83 107L59 83L53.36 88.64Z" fill="white" />
            </svg>
            <svg onClick={pressDown} x="44" y="177.7" width="214" height="121" viewBox="0 0 214 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_1_54" fill="white">
                    <path fillRule="evenodd" clipRule="evenodd" d="M136.699 0.698507C129.098 8.29901 118.598 13 107 13C95.402 13 84.902 8.299 77.3015 0.698486L0.933975 77.066C14.8628 90.9948 31.3986 102.044 49.5975 109.582C67.7963 117.12 87.3017 121 107 121C126.698 121 146.204 117.12 164.403 109.582C182.601 102.044 199.137 90.9948 213.066 77.066L136.699 0.698507Z" />
                </mask>
                <path fillRule="evenodd" clipRule="evenodd" d="M136.699 0.698507C129.098 8.29901 118.598 13 107 13C95.402 13 84.902 8.299 77.3015 0.698486L0.933975 77.066C14.8628 90.9948 31.3986 102.044 49.5975 109.582C67.7963 117.12 87.3017 121 107 121C126.698 121 146.204 117.12 164.403 109.582C182.601 102.044 199.137 90.9948 213.066 77.066L136.699 0.698507Z" fill="white" fillOpacity="0.1" />
                <path d="M136.699 0.698507L139.527 -2.12992L136.699 -4.95835L133.87 -2.12992L136.699 0.698507ZM77.3015 0.698486L80.1299 -2.12994L77.3015 -4.95837L74.4731 -2.12994L77.3015 0.698486ZM0.933975 77.066L-1.89445 74.2376L-4.72288 77.066L-1.89445 79.8945L0.933975 77.066ZM49.5975 109.582L48.0668 113.277L48.0668 113.277L49.5975 109.582ZM107 121L107 117L107 117L107 121ZM164.403 109.582L165.933 113.277L165.933 113.277L164.403 109.582ZM213.066 77.066L215.894 79.8944L218.723 77.066L215.894 74.2376L213.066 77.066ZM133.87 -2.12992C126.989 4.75111 117.495 9.00002 107 9.00002V17C119.701 17 131.207 11.8469 139.527 3.52694L133.87 -2.12992ZM107 9.00002C96.5055 9.00002 87.011 4.7511 80.1299 -2.12994L74.4731 3.52691C82.7931 11.8469 94.2986 17 107 17V9.00002ZM74.4731 -2.12994L-1.89445 74.2376L3.7624 79.8945L80.1299 3.52691L74.4731 -2.12994ZM-1.89445 79.8945C12.4058 94.1947 29.3826 105.538 48.0668 113.277L51.1282 105.886C33.4147 98.5492 17.3198 87.795 3.7624 74.2376L-1.89445 79.8945ZM48.0668 113.277C66.7509 121.017 86.7764 125 107 125L107 117C87.827 117 68.8418 113.224 51.1282 105.886L48.0668 113.277ZM107 125C127.224 125 147.249 121.017 165.933 113.277L162.872 105.886C145.158 113.224 126.173 117 107 117V125ZM165.933 113.277C184.617 105.538 201.594 94.1947 215.894 79.8944L210.238 74.2376C196.68 87.7949 180.585 98.5492 162.872 105.886L165.933 113.277ZM215.894 74.2376L139.527 -2.12992L133.87 3.52694L210.238 79.8944L215.894 74.2376Z" fill="#070219" mask="url(#path-1-inside-1_1_54)" />
                <path d="M125.36 53.36L107 71.68L88.64 53.36L83 59L107 83L131 59L125.36 53.36Z" fill="white" />
            </svg>
            <svg onClick={pressOK} x="113" y="112" width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="38" cy="38" r="38" fill="white" fillOpacity="0.2" />
                <path d="M30 23V53L54 38L30 23Z" fill="white" />
            </svg>
        </svg>
    )
}

export default Pad;