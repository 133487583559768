/******************/
/* COLOR SETTINGS */
/******************/
export const DARK_COLOR = "#070219";
export const LIGHT_COLOR = "#ffffff";

export const PRIMARY_COLOR = "#ff2453";
export const SECONDARY_COLOR = "#f3ca20";
export const TERTIARY_COLOR = "#20abf3";
export const QUATERNARY_COLOR = "#1bc65f";

export const PALETTE_COLOR = [PRIMARY_COLOR, SECONDARY_COLOR, TERTIARY_COLOR, QUATERNARY_COLOR, "#de28dc", "#e5981e", "#2768c9", "#76b820", "#b86420", "#b8b620", "#279012", "#71a990", "#76b820", "#b86420", "#b8b620", "#279012", "#71a990"];
export const PLAYER_COLOR = PALETTE_COLOR;

const COLOR = {
    DARK: DARK_COLOR,
    LIGHT: LIGHT_COLOR,
    PRIMARY: PRIMARY_COLOR,
    SECONDARY: SECONDARY_COLOR,
    TERTIARY: TERTIARY_COLOR,
    QUATERNARY: QUATERNARY_COLOR,
    PALETTE: PALETTE_COLOR,
    PLAYER: PLAYER_COLOR,
}

export default COLOR;