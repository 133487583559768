import WordList from '../data/words.json'

export const generateRandomWords = (lang = "fr") => {
    const availableWords = WordList[lang];
    let words = [];
    while (words.length < 25) {
        let sample = availableWords[Math.floor(Math.random() * availableWords.length)]
        if (!words.includes(sample)) {
            words.push(sample);
        }
    }
    return words;
}


export const generateRandomColors = () => {
    let baseArray = Array(25).fill("white");
    baseArray.fill("black", 0, 1)
    if (Math.random() < 0.5) {
        baseArray.fill("red", 8, 17)
        baseArray.fill("blue", 17, 26)
        shuffleArray(baseArray);
    }
    else {
        baseArray.fill("blue", 8, 17)
        baseArray.fill("red", 17, 26)
        shuffleArray(baseArray);
    }
    return baseArray;
}

export const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

export const count = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
