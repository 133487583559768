import { Header } from '../../react-unboared';
import StyledAccessPage from './StyledAccessPage'

const LaunchPage = (props) => {
    console.log("[LaunchPage] Rewrite LaunchPage")

    return (
        <StyledAccessPage>
            <div className='elements'>
                <Header className='logo' />
                <h1>
                    Let's play !
                </h1>
                <p>With your phones and a computer (or TV), discover a new way to play with your loved ones with Unboared.
                </p>
                <h2>Welcome back on Unboared</h2>
                <form>
                    <input className="validation-btn submitLaunch" onClick={props.onClick} type="submit" value="Launch"/>
                </form>
            </div>
        </StyledAccessPage>
    )
}

export default LaunchPage;
