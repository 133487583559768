import styled from 'styled-components'

const StyledPlayerIcon = styled.div`
position: relative;
overflow: hidden;
background-color : ${props => props.backgroundColor};
border-radius: 30%;
.playerAvatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80%;
}
.playerName {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}
.additionalPlayers {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size:1.3rem;
}
`

export default StyledPlayerIcon;