import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import { InGameLayout, UnboaredIcon, Header } from "../../";

const StyledContent = styled.div`
    height:100%;
    width:70%;
    font-size:2.5rem;
`

const ChangeOrientation = ({ to }) => {
    const header = (to === 'PORTRAIT' ? <></> : <Header />)
    return (
        <InGameLayout header={header}>
            <StyledContent>
                <UnboaredIcon MaterialIcon={ScreenRotationIcon} size={6} />
                <br />
                <br />
                Change your screen orientation for a better experience.
            </StyledContent>
        </InGameLayout>
    )
}

ChangeOrientation.propTypes = {
    to: PropTypes.string
}

export default ChangeOrientation