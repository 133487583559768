import create from "zustand"
import { useDefaultGame, useDefaultScenario } from "..";

const useGamepadSession = create(
    (set) => ({
        id: 0,
        avatar: 'default.png',
        username: 'Unknown',
        game: useDefaultGame(),
        scenario: useDefaultScenario(),
        statusPlayers: undefined,
        statusConnexion: undefined,
        setStatusPlayers: new_status => set({ statusPlayers: new_status }),
        setStatusConnexion: new_status => set({ statusConnexion: new_status }),
        setUsername: new_username => set({ username: new_username }),
        setAvatar: new_avatar => set({ avatar: new_avatar }),
        setID: new_id => set({ id: new_id }),
        setLocalScenario: (game, scenario) => {
            set({ game: game, scenario: scenario });
        }
    }),
)

export default useGamepadSession;
