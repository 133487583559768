import { useState } from 'react';
import styled from 'styled-components';
import { MuteButton, FavoriteButton, ConnexionButton } from "../../../react-unboared";
import { useListener, useGamepadCom } from "../../../unboared-com";

const Positions = {
    sound: { top: "3rem", left: "0%" },
    love: { top: "0rem", left: "50%" },
    connexion: { top: "3rem", right: "0%" }
};

const SystemActionPosition = styled.div`
    position : absolute;
    top : ${(props) => props.top};
    left : ${(props) => props.left};
    right : ${(props) => props.right};
    transform: translate(${(props) => props.right ? props.right : `-${props.left}`}, -50%);
`

const StyledSystemActionPad = styled.div`
    // background-color:red;
    width:100%;
    height:5vh;
    .relative {
        position: relative;
    }
`

/**
 * The component used to display system actions over the gamepad.
 * This component will be displayed with position based on a circular component 
 * displayed below it. 
 */
const SystemActionPad = () => {
    console.log('[SystemActionPad] Rewrite');

    const sizeIcon = 5;

    const { emitAction } = useGamepadCom();
    const [systemActions, setSystemActions] = useState({
        favorite: false,
        connexionInfos: true,
        volume: 50,
    });

    useListener("system_actions", setSystemActions);

    return (
        <StyledSystemActionPad>
            <div className="relative">
                <SystemActionPosition {...Positions['sound']}>
                    <MuteButton
                        isActive={systemActions.volume === 0} size={sizeIcon}
                        onClick={() => emitAction('pressVolume')}
                    />
                </SystemActionPosition>
                <SystemActionPosition {...Positions['love']}>
                    <FavoriteButton
                        isActive={systemActions.favorite} size={sizeIcon}
                        onClick={() => emitAction('pressFavorite')}
                    />
                </SystemActionPosition>
                <SystemActionPosition {...Positions['connexion']}>
                    <ConnexionButton
                        isActive={systemActions.connexionInfos} size={sizeIcon}
                        onClick={() => emitAction('pressConnexionInfos')}
                    />
                </SystemActionPosition>
            </div>
        </StyledSystemActionPad>
    );
}

export default SystemActionPad;