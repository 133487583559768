import styled from 'styled-components'
import { Flex } from '../../../settings/Mixin';
import { device } from '../../..';

const StyledInGameNavLayout = styled.div`
    ${Flex({ alignItem: "center", justifyContent: "center" })}
    flex-direction:column;
    height: 96vh;
    width: 100vw;
    .header {
        ${Flex({ alignItem: "center", justifyContent: "center" })}
        height: 40%;
        width: 20vw;
        @media ${device.tablet} {
            width: 60vw;
        }
    }
    .content {
        ${Flex({ alignItem: "center", justifyContent: "center" })}
        height: 50%;
        text-align:center;
    }
    .nav {
        ${Flex({ alignItem: "center", justifyContent: "center" })}
        height: 10%;
    }
`


const StyledInGameLayout = styled.div`
    ${Flex({ alignItem: "center", justifyContent: "center" })}
    flex-direction:column;
    height: 96vh;
    width: 100vw;
    .header {
        ${Flex({ alignItem: "center", justifyContent: "center" })}
        height: 40%;
        width: 20vw;
        @media ${device.tablet} {
            width: 60vw;
        }
    }
    .content {
        ${Flex({ alignItem: "center", justifyContent: "center" })}
        height: 60%;
        text-align:center;
    }
`

export { StyledInGameNavLayout, StyledInGameLayout };