import { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import shallow from 'zustand/shallow'
import { useScreenSession } from '../../../unboared-com';
import { useSoundManager, PRIMARY_COLOR, Header, ConnexionButton, User } from '../../../react-unboared';
import Navbar from '../navbar/Navbar';
import SystemActionsWidget from '../system/S_SystemActionsWidget';
import ConnectedPlayersList from '../../../games/catalog/components/Players/ConnectedPlayersList'
import { getUserFromID } from '../../../Tools';
import useSystemActions from '../../../hooks/useSystemActions';


const LayoutUnboaredUI = (props) => {

    const statusPlayers = useScreenSession(state => state.statusPlayers);

    const masterPlayer = getUserFromID(statusPlayers.players, statusPlayers.masterID);
    const { toggleShowConnexionInfos } = useSystemActions();
    const onPlay = useSoundManager((state) => state.onPlay);

    let masterPlayerComponent;
    if (masterPlayer) {
        masterPlayerComponent = <User style={{ position: 'fixed', margin: '2rem', right: '0rem', zIndex: '99' }} user={masterPlayer} isMaster={true} size={4} />;
    }

    useEffect(() => {
        onPlay();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <StyledUnboaredUI contentWidth={85}>
            {(masterPlayerComponent || null)}
            <div className='tools'>
                <Navbar />
                <Link to='/'><Header className='logo' /></Link>
                <ConnexionMsg onClick={toggleShowConnexionInfos}>
                    Click on
                    <ConnexionButton size={2} style={{ margin: '0rem 0.5rem' }} />
                    to add new players.
                </ConnexionMsg>

                <div style={{ margin: '0rem 0rem' }}>
                    <p style={{ fontSize: '1rem', opacity: '0.7' }}>Connected players</p>
                    <ConnectedPlayersList players={statusPlayers.players} masterID={statusPlayers.masterID} maxDisplay={4} size={4} />
                </div>
                <SystemActionsWidget />
            </div>
            <div className='ui'>
                {props.children}
            </div>
        </StyledUnboaredUI>
    );
}


const ConnexionMsg = styled.div`
    margin: 2rem 0rem;
    font-size:1.3rem;
    cursor: pointer;
    &:hover {
        color : ${PRIMARY_COLOR};
    }
`

const StyledUnboaredUI = styled.div`
    // position: absolute;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
    // background-color: blue;
    

    .tools {
        // background-color:grey;
        position: absolute;
        height: 100vh;
        width: ${(props) => (100 - props.contentWidth)}%;
        // background-color:blue;
        padding:2rem;

        .logo {
            width : 90%;
        }

    }
    
    .ui {
        padding-left:2rem;
        position: absolute;
        height: 100vh;
        left: ${(props) => (100 - props.contentWidth)}%;
        width: ${(props) => props.contentWidth}%;
    }

`


export default LayoutUnboaredUI;
