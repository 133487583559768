import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Gamepad from '../gamepad/Gamepad';
import AccessPage from '../gamepad/access/AccessPage';

const GamepadRoutes = () => {
    return (
        <Switch>
            {/* <Route
                path='/gamepad/:roomID/setting'
                component={UserSetting}
            >
            </Route>
            <Route
                path='/gamepad/:roomID/menu'
                component={PauseMenu}
            >
            </Route> */}
            <Route
                exact
                path='/gamepad'
                component={AccessPage}>
            </Route>
            <Route
                path='/gamepad/:roomID'
                component={Gamepad}>
            </Route>
        </Switch>
    );
}

export default GamepadRoutes;