// import axiosClient from "./axiosClient";

export const category = {
        boardgame: 'boardgame',
        videogame: 'videogame'
}

export const boardGameType = {
        popular: 'popular',
        top_rated: 'top_rated',
        new: 'new',
        upcoming: 'upcoming',
}

export const videoGameType = {
        popular: 'popular',
        top_rated: 'top_rated',
        new: 'new',
        upcoming: 'upcoming',
}

const unboaredApi = {
        getBoardGameList: (type, params) => {
                return [{
                                        "id": 8,
                                        "status": 'new',
                                        "title": "Bomber Kitten",
                                        "short": "bomber-kitten",
                                        "category": "Video Game",
                                        "duration": "10 minutes",
                                        "minPlayers": 1,
                                        "maxPlayers": 4,
                                        "overview": "The bomber kittens are back! Collect power-ups to improve your abilities and blow up opponents/enemies to win the game. This Unboared adaptation of Bomberman offers a new gameplay experience.",
                                        "poster_path": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/bomberkitten/CoverSmall.png",
                                        "backdrop_path": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/bomberkitten/Screen.png",
                                },
                                // {
                                //         "id": 5,
                                //         "status": 'enable',
                                //         "title": "Code Names",
                                //         "short": "codenames",
                                //         "category": "Board Game",
                                //         "duration": "20 minutes",
                                //         "minPlayers": 4,
                                //         "maxPlayers": 8,
                                //         "overview": "Two rival spymasters know the secret identities of 25 agents. Their teammates know the agents only by their codenames. To win the game, your team will need to contact all of your agents in the field before the other team finds their own agents. And watch out for the assassin — meet him in the field and your team is done !",
                                //         "poster_path": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/codenames/icon.jpg",
                                //         "backdrop_path": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/codenames/cover.png",
                                // },
                                // {
                                //     "id": 20,
                                //     "available": true,
                                //     "new": true,
                                //     "title": "Unboared",
                                //     "short": "untemplate",
                                //     "category": "Board Game",
                                //     "duration": "X minutes",
                                //     "minPlayers": "0",
                                //     "maxPlayers": "1000",
                                //     "overview": "A template for games developed with Unboared Technology",
                                //     "poster_path": "https://avatars.githubusercontent.com/u/104763840?s=400&u=7429ddbc2030c9e8dacc78a71d7399a00a53e905&v=4",
                                //     "backdrop_path": "https://unboared.com/wp-content/uploads/2022/05/Unboared_Logo-e1651840251997.png",
                                // },
                                {
                                        "id": 1,
                                        "status": 'enable',
                                        "title": "Unblind Test",
                                        "short": "unblind-test",
                                        "category": "Board Game",
                                        "duration": "Less than 10 minutes",
                                        "minPlayers": 1,
                                        "maxPlayers": 10,
                                        "overview": "Get ready for a fiery music quiz... Compete with friends or family to see who is the true master of music! Choose from different playlists, and get ready to answer with your phone. Who will be the best?",
                                        "poster_path": "https://raw.githubusercontent.com/unboared/public-assets/main/images/games/unblind-test/icon.png",
                                }, {
                                        "id": 0,
                                        "status": 'enable',
                                        "title": "Unboared Speed",
                                        "short": "jungle-speed",
                                        "category": "Board Game",
                                        "duration": "Less than 20 minutes",
                                        "minPlayers": 1,
                                        "maxPlayers": 4,
                                        "overview": "Jungle Speed is a game combining speed and observation. Put down a card and jump on the totem pole as soon as two cards are identical before your opponents. But be careful! Beware if the pictograms do not match exactly, the cards are deceptive. Keep your eyes peeled or collect the cards of other players!",
                                        "poster_path": "https://raw.githubusercontent.com/unboared/public-assets/55484265c446c68da890fc7d12f291180427a6a1/images/games/unboared-speed/icon.png",
                                }, {
                                        "id": 10,
                                        "status": 'disable',
                                        "title": "Flappy Race",
                                        "short": "flappy-race",
                                        "category": "Video Game",
                                        "duration": "Less than 10 minutes",
                                        "minPlayers": 1,
                                        "maxPlayers": 6,
                                        "overview": "Welcome to the Flappy Bird replica game for Unboared! The principle is very simple: compete with your friends by making the bird fly between the pipes.",
                                        "poster_path": "https://raw.githubusercontent.com/unboared/public-assets/55484265c446c68da890fc7d12f291180427a6a1/images/games/flappy-race/icon.png",
                                },
                                // {
                                //     "id": 2,
                                //     "status": 'disable',
                                //     "title": "Dixit",
                                //     "short": "dixit",
                                //     "category": "Board Game",
                                //     "duration": "40 minutes",
                                //     "minPlayers": 3,
                                //     "maxPlayers": 8,
                                //     "overview": "In each round, a Storyteller secretly chooses a picture card and associates a word/phrase with it. The other players have to find the Storyteller's card using the riddle !",
                                //     "poster_path": "https://cdn.siter.io/assets/ast_joSGCqso7YWwyyZagjCBq8YJZ/9fd06fd9-1d47-47fb-bd6d-233a08f97485.jpeg",
                                // },
                                {
                                        "id": 3,
                                        "status": 'disable',
                                        "title": "Escape the Ruins",
                                        "short": "escape-ruins",
                                        "category": "Board Game",
                                        "duration": "20 minutes",
                                        "minPlayers": 2,
                                        "maxPlayers": 6,
                                        "overview": "You are walking through the remains of a forbidden Astheque template. All was quiet until a gust of wind blew you and your friends into a cave. A very strange cave... a tomb? ",
                                        "poster_path": "https://cdn.siter.io/assets/ast_5RbaWsJdfEPPMRFvQQh4H8reD/d42a7217-cbac-4c8b-9165-85024910d669.jpg",
                                },
                                {
                                        "id": 4,
                                        "status": 'disable',
                                        "title": "Pikita",
                                        "short": "pikita",
                                        "category": "Board Game",
                                        "duration": "20 minutes",
                                        "minPlayers": 2,
                                        "maxPlayers": 8,
                                        "overview": "A set of simple and fun games.",
                                        "poster_path": "https://cdn.siter.io/assets/ast_DunMFnKHyjjqkJHKyraQz59vo/ffde5af2-bb5e-40a7-8ccb-bba6d9c8f144.png",
                                },
                                {
                                        "id": 5,
                                        "status": 'disable',
                                        "title": "Loups-Garous !",
                                        "short": "loups-garous",
                                        "category": "Board Game",
                                        "duration": "20 minutes",
                                        "minPlayers": 4,
                                        "maxPlayers": 12,
                                        "overview": "In the 'Wild East', the small hamlet of Thiercelieux has recently fallen prey to Werewolves. Murders are committed every night by some of the villagers, who have become Lycanthropes due to a mysterious phenomenon (perhaps the greenhouse effect?)... The villagers must pull together to eradicate this new scourge from the depths of time, before the hamlet loses its last inhabitants.",
                                        "poster_path": "https://cdn.siter.io/assets/ast_5NvYiQYp8YwvxJ3CiUcxSPFmU/270a1e23-d440-47b3-b10a-4ebd684a1259.jpeg",
                                },
                                // {
                                //     "id": 6,
                                //     "status": 'disable',
                                //     "title": "Uno !",
                                //     "short": "uno",
                                //     "category": "Board Game",
                                //     "duration": "20 minutes",
                                //     "minPlayers": 2,
                                //     "maxPlayers": 6,
                                //     "overview": "To win at Uno, you must be the first to get rid of all your cards (in each round to score points).",
                                //     "poster_path": "https://cdn.siter.io/assets/ast_DBfrJfkesYttqLEWu8MEYD6W3/fcdac3f1-ce0a-40c6-92d1-f66f461af3d5.png",
                                // },
                                {
                                        "id": 6,
                                        "status": 'disable',
                                        "title": "Party Challenges",
                                        "short": "party-challenges",
                                        "category": "Board Game",
                                        "duration": "20 minutes",
                                        "minPlayers": 2,
                                        "maxPlayers": 12,
                                        "overview": "A hilarious party game that will take your parties to the next level. In teams or one by one, answer the questions and follow the rules, no matter how wild and crazy they get! Whether you're at the bar or hanging out at home.",
                                        "poster_path": "https://cdn.siter.io/assets/ast_hY5Kn6krPTTeBfitBLNT4hDNm/d91a1e45-8711-4df8-af14-e2e5137caa94.jpg",
                                },
                        ]
                        // const url = 'movie/' + movieType[type];
                        // return axiosClient.get(url, params);
        },
        getVideoGameList: (type, params) => {
                return [{
                                "id": 0,
                                "status": 'disable',
                                "title": "Flappy Race",
                                "category": "Video Game",
                                "duration": "Less than 10 minutes",
                                "minPlayers": 1,
                                "maxPlayers": 6,
                                "overview": "A test in which people are asked to try a product without knowing its name and then say how good or effective they think it is: On a blind test, people couldn't tell the difference between the two drinks.",
                                "poster_path": "https://raw.githubusercontent.com/unboared/public-assets/55484265c446c68da890fc7d12f291180427a6a1/images/games/flappy-race/icon.png",
                        }, ]
                        // const url = 'tv/' + tvType[type];
                        // return axiosClient.get(url, params);
        },
        getVideos: (cate, id) => {
                // const url = category[cate] + '/' + id + '/videos';
                // return axiosClient.get(url, { params: {} });
        },
        search: (cate, params) => {
                console.log("UNDEFINED SEARCH FUNCTION")
                        // const url = 'search/' + category[cate];
                        // return axiosClient.get(url, params);
                return []

        },
        detail: (cate, id, params) => {
                // const url = category[cate] + '/' + id;
                // return axiosClient.get(url, params);
        },
        credits: (cate, id) => {
                // const url = category[cate] + '/' + id + '/credits';
                // return axiosClient.get(url, { params: {} });
        },
        similar: (cate, id) => {
                console.log("UNDEFINED SIMILAR FUNCTION")
                        // const url = category[cate] + '/' + id + '/similar';
                        // return axiosClient.get(url, { params: {} });
                return []
        },
}

export default unboaredApi;