import { useState } from 'react';
import styled from 'styled-components'
import { useScreenScenario, useGameState, useScreenSession, useListener } from '../../../../unboared-com';
import { CenterDiv, UnboaredLoader } from '../../../../react-unboared'

import { GAME_NAME } from '../../config/Const';
import { generateRandomColors, generateRandomWords, count } from '../../config/Tools'
import { RedTeam, BlueTeam } from '../../components/TeamDisplay/TeamDisplay'
import WordGrid from '../../components/WordCard/WordGrid'
import EndGame from '../Endgame/Endgame';

const StyledScreen = styled.div`
    height:100vh;
    width:100vw;
    display:flex;
    padding:0vh 5%;
    
    .left {
        position : relative;
        width : 20vw;
        .content {
            ${CenterDiv()}
        }
    }
    .right {
        position:relative;
        width : 80vw;
        .content {
            ${CenterDiv()}
            width:90%;
        }
    }
`

const initializeGameState = (colors) => {
    if (colors) {
        const doesTeamBlueStart = (count(colors, 'red') < count(colors, 'blue'));
        return {
            currentTeam: (doesTeamBlueStart ? 'blue' : 'red'),
            choiceLeft: 0,
            cardLeftBlue: count(colors, 'blue'),
            cardLeftRed: count(colors, 'red'),
            hintedWord: "",
            winner: undefined,
        }
    }
    return undefined;
}

const generateRandomTeams = (players) => {
    const half = Math.ceil(players.length / 2);

    const firstHalf = players.slice(0, half);
    const secondHalf = players.slice(-half);

    // const firstHinterIndex = Math.floor(Math.random() * firstHalf.length);
    // const secondHinterIndex = Math.floor(Math.random() * secondHalf.length);

    return {
        team1: {
            name: "blue",
            players: firstHalf,
            hinterID: (firstHalf.length > 0 ? firstHalf[0].id : 0),
        },
        team2: {
            name: "red",
            players: secondHalf,
            hinterID: (secondHalf.length > 0 ? secondHalf[0].id : 0),
        }
    }
}

const nextTeam = (current) => {
    return (current === "blue" ? "red" : "blue");
}

const Screen = () => {
    // Update Scenario to category selection
    console.log("[Codenames] Rewrite screen");
    useScreenScenario(GAME_NAME, "game");

    const statusPlayers = useScreenSession(state => state.statusPlayers);

    const initColors = generateRandomColors();
    const initWords = generateRandomWords();
    const initGameState = initializeGameState(initColors);
    const initTeamsInfos = generateRandomTeams(statusPlayers.players);

    const [words, setWords] = useState(initWords);
    const [colors, setColors] = useGameState("grid", initColors);
    const [gameState, setGameState] = useGameState("gameState", initGameState);
    const [teamsInfos, setTeamsInfos] = useGameState("teamsInfos", initTeamsInfos);
    console.log("[Codenames] gameState = " + JSON.stringify(gameState));
    console.log(setWords, setColors, setTeamsInfos);

    useListener('submitHint', (data) => {
        console.log(`[Codenames] Handle action -- submitHint('${data['word']}', ${data['number']})`);
        setGameState((prevState) => {
            const newState = { ...prevState };
            newState.choiceLeft = data['number'];
            return newState;
        });
    });

    useListener("pass", data => {
        console.log(`[Codenames] Handle action -- pass`);
        setGameState((prevState) => {
            const newState = { ...prevState };
            newState.choiceLeft = 0;
            newState.currentTeam = nextTeam(prevState.currentTeam);
            return newState;
        });
    });


    useListener("onReady", data => {
        console.log(`[Codenames] Handle action -- onReady`);
        setColors((prevState) => prevState);
        setGameState((prevState) => prevState);
        setTeamsInfos((prevState) => prevState);
    });


    const updateState = (revealedColor) => {
        setGameState((prevState) => {
            const newState = { ...prevState };
            console.log("oldState=" + JSON.stringify(prevState));

            if (revealedColor === 'blue') {
                newState.cardLeftBlue = prevState.cardLeftBlue - 1;
                newState.choiceLeft = prevState.choiceLeft - 1;
                if (newState.cardLeftBlue === 0) {
                    newState.winner = 'blue';
                }
                else if (prevState.currentTeam === 'red') {
                    newState.choiceLeft = 0;
                }
                if (newState.choiceLeft === 0) {
                    newState.currentTeam = nextTeam(prevState.currentTeam);
                }
            }
            else if (revealedColor === 'red') {
                newState.cardLeftRed = prevState.cardLeftRed - 1;
                newState.choiceLeft = prevState.choiceLeft - 1;
                if (newState.cardLeftRed === 0) {
                    newState.winner = 'red';
                }
                else if (prevState.currentTeam === 'blue') {
                    newState.choiceLeft = 0;
                }
                if (newState.choiceLeft === 0) {
                    newState.currentTeam = nextTeam(prevState.currentTeam);
                }
            }
            else if (revealedColor === 'white') {
                newState.choiceLeft = 0;
                newState.currentTeam = nextTeam(prevState.currentTeam);
            }
            else if (revealedColor === 'black') {
                newState.choiceLeft = 0;
                newState.winner = (prevState.currentTeam === 'blue' ? 'red' : 'blue');
            }
            return newState;
        });
    }

    if (!gameState || !teamsInfos) {
        return <UnboaredLoader />;
    }


    return (
        <StyledScreen>
            {(gameState.winner ? <EndGame team={(gameState.winner === 'blue' ? teamsInfos.team1 : teamsInfos.team2)} /> : null)}
            <div className="left">
                <div className="content">
                    <BlueTeam className='team' cardLeft={gameState.cardLeftBlue} teamPlayer={teamsInfos.team1.players} />
                    <RedTeam className='team' cardLeft={gameState.cardLeftRed} teamPlayer={teamsInfos.team2.players} />
                </div>
            </div>
            <div className="right">
                <div className="content">
                    {!colors ? null : <WordGrid currentTeam={gameState.currentTeam} colors={colors} words={words} callbackState={updateState} />}
                </div>
            </div>
        </StyledScreen>
    );
}


export default Screen;