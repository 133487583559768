import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledAlert = styled.div`
    width : 50vw;
    height : 50vh;
    .header {
        padding: 3em 4em ;
        border-bottom : 3px solid white;
    }
    .details {
        font-size : 1.7em;
        padding: 3em ;
    }
`

const Alert = ({ message, children }) => {
    return (
        <StyledAlert>
            <div className='header'>
                <h1>{message}</h1>
            </div>
            <div className='details'>
                {children}
            </div>

        </StyledAlert>
    )
}

Alert.propTypes = {
    message: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
}

export default Alert