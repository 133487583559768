import styled from 'styled-components';
import { TEAM, BORDER_RADIUS } from '../../config/Const';
import { device } from '../../../../react-unboared';
import DetectiveCard from '../DetectiveCard/DetectiveCard';
import './TeamDisplay.css';

const StyledTeamDisplay = styled.div`
    position:relative;
    width:30vh;
    height:30vh;
    border-radius: ${BORDER_RADIUS};

    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;

    .card {
        display:relative;
        width: 80%;
    }

    .teamName {
        font-size:3rem;
        @media ${device.laptop}{
            font-size:1.6rem;
        }
    }

    .players {
        display:flex;
        flex-wrap: wrap;
        p {
            margin : 0 0.5rem;
            font-size:2.5rem;
            @media ${device.laptop}{
                font-size:1.2rem;
            }
        }
    } 
`

const StyledCardLeft = styled.div`
    text-align: center;
    font-size:6rem;

    @media ${device.laptop}{
        font-size:4rem;
    }

    @media ${device.tablet}{
        font-size : 2rem;   
    }

`

const TeamDisplay = ({ teamName, teamImg, teamColor, teamPlayer, cardLeft, ...props }) => {

    // const transit()

    return (
        <StyledTeamDisplay className={props.className} style={{ backgroundColor: `${teamColor}` }}>
            <DetectiveCard className="card" src={teamImg} />
            <h2 className='teamName'>{teamName}</h2>
            <div className='players'>
                {teamPlayer.map((player, index) =>
                    <p key={index}>{player.username}</p>// <ColorPlayerIcon key={`${teamName}-${index}`} className="icon" avatar={player.avatar} username={player.username} id={player.id} size="3rem" />
                )}
            </div>
        </StyledTeamDisplay>
    );
}

export const TeamDisplayFromConfig = ({ config, teamPlayer, ...props }) => {
    return (
        <TeamDisplay {...props} teamImg={config.card} teamName={config.name} teamColor={config.color} teamPlayer={teamPlayer} />
    );
}

export const RedTeam = ({ teamPlayer, ...props }) => {
    return <>
        <StyledCardLeft id='cardLeft' style={{ color: `${TEAM.RED.color}` }}>
            {props.cardLeft}
        </StyledCardLeft>
        <TeamDisplayFromConfig {...props} config={TEAM.RED} teamPlayer={teamPlayer} />
    </>
}

export const BlueTeam = ({ teamPlayer, ...props }) => {
    return <>
        <TeamDisplayFromConfig {...props} config={TEAM.BLUE} teamPlayer={teamPlayer} />
        <StyledCardLeft id='cardLeft' style={{ color: `${TEAM.BLUE.color}` }}>
            {props.cardLeft}
        </StyledCardLeft>
    </>
}

export default TeamDisplay;