import React, { useEffect, useRef, useState } from "react"
import nipplejs from 'nipplejs'


const Joystick = ({
    size = 300, color,
    onStart, onEnd, onMove,
    onDir, onDirUp, onDirDown, onDirLeft, onDirRight,
    onPlane, onPlaneUp, onPlaneDown, onPlaneLeft, onPlaneRight,
}) => {

    const zoneJoystickRef = useRef(null)
    const [created, setCreated] = useState(false)

    const lockX = false
    const lockY = false

    const options = {
        color: color,
        mode: "static",
        zone: "zone_joystick",
        size: 0.7 * size,

        position: {
            left: "50%",
            top: "50%"
        },
        lockX,
        lockY
    };

    useEffect(() => {
        const handleCreateJoystick = () => {
            if (zoneJoystickRef.current !== null) {
                setCreated(() => {
                    console.log(created)
                    if (!created) {
                        createJoystick(
                            zoneJoystickRef.current,
                            options,
                            onStart, onEnd, onMove,
                            onDir, onDirUp, onDirDown, onDirLeft, onDirRight,
                            onPlane, onPlaneUp, onPlaneDown, onPlaneLeft, onPlaneRight
                        )
                    }
                    return true;
                })
            }
        }
        handleCreateJoystick()
        return () => { setCreated(false) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        created,
        onStart, onEnd, onMove,
        onDir, onDirUp, onDirDown, onDirLeft, onDirRight,
        onPlane, onPlaneUp, onPlaneDown, onPlaneLeft, onPlaneRight
    ])

    return (
        <div ref={zoneJoystickRef} style={{
            position: 'relative',
            overflow: "hidden",
            width: 1 * size,
            height: 1 * size,
        }}>
        </div >
    )
}


const createJoystick = (element, options,
    onStart, onEnd, onMove,
    onDir, onDirUp, onDirDown, onDirLeft, onDirRight,
    onPlane, onPlaneUp, onPlaneDown, onPlaneLeft, onPlaneRight,
) => {
    var joystick = nipplejs.create({
        zone: element,
        color: options.color,
        lockX: options.lockX,
        lockY: options.lockY,
        mode: options.mode,
        size: options.size,
        position: {
            left: options.position.left,
            top: options.position.top
        }
    });

    joystick.on('start', function (evt, data) {
        onStart(evt, data)
    })
    joystick.on('end', function (evt, data) {
        onEnd(evt, data)
    })
    joystick.on('move', function (evt, data) {
        onMove(evt, data)
    })
    joystick.on('dir', function (evt, data) {
        onDir(evt, data)
    })
    joystick.on('dir:up', function (evt, data) {
        onDirUp(evt, data)
    })
    joystick.on('dir:down', function (evt, data) {
        onDirDown(evt, data)
    })
    joystick.on('dir:left', function (evt, data) {
        onDirLeft(evt, data)
    })
    joystick.on('dir:right', function (evt, data) {
        onDirRight(evt, data)
    })
    joystick.on('plain', function (evt, data) {
        onPlane(evt, data)
    })
    joystick.on('plain:up', function (evt, data) {
        onPlaneUp(evt, data)
    })
    joystick.on('plain:down', function (evt, data) {
        onPlaneDown(evt, data)
    })
    joystick.on('plain:left', function (evt, data) {
        onPlaneLeft(evt, data)
    })
    joystick.on('plain:right', function (evt, data) {
        onPlaneRight(evt, data)
    })

    return joystick;
}

export default Joystick;