import { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
// import SettingsIcon from '@mui/icons-material/Settings';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';

import { useGamepadCom } from "../../../unboared-com";
import { InGameNavLayout, CenteredFlexBox, NavButtonGamepad, UnboaredIcon, useWindowOrientation, ChangeOrientation } from "../../../react-unboared";

import Pad from "../components/Pad/Pad";
// import styles from "./Gamepad.module.scss"
import SystemActionsPad from "../../../screen/components/system/G_SystemActionsPad";

const NavbarUnboaredUI = [
    {
        short: 'games',
        title: "Games",
        icon: <UnboaredIcon MaterialIcon={VideogameAssetIcon} size={6} />,
        path: '/',
        action: 'pressUnboaredGames'
    },
    {
        short: 'search',
        title: "Search",
        icon: <UnboaredIcon MaterialIcon={SearchIcon} size={6} />,
        path: '/boardgame',
        action: 'pressUnboaredSearch'
    },
    // {
    //     short: 'settings',
    //     title: "Settings",
    //     icon: <UnboaredIcon MaterialIcon={SettingsIcon} size={3} />,
    //     path: '/settings',
    //     action: 'pressUnboaredSettings'
    // }
];

const Gamepad = () => {
    const { emitAction } = useGamepadCom()

    const [active, setActive] = useState("games");
    const windowOrientation = useWindowOrientation();
    if (windowOrientation === 'LANDSCAPE') {
        return <ChangeOrientation to='PORTRAIT' />
    }

    const access = (navItem) => {
        setActive(navItem.short);
        emitAction(navItem.action);
    }

    const nav_items = [];
    var rank = 0;

    for (const navItem of NavbarUnboaredUI) {
        nav_items.push(<NavButtonGamepad
            key={rank}
            isActive={active === navItem.short}
            title={navItem.title}
            icon={navItem.icon}
            onClick={() => access(navItem)} />
        );
        rank += 1;
    }

    const nav = (<CenteredFlexBox>
        {nav_items}
    </CenteredFlexBox>);

    return (
        <InGameNavLayout header={<></>} nav={nav}>
            <div style={{ display: "flex", flexDirection: 'column' }}>
                <SystemActionsPad />
                <Pad
                    pressOK={() => emitAction('ok')}
                    pressUp={() => emitAction('up')}
                    pressDown={() => emitAction('down')}
                    pressLeft={() => emitAction('left')}
                    pressRight={() => emitAction('right')}
                />
            </div>
        </InGameNavLayout>
    )
}

export default Gamepad;

