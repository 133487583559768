import styled from 'styled-components';

const StyledErrorMessage = styled.div`
    font-size : 2rem;
    font-weight : 700;
    color: ${props => props.color};
    border-radius : ${({ theme }) => theme.BORDER_RADIUS};
`

export const ErrorMessage = ({ txt, color = "red" }) => {
    return <StyledErrorMessage color={color}>
        {txt}
    </StyledErrorMessage>
}
