import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

import SearchIcon from '@mui/icons-material/Search';
// import SettingsIcon from '@mui/icons-material/Settings';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import { UnboaredIcon, Flex, device } from '../../../react-unboared';
import { useListener } from '../../../unboared-com';

export const StyledNavButton = styled.button`
    padding: 0.7rem;
    font-size: 1.5rem;
    position: relative;
    border-radius: ${({ theme }) => theme.BORDER_RADIUS};
    display:flex;
    align-items: center;
    width:100%;
    * {
        margin-right: 0.8rem;
        margin-left: 0.2rem;
    }
    &::after {
        color: ${({ theme }) => theme.PRIMARY_TEXT_COLOR};
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        transition: width 0.5s ease;
        height: 2px;
        background-color: ${({ theme }) => theme.SECONDARY_TEXT_COLOR};
    }
    &:hover::after {
        width: 100%;
    }
    &.active {
        background-color: ${({ theme }) => theme.SECONDARY_TEXT_COLOR};
        &:hover::after {
            width: 0;
        }
        transition: background-color 500ms ease;
    }
`


const headerNav = [
    {
        icon: <UnboaredIcon MaterialIcon={VideogameAssetIcon} size={2.5} />,
        title: "Games",
        path: '/'
    },
    {
        icon: <UnboaredIcon MaterialIcon={SearchIcon} size={2.5} />,
        title: "Search",
        path: '/boardgame'
    },
    // {
    //     icon: <UnboaredIcon MaterialIcon={SettingsIcon} size={1.8} />,
    //     title: "Settings",
    //     path: '/settings'
    // }
];


const Navbar = () => {
    console.log("[UnboaredUI::Navbar] REWRITE Navbar");
    /* Get active component */
    const { pathname } = useLocation();

    const history = useHistory();
    const goTo = (path) => {
        history.push(path);
    }

    const active = headerNav.findIndex(e => e.path === pathname);

    useListener("pressUnboaredGames", () => { goTo("/") });
    useListener("pressUnboaredSearch", () => { goTo("/boardgame") });
    useListener("pressUnboaredSettings", () => { goTo("/settings") });

    return (
        <StyledNavbar>
            {
                headerNav.map((e, i) => (
                    <StyledNavButton key={i} onClick={() => { goTo(e.path) }} className={i === active ? "active" : ''}>
                        {e.icon}
                        {e.title}
                    </StyledNavButton>
                ))
            }
        </StyledNavbar>
    );
}


const StyledNavbar = styled.div`        
    position : fixed;
    ${Flex({ alignItem: "center", justifyContent: "unset" })}
    bottom:5%;
    left:50%;
    transform: translate(-50%, 0%);
    z-index: 99;
    background-color:${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
    padding : 0.8rem;
    border-radius: ${({ theme }) => theme.BORDER_RADIUS};
    box-shadow: 0px 0px 0.4rem 0.01rem ${({ theme }) => theme.PRIMARY_TEXT_COLOR};
    &>*~* {
        margin-left: 2rem;
        color: ${({ theme }) => theme.PRIMARY_TEXT_COLOR};
    }
    
    // @media ${device.tablet} {
    //     position: fixed;
    //     bottom: 0;
    //     left: 0;
    //     height: 5rem;
    //     width: 100%;
    //     transform: translate(0%, 0%);
    //     background-color: ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
    //     padding: 0 2rem;
    //     box-shadow: ${({ theme }) => theme.PRIMARY_TEXT_COLOR}80 0px 7px 29px 0px;
    //     ${Flex({ alignItem: "center", justifyContent: "space-between" })}
    //     &>*~* {
    //         margin-left: 0;
    //     }
    // }
`

export default Navbar;