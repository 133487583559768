import StyledTableResults from './StyledTableResults.jsx'

let sortPlayers = (scores, players) => {
    let sortedPlayers = [...players];
    sortedPlayers.sort(function (a, b) { return scores[a.id] > scores[b.id] ? -1 : scores[a.id] < scores[b.id] ? 1 : 0; });
    return sortedPlayers;
}

const Result = ({ position, username, score }) => {
    return (
        <tr>
            <th className="playerResult" scope="row">{position}</th>
            <td>{username}</td>
            <td>{score}</td>
        </tr>
    );
}

const TableResults = ({ scores, players }) => {
    // Get the number of players
    const nPlayers = scores.length;
    const show_n_results = (nPlayers < 10) ? nPlayers : 10;

    var rank = 0;
    // Add Result for each player in the descendant order of scores.
    const results_display = [];
    for (const player of sortPlayers(scores, players).slice(0, show_n_results)) {
        results_display.push(<Result key={rank} username={player.username} score={scores[player.id]} position={rank + 1} />)
        rank += 1;
    }

    return (<StyledTableResults>
        <thead>
            <tr>
                <th scope="col">Rank</th>
                <th scope="col">Name</th>
                <th scope="col">Points</th>
            </tr>
        </thead>
        <tbody>
            {results_display}
        </tbody>
    </StyledTableResults>);
}

export default TableResults;