import React from 'react'
import PropTypes from 'prop-types'
import Alert from '.'
import { ConnexionButton } from '../../../react-unboared'

const msgStyle = {
    marginBottom: '1em',
    
}

const NotEnoughPlayersAlert = ({ game, numPlayers, minPlayers }) => {
    const headerMessage = "✋ Not enough players.";

    return (
        <Alert message={headerMessage}>
            <>
                <p style={msgStyle}>To be able to play <span style={{ fontWeight: '1000' }}>{game}</span> you need to be  <span className='important'>{minPlayers} players</span>.</p>
                <p style={msgStyle}>Add more players by clicking on <ConnexionButton size={3} style={{ margin: '0rem 0.5rem' }} /> on your phone.</p>
            </>
        </Alert>
    )
}

NotEnoughPlayersAlert.propTypes = {
    game: PropTypes.string.isRequired,
    numPlayers: PropTypes.number,
    minPlayers: PropTypes.number.isRequired,
}

export default NotEnoughPlayersAlert
