import styled from 'styled-components';
import { device, BackgroundCover } from '../../react-unboared';
import bgImg from '../../assets/footer-bg.png';


const StyledAccessPage = styled.div`
    ${BackgroundCover(bgImg)}
    position: absolute;
    top:0;
    left:0;
    width:100vw;
    height:100vh;

    .elements {
        position: absolute;
        top:50vh;
        left:0;
        transform : translate(0%, -50%);
        margin-left:8%;
        width:30%;
        @media ${device.tablet} {
            margin-left:5%;
            width:80%;
        }

        .logo {
            width:30%;
        }

        > * {
            margin-bottom: 1rem;
        }

        h1 {
            line-height: 1em;
            font-size : 3.5rem;
            overflow: hidden;
        }

        p {
            color: #C5C5C5
        }

        .discordBtn {
            border: 0.2rem solid #7289da;
            .discordButtonContent {
                width: auto;
                height: auto;
                padding: 0rem 1rem;
                font-weight : 500;
                img {
                    height:3rem;
                }
            }
        }

        .submitAccessCode {
            border-radius : 0 ${({ theme }) => theme.BORDER_RADIUS} ${({ theme }) => theme.BORDER_RADIUS} 0;
        }
        
        .submitLaunch {
            font-size:1.5rem;
            font-weight:700;
            // width : 25%;
        }

        .input {
            font-size:1.31rem;
            border-radius: ${({ theme }) => theme.BORDER_RADIUS} 0  0  ${({ theme }) => theme.BORDER_RADIUS};
            vertical-align: middle;
            padding: 1rem 1.5rem;
            width : 50%;

        }

        .error {
            color : red;
            font-size: 1.3rem;
            margin-bottom:1rem;
            a {
                color: #7289da;
            }
        }
}
`
export default StyledAccessPage;