import React, { useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types'
import useSound from 'use-sound';

import ValidateSound from '../../react-unboared/assets/sounds/Validate.mp3';

import { useScreenSession, useListener } from '../../unboared-com';
import { OverlayView } from '../../react-unboared';

import NotEnoughPlayersAlert from '../components/Alert/NotEnoughPlayersAlert';
import NotAvailableAlert from '../components/Alert/NotAvailableAlert';

const ValidateComponent = ({ currentGame }) => {
    const history = useHistory();
    const statusPlayers = useScreenSession((state) => state.statusPlayers);
    const [alert, setAlert] = useState(undefined);

    // Sound 
    const [playValidateSound] = useSound(ValidateSound);

    const checkAvailable = (game) => {
        let isAvailable = (game.status === 'new' || game.status === 'enable');
        if (!isAvailable) {
            setAlert(<NotAvailableAlert game={game.title} />);
            setTimeout(() => {
                setAlert(undefined);
            }, 3000);
        }
        return (isAvailable);
    }

    const checkNumPlayers = (game, numPlayers) => {
        if (game.minPlayers > numPlayers) {
            setAlert(<NotEnoughPlayersAlert game={game.title} numPlayers={numPlayers} minPlayers={game.minPlayers} />);
            setTimeout(() => {
                setAlert(undefined);
            }, 3000);
        }
        return (game.minPlayers <= numPlayers);
    }

    useListener("ok", () => {
        const validate = (statusPlayers, currentGame) => {
            if (checkAvailable(currentGame) && checkNumPlayers(currentGame, statusPlayers.players.length)) {
                playValidateSound();
                history.push(`/game/${currentGame.short}`);
            }
        }
        validate(statusPlayers, currentGame);
    }, [statusPlayers, currentGame])

    return (
        <>
            {alert && (
                <OverlayView>
                    {alert}
                </OverlayView>
            )}
        </>
    )
}

ValidateComponent.propTypes = {
    currentGame: PropTypes.shape({
        status: PropTypes.string.isRequired,
        short: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        minPlayers: PropTypes.number.isRequired,
        maxPlayers: PropTypes.number.isRequired,
    })
}

export default ValidateComponent