import styled from 'styled-components'
import NavButton from "./NavButton";
import { PRIMARY_COLOR } from '../..';

const StyledNavButtonGamepad = styled.div`
margin: auto;
display: flex;
flex-direction: column;
align-items: center;
h2 {
    margin-bottom: 0.5rem;
    ${(props) => (`color:${props.color};` || "")};
}
.shortcutIcon {
    ${(props) => (`color:${props.color};` || "")};
    ${(props) => (`background-color:${props.backgroundColor};` || "")};
}
.active {
    background-color:${(props) => (props.activeColor || PRIMARY_COLOR)};
}
`

const NavButtonGamepad = ({ title, icon, isActive, onClick, ...props }) => {
    return (
        <StyledNavButtonGamepad color={props.color} backgroundColor={props.backgroundColor} activeColor={props.activeColor}>
            <h2>{title}</h2>
            <NavButton className={"shortcutIcon " + (isActive ? "active" : "")} onClick={onClick}>
                {icon}
            </NavButton>
        </StyledNavButtonGamepad>
    );
}

export default NavButtonGamepad;