import styled from 'styled-components'
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../components/authentification/UserAuthentification.module.scss'
import { Header, InGameLayout } from '../../react-unboared'

const StyledAccessPage = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding:4rem;
    .instruction {
        margin-bottom: 1rem;
        font-size: 2.2rem;
        font-weight: 800;
        .instruction-title {
            font-size: 2.4rem;
        }
    }
    .option {
        margin:0rem 1rem;
        margin-bottom: 1rem;
        font-weight: 500;
        font-size: 2.1rem;
    }

    .submitAccessCode {
        border-radius : 0 ${({ theme }) => theme.BORDER_RADIUS} ${({ theme }) => theme.BORDER_RADIUS} 0;
    }
    
    .submitLaunch {
        font-size:2.2rem;
        font-weight:700;
        // width : 25%;
    }

    .input {
        font-size:2.01rem;
        border-radius: ${({ theme }) => theme.BORDER_RADIUS} 0  0  ${({ theme }) => theme.BORDER_RADIUS};
        vertical-align: middle;
        padding: 1rem 1.5rem;
        width : 50%;

    }

    .error {
        color : red;
        font-size: 1.3rem;
        margin-bottom:1rem;
        a {
            color: #7289da;
        }
    }
    form {
        margin:auto;
        padding : 1rem 0rem;
    }
`

const AccessPage = () => {
    console.log("[AccessPage] Rewrite AccessPage")
    let history = useHistory()
    const [code, setCode] = useState("")

    const handleChange = (event) => {
        setCode(event.target.value);
    }

    const handleSubmit = (event) => {
        console.log(`[System] Join room n°${code}`);
        event.preventDefault();
        history.replace(`/gamepad/${code}`);
    }

    return (
        <InGameLayout className={styles.container} header={<Header />}>
            <StyledAccessPage>
                <div className='instructions'>
                    <p className='instruction'><span className='instruction-title'>1.</span> Connecte toi à <i className='important'>play.unboared.com</i> sur ton ordi.</p>
                    <p className='instruction'><span className='instruction-title'>2.</span>  Rentre ton code Beta Testeur reçu par mail ou sur Discord.</p>
                    <p className='instruction'><span className='instruction-title'>3.</span>  Pour appairer ton téléphone:</p>
                    <p className='option'> <span className='important'>Option 1</span> : scan le QR code avec l'appareil photo de ton téléphone</p>
                    <p className='option'> <span className='important'>Option 2</span> : écris ci-dessous le code numérique présent à l'écran</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <input className='input' type="text" minLength="6" maxLength="6" placeholder='Session code' value={code} onChange={handleChange} required />
                    <input className={`validation-btn submitAccessCode submitLaunch`} type="submit" value="Join game" />
                </form>
            </StyledAccessPage>
        </InGameLayout>
    )
}

export default AccessPage;
