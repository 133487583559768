import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

import { useGamepadSession, useGamepadCom, useListener, useGamepadLS } from '../unboared-com';
import { PRIMARY_COLOR, UnboaredLoader } from '../react-unboared'

import GamepadComponent from '../config/GamepadComponent';
import { UserAuthentification } from './components/authentification/UserAuthentification'
import ProfilNav from './components/nav/ProfilNav';
import UserSelection from './components/authentification/UserSelection';
import { toggleFullscreen } from '../Tools';
import GLayoutGame from './components/layouts/GLayoutGame';

const GamepadUI = ({ roomID }) => {
    console.log("[GamepadUI] Write GamepadUI App")

    const { statusConnexion, statusPlayers, setStatusConnexion, setStatusPlayers } = useGamepadSession();
    const { username, avatar, id, game, setLocalScenario, setID } = useGamepadSession();
    const { join } = useGamepadCom(roomID, username, id);

    useEffect(() => {
        join(roomID, username, avatar)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useListener("changeRoomID", changeRoomID)
    useListener("changeScenario", (data) => {
        console.log("Change Scenario : " + data['game'] + " - " + data['scenario'])
        setLocalScenario(data['game'], data['scenario'])
    })

    useListener("changeID", (data) => {
        setID(data['id'])
    })

    useListener("statusPlayers", setStatusPlayers);
    useListener("statusConnexion", setStatusConnexion);

    const CurrentGamepad = GamepadComponent[game];

    if (!statusConnexion || !statusPlayers) {
        return <UnboaredLoader>
            <div style={{display:'flex', flexDirection:'column', padding:'2rem', textAlign:'center'}}>
                <h2  style={{marginBottom:'1rem', color:`${PRIMARY_COLOR}`}}>If the page takes too long to load, it may mean that :</h2>
                <h2>1. your network connexion is bad</h2>
                <h2>2. no session corresponds to the code you have entered</h2>
            </div>
        </UnboaredLoader>
    }

    if (game === "catalog") {
        return (
            <>
                <ProfilNav />
                {CurrentGamepad}
            </>
        )
    }
    return (
        <GLayoutGame>
            {CurrentGamepad}
        </GLayoutGame>
    );
}

const Gamepad = () => {
    console.log("[Gamepad] Write Gamepad App")
    const { roomID } = useParams();
    const [launched, setLaunched] = useState(false);
    const { users } = useGamepadLS();

    // setUsers([]);

    const launchUI = () => {
        setLaunched(true);
        toggleFullscreen();
    }

    if (users.length === 0) {
        return <UserAuthentification onSubmitted={launchUI} />
    }
    if (!launched) {
        return <UserSelection onSelected={launchUI} users={users} />;
    }
    return <GamepadUI roomID={roomID} />
}

export default Gamepad;
