import { ERROR_DISC_COMPUTER_LEVEL1, ERROR_DISC_COMPUTER_LEVEL3, DISC_SIZE, ARROWDOWN_KEY, ARROWRIGHT_KEY, Z_KEY, X_KEY } from "../config/Const"
import { deck } from "./deck"
import PlayerComputer from "./playerComputer"
import PlayerHuman from "./playerHuman"
import Upground from "./upground"
import Message from "./message"

const bot1 = {
    username: 'Figue',
    color: '#ffffff'
}

const bot2 = {
    username: 'Melon',
    color: '#ffffff'
}

const bot3 = {
    username: 'Courgette',
    color: '#ffffff'
}


const bot4 = {
    username: 'Framboise',
    color: '#ffffff'
}

class IronSpeed {
    constructor(ctx, humanPlayers, responseTimeComputerLevel, errorDiscComputerLevel, maxWidth, maxHeight, callbackEnd) {
        this.ctx = ctx
        this.deck = deck
        this.spaceToBorderY = 0.
        this.spaceToBorderX = 0.

        this.callbackEnd = callbackEnd;
        this.player_pos = [
            { 'x': maxWidth * this.spaceToBorderX, 'y': maxHeight * this.spaceToBorderY - DISC_SIZE / 2 },
            { 'x': maxWidth * this.spaceToBorderX, 'y': maxHeight * (1 - this.spaceToBorderY) - DISC_SIZE / 2 },
            { 'x': maxWidth * (1. - this.spaceToBorderX) - DISC_SIZE, 'y': maxHeight * (1 - this.spaceToBorderY) - DISC_SIZE / 2 },
            { 'x': maxWidth * (1. - this.spaceToBorderX) - DISC_SIZE, 'y': maxHeight * this.spaceToBorderY - DISC_SIZE / 2 },
        ];
        if (humanPlayers.length === 0) {
            this.player1 = new PlayerComputer(1, bot1, this.player_pos[0]['x'], 1, this.player_pos[0]['y'], 1, this, responseTimeComputerLevel, ERROR_DISC_COMPUTER_LEVEL1, maxWidth, maxHeight)
            // this.player1 = new PlayerHuman(1, this.player_pos[0]['x'], 1, this.player_pos[0]['y'], 1, this, ARROWDOWN_KEY, ARROWRIGHT_KEY, maxWidth, maxHeight)
            this.player2 = new PlayerComputer(2, bot2, this.player_pos[1]['x'], 1, this.player_pos[1]['y'], -1, this, responseTimeComputerLevel, ERROR_DISC_COMPUTER_LEVEL1, maxWidth, maxHeight)
            this.player3 = new PlayerComputer(3, bot3, this.player_pos[2]['x'], -1, this.player_pos[2]['y'], -1, this, responseTimeComputerLevel, ERROR_DISC_COMPUTER_LEVEL1, maxWidth, maxHeight)
            this.player4 = new PlayerComputer(4, bot4, this.player_pos[3]['x'], -1, this.player_pos[3]['y'], 1, this, responseTimeComputerLevel, ERROR_DISC_COMPUTER_LEVEL3, maxWidth, maxHeight)
        }
        else if (humanPlayers.length === 1) {
            this.player1 = new PlayerHuman(1, humanPlayers[0], this.player_pos[0]['x'], 1, this.player_pos[0]['y'], 1, this, ARROWDOWN_KEY, ARROWRIGHT_KEY, maxWidth, maxHeight)
            this.player2 = new PlayerComputer(2, bot2, this.player_pos[1]['x'], 1, this.player_pos[1]['y'], -1, this, responseTimeComputerLevel, errorDiscComputerLevel, maxWidth, maxHeight)
            this.player3 = new PlayerComputer(3, bot3, this.player_pos[2]['x'], -1, this.player_pos[2]['y'], -1, this, responseTimeComputerLevel, errorDiscComputerLevel, maxWidth, maxHeight)
            this.player4 = new PlayerComputer(4, bot4, this.player_pos[3]['x'], -1, this.player_pos[3]['y'], 1, this, responseTimeComputerLevel, errorDiscComputerLevel, maxWidth, maxHeight)
        } else if (humanPlayers.length === 2) {
            this.player1 = new PlayerHuman(1, humanPlayers[0], this.player_pos[0]['x'], 1, this.player_pos[0]['y'], 1, this, ARROWDOWN_KEY, ARROWRIGHT_KEY, maxWidth, maxHeight)
            this.player2 = new PlayerHuman(2, humanPlayers[1], this.player_pos[1]['x'], 1, this.player_pos[1]['y'], -1, this, Z_KEY, X_KEY, maxWidth, maxHeight)
            this.player3 = new PlayerComputer(3, bot3, this.player_pos[2]['x'], -1, this.player_pos[2]['y'], -1, this, responseTimeComputerLevel, errorDiscComputerLevel, maxWidth, maxHeight)
            this.player4 = new PlayerComputer(4, bot4, this.player_pos[3]['x'], -1, this.player_pos[3]['y'], 1, this, responseTimeComputerLevel, errorDiscComputerLevel, maxWidth, maxHeight)
        } else if (humanPlayers.length === 3) {
            this.player1 = new PlayerHuman(1, humanPlayers[0], this.player_pos[0]['x'], 1, this.player_pos[0]['y'], 1, this, ARROWDOWN_KEY, ARROWRIGHT_KEY, maxWidth, maxHeight)
            this.player2 = new PlayerHuman(2, humanPlayers[1], this.player_pos[1]['x'], 1, this.player_pos[1]['y'], -1, this, Z_KEY, X_KEY, maxWidth, maxHeight)
            this.player3 = new PlayerHuman(3, humanPlayers[2], this.player_pos[2]['x'], -1, this.player_pos[2]['y'], -1, this, ARROWDOWN_KEY, ARROWRIGHT_KEY, maxWidth, maxHeight)
            this.player4 = new PlayerComputer(4, bot4, this.player_pos[3]['x'], -1, this.player_pos[3]['y'], 1, this, responseTimeComputerLevel, errorDiscComputerLevel, maxWidth, maxHeight)
        } else {
            this.player1 = new PlayerHuman(1, humanPlayers[0], this.player_pos[0]['x'], 1, this.player_pos[0]['y'], 1, this, ARROWDOWN_KEY, ARROWRIGHT_KEY, maxWidth, maxHeight)
            this.player2 = new PlayerHuman(2, humanPlayers[1], this.player_pos[1]['x'], 1, this.player_pos[1]['y'], -1, this, Z_KEY, X_KEY, maxWidth, maxHeight)
            this.player3 = new PlayerHuman(3, humanPlayers[2], this.player_pos[2]['x'], -1, this.player_pos[2]['y'], -1, this, ARROWDOWN_KEY, ARROWRIGHT_KEY, maxWidth, maxHeight)
            this.player4 = new PlayerHuman(4, humanPlayers[3], this.player_pos[3]['x'], -1, this.player_pos[3]['y'], 1, this, Z_KEY, X_KEY, maxWidth, maxHeight)
        }
        this.players = [this.player1, this.player2, this.player3, this.player4]
        this.turn = 0
        this.orderDiscs = []
        this.propCheck = 'form'
        this.upground = new Upground(ctx, this)
        this.timeouts = []
        this.stack_messages = []
    }

    setStartTurn() {
        this.turn = 1
        this._enableActivePlayer(1)
        this.playCardNextComputerPlayer()
    }

    _updateAllCurrentsCards() {
        for (let i = 0; i < this.players.length; i++) {
            if (this.players[i].centerCards.length === 0) {
                document.getElementById(`cardCenter${this.players[i].id}`).style.background = require('../assets/img/CardMark.png')
                document.getElementById(`cardCenter${this.players[i].id}`).style.backgroundSize = 'cover'
            } else if (this.players[i].centerCards.length > 0) {
                this.players[i].updateCurrentCard()
            }
        }
    }

    _updateAllCountersCards() {
        for (let i = 0; i < this.players.length; i++) {
            this.players[i].updateCounterCards()
        }
    }

    dealCards() {
        let tmpDeck = this.deck.map(a => ({ ...a }));
        while (tmpDeck.length > 0) {
            for (let i = 0; i < this.players.length; i++) {
                let n = Math.floor(Math.random() * (tmpDeck.length))
                this.players[i].playerCards.push(tmpDeck[n])
                tmpDeck.splice(n, 1)
            }
        }
        this._updateAllCurrentsCards()
        this._updateAllCountersCards()
    }

    checkAllCardsInCenter() {
        for (let i = 0; i < this.players.length; i++) {
            if (this.players[i].playerCards.length > 0) {
                return
            }
        }
        console.log(`All the cards are in the center. Each player pick up their cards. PLAYER1 start again.`)
        let messageAllCenter = this.createMessage(`All cards in the center`, 2000)
        messageAllCenter.showMessage()
        let messageRestart = this.createMessage(`${this.players[0].username} start again`, 1500)
        this.callLater(() => { messageRestart.showMessage() }, 2000)
        for (let i = 0; i < this.players.length; i++) {
            this._addCardsLoser(i + 1, i + 1)
        }
        this.callLater(() => {
            this._updateAllCurrentsCards()
            this._updateAllCountersCards()
        }, 1000)
    }

    updatePropCheck() {
        let playersCheck = []
        for (let i = 0; i < this.players.length; i++) {
            if (this.players[i].centerCards.length > 0) {
                playersCheck.push(this.players[i])
            }
        }
        let possibleColor = false
        for (let i = 0; i < playersCheck.length; i++) {
            if (playersCheck[i].centerCards[0].type === 'disc') {
                this.turn = 'discTurn'
                return
            } else if (playersCheck[i].centerCards[0].type === 'color') {
                this.propCheck = 'color'
                possibleColor = true
            } else if (playersCheck[i].centerCards[0].type === 'normal' && possibleColor === true) {
                continue
            } else {
                this.propCheck = 'form'
            }
        }
    }

    _checkNextTurn() {
        if (this.players[this.turn - 1].playerCards.length === 0) {
            this.incrementTurn()
        }
    }

    _disableActivePlayer() {
        var activePlayers = document.getElementsByClassName("activePlayer");
        for (var i = 0; i < activePlayers.length; i++) {
            activePlayers[i].classList.remove("activePlayer");
        }
    }

    _enableActivePlayer(id) {
        document.getElementById(`player${id}`).className += " activePlayer";
    }

    _changeTurn(newID) {
        this._disableActivePlayer()
        this.turn = newID
        this._enableActivePlayer(this.turn)
    }

    incrementTurn() {

        if (this.turn === this.players.length) {
            this._changeTurn(1)
        } else {
            this._changeTurn(this.turn + 1)
        }
        this._checkNextTurn()
    }

    playDiscsComputersPlayers() {
        for (let i = 0; i < this.players.length; i++) {
            if (this.players[i].typePlayer === 'computer') {
                this.players[i].checkThrowDisc()
            }
        }
    }

    playCardNextComputerPlayer() {
        if (this.players[this.turn - 1].typePlayer && this.players[this.turn - 1].typePlayer === 'computer') {
            this.players[this.turn - 1].throwCard()
        }
    }

    _addSingleCardLoser(idLoser, idWinner) {
        let addCard = this.players[idWinner - 1].centerCards.pop()
        this.players[idLoser - 1].playerCards.unshift(addCard)
    }


    _addCardsLoser(idLoser, idWinner) {
        for (let i = this.players[idWinner - 1].centerCards.length; i > 0; i--) {
            this._addSingleCardLoser(idLoser, idWinner)
        }
    }

    _checkCards(id, prop) {
        let checkPlayer = this.players[id - 1]
        if (checkPlayer.centerCards.length === 0) {
            for (let i = 0; i < this.players.length; i++) {
                this._addCardsLoser(id, i + 1)
            }
            console.log(`mistake ${checkPlayer.username}, loses the duel`)
            let messageMistake = this.createMessage(`mistake ${checkPlayer.username}`, 2000)
            messageMistake.showMessage()
            this._changeTurn(id)
            return
        }
        let losersPlayers = []
        for (let i = 0; i < this.players.length; i++) {
            if (checkPlayer.id !== this.players[i].id) {
                if ((this.players[i].centerCards.length > 0) && (checkPlayer.centerCards[0][prop] === this.players[i].centerCards[0][prop])) {
                    losersPlayers.push(this.players[i].id)
                }
            }
        }
        /// checkPlayer loses ///
        if (losersPlayers.length === 0) {
            for (let i = 0; i < this.players.length; i++) {
                this._addCardsLoser(id, i + 1)
            }
            console.log(`mistake ${checkPlayer.username}, loses the duel`)
            let messageMistake = this.createMessage(`mistake ${checkPlayer.username}`, 2000)
            messageMistake.showMessage()
            this._changeTurn(id)
            return
        }
        /// checkPlayer wins ///
        else if (losersPlayers.length > 0) {
            let currentPlayer = id
            const _nextLoserPlayer = () => {
                if (currentPlayer >= this.players.length) {
                    currentPlayer = 1
                } else {
                    currentPlayer++
                }
            }
            while (checkPlayer.centerCards.length > 0) {
                if (losersPlayers.includes(currentPlayer)) {
                    this._addSingleCardLoser(currentPlayer, id)
                }
                _nextLoserPlayer()
            }
            for (let i = 0; i < losersPlayers.length; i++) {
                let j = losersPlayers[i]
                this._addCardsLoser(j, j)
            }
            console.log(`${prop} duel | ${checkPlayer.username} vs PLAYERS:${losersPlayers} | ${checkPlayer.username} wins the ${prop} duel`)
            let messageWin = this.createMessage(`${checkPlayer.username} wins the ${prop} duel`, 2000)
            messageWin.showMessage()
            let nextTurn = id
            for (let i = 0; i <= this.players.length; i++) {
                if (nextTurn >= this.players.length) {
                    nextTurn = 1
                } else {
                    nextTurn++
                }
                if (losersPlayers.includes(nextTurn)) {
                    this._changeTurn(nextTurn)
                    return
                }
            }
        }
    }

    _checkWinner() {
        let winners = []
        for (let i = 0; i < this.players.length; i++) {
            if ((this.players[i].centerCards.length === 0) && (this.players[i].playerCards.length === 0)) {
                winners.push(this.players[i].username)
            }
        }
        if (winners.length > 0) {
            this.turn = null
            winners.forEach(winner => console.log(`${winner} wins`))
            let messageWinners = () => {
                for (let i = 0; i < winners.length; i++) {
                    let messageChampions = this.createMessage(`${winners[i]} wins!`, 3000)
                    messageChampions.showMessage()
                }
            }
            messageWinners()
            this.callbackEnd(winners[0]);
            // setInterval(messageWinners, (winners.length + 1) * 3000)
        }
    }

    duelCards() {
        this._checkCards(this.orderDiscs[0], this.propCheck)
        this._updateAllCurrentsCards()
        this._updateAllCountersCards()
        this.players.forEach(player => player.disc.resetDisc())
        this.orderDiscs = []
        this._checkWinner()
        if (this.turn === null) {
            return
        } else {
            console.log(this.turn)
            console.log(`${this.players[this.turn - 1].username} it's your turn`)
            let messageTurn = this.createMessage(`${this.players[this.turn - 1].username} it's your turn`, 1500)
            this.callLater(() => { messageTurn.showMessage() }, 2000)
            this.callLater(() => { this.playCardNextComputerPlayer() }, 4000)
        }
    }

    duelDiscs() {
        console.log(`every players must throw the disc`)
        let fullOrderDiscs = this.orderDiscs
        for (let i = 0; i < this.players.length; i++) {
            if (!fullOrderDiscs.includes(this.players[i].id)) {
                fullOrderDiscs.push(this.players[i].id)
            }
        }
        let loser = fullOrderDiscs[this.players.length - 1]
        for (let i = 0; i < this.players.length; i++) {
            if (this.players[i].centerCards.length > 0) {
                let winner = this.players[i].id
                this._addCardsLoser(loser, winner)
            }
        }
        console.log(`PLAYER${loser-1} loses`)
        let messageLoser = this.createMessage(`${this.players[loser-1].username} loses`, 1500)
        this.callLater(() => { messageLoser.showMessage() }, 2000)
        this._updateAllCurrentsCards()
        this._updateAllCountersCards()
        this.players.forEach(player => player.disc.resetDisc())
        this.orderDiscs = []
        this._changeTurn(loser)
        this._checkWinner()
        if (this.turn === null) {
            return
        } else {
            console.log(`${this.players[this.turn - 1].username} it's your turn`)
            let messageTurn = this.createMessage(`${this.players[this.turn - 1].username} it's your turn`, 1500)
            this.callLater(() => { messageTurn.showMessage() }, 3500)
            this.playCardNextComputerPlayer()
        }
    }

    createMessage(txt, timeout) {
        let msg = new Message(txt, timeout)
        this.stack_messages.push(msg)
        return msg
    }

    callLater(call, delay) {
        this.timeouts.push(setTimeout(call, delay))
    }

    clearTimeouts() {
        for (var i = 0; i < this.timeouts.length; i++) {
            clearTimeout(this.timeouts[i]);
        }
    }

    clearMessages() {
        for (var i = 0; i < this.stack_messages.length; i++) {
            this.stack_messages[i].clear();
        }
    }

    clear() {
        this.clearTimeouts();
        this.clearMessages()
        for (let i = 0; i < this.players.length; i++) {
            this.players[i].clear()
        }
    }

}
export default IronSpeed;