import { FullscreenButton } from "../../../react-unboared";
import useFullscreen from "../../../hooks/useFullscreen";

const GFullscreenButton = ({ size, ...props }) => {
    const [isFullscreen, toggleFullscreen] = useFullscreen();
    return (<FullscreenButton
        {...props}
        isActive={isFullscreen} size={size}
        onClick={() => toggleFullscreen(document.documentElement)}
    />);
}

export default GFullscreenButton;