import styled from 'styled-components'

const StyledUsername = styled.div`
font-size:2.5rem;
`

const Username = ({ username, isMaster, ...props }) => {
    return (
        <StyledUsername {...props}>{username} {(isMaster ? "👑" : "")}</StyledUsername>
    )
}
export default Username;