import { useGamepadSession } from "../../unboared-com";

import Scenario from "./config/Scenario";
import UnboaredSpeedStyles from './UnboaredSpeedStyles'

const GamepadApp = () => {
    const scenario = useGamepadSession(state => state.scenario);

    return (
        <UnboaredSpeedStyles>
            <div className="gamepad">
                {Scenario[scenario].gamepad}
            </div>
        </UnboaredSpeedStyles>
    )
}

export default GamepadApp;
