import NextPlanIcon from '@mui/icons-material/NextPlan';
import { useGamepadCom } from "../../../../../unboared-com";
import { InGameNavLayout, NavButtonGamepad, UnboaredIcon, Header } from "../../../../../react-unboared";

import Pad from "../../../components/Pad/Pad";

const Gamepad = () => {
    const { emitAction } = useGamepadCom()

    const nav = (
        <NavButtonGamepad key={0} title={"Pass your turn"} icon={<UnboaredIcon MaterialIcon={NextPlanIcon} size={6} />} onClick={() => emitAction("pass")} />
    );

    return (
        <InGameNavLayout header={<Header />} nav={nav}>
            <Pad
                pressOK={() => emitAction('ok')}
                pressUp={() => emitAction('up')}
                pressDown={() => emitAction('down')}
                pressLeft={() => emitAction('left')}
                pressRight={() => emitAction('right')}
            />
        </InGameNavLayout>
    )
}

export default Gamepad;

