import { useState } from 'react';
import styled from 'styled-components'
// import { useSpring } from "react-spring";
import { DATA } from '../../config/Const';
// import { count } from '../../config/Tools';
import { useListener } from '../../../../unboared-com';
import {useWindowDimensions} from '../../../../react-unboared/'

import CodenamesCard from './CodenamesCard';

const StyledWordGrid = styled.div`
display:flex;
flex-wrap: wrap;

.card {
    position:relative;
    width:20%;
    height:${props => (props.cardHeight ? `${props.cardHeight}px` : "100%")};
    padding:0.5%;
}
`

const WordGrid = ({ currentTeam, words, colors, callbackState, ...props }) => {
    const { height } = useWindowDimensions();
    const [revealed, setRevealed] = useState(new Array(25).fill(false))
    const [current, setCurrent] = useState(0);

    // const styles = useSpring({
    //     from: { transform: "translate(-80%,0)" },
    //     to: [{ transform: "translate(0,0)" }],
    //     config: { duration: 500 },
    //     loop: false
    // });

    const reveal = () => {
        let color;
        let alreadyRevealed;
        setRevealed((prevReveal) => {
            const newReveals = [...prevReveal];
            setCurrent((selectedCard) => {
                alreadyRevealed = prevReveal[selectedCard];
                if (!alreadyRevealed) {
                    console.log(`reveal ${selectedCard}`);
                    newReveals[selectedCard] = true;
                    color = colors[selectedCard];
                }
                return selectedCard;
            })
            return newReveals;
        });
        console.log(`from ${color} team`);
        callbackState(color);
    }

    useListener("left", () => {
        setCurrent((prev) => {
            if (prev > 0) {
                return prev - 1;
            }
            return prev;
        })
    })

    useListener("right", () => {
        setCurrent((prev) => {
            if (prev < 24) {
                return prev + 1;
            }
            return prev;
        })
    })

    useListener("up", () => {
        setCurrent((prev) => {
            if (prev >= 5) {
                return prev - 5;
            }
            return prev + 20;
        })
    })

    useListener("down", () => {
        setCurrent((prev) => {
            if (prev + 5 < 25) {
                return prev + 5;
            }
            return prev - 20;
        })
    })

    useListener("ok", reveal)

    return (
        <StyledWordGrid className={props.className} cardHeight={0.2 * 0.8 * height}>
            {words.map((word, index) =>
                <CodenamesCard key={index} className='card' word={word} detective={DATA[colors[index]].card} active={index === current} color={DATA[currentTeam].color} revealed={revealed[index]} />
            )}
        </StyledWordGrid>
    );
}

export default WordGrid;
