import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useScreenScenario, useScreenSession, useListener, useGameState } from '../../../unboared-com'
import { device, PALETTE_COLOR, PLAYER_COLOR } from '../../../react-unboared';

import './game.scss'
import { PUBLIC_ASSETS } from '../../../config/Const';
import { GAME_NAME } from '../config/Const';
import { BlindTest } from '../jsx/BlindTest';
import { RingLoader } from 'react-spinners';
import EndGame from '../Endgame/Screen';


const StyledProposals = styled.div`
    height: 40%;
    opacity: 0.9;
    display: flex;
    flex-wrap: wrap;
    margin: 0 15rem;
    @media ${device.tablet} {
        margin: 0 2rem;
    }
    .proposal {
        display: flex;
        font-size: 3rem;
        margin: auto;
        width: 45%;
        max-height:40%;
        overflow: hidden;
        border-radius: 20px;
        @media ${device.laptop} {
            font-size: 2.2rem;
        }
            @media ${device.mobile} {
            margin-bottom: 2rem;
            width: 100%;
        }
    }
    .identifier {
        border-right: 0.8rem solid ${({ theme }) => theme.PRIMARY_BACKGROUND_COLOR};
    }
    .identifier,
    .value {
        padding: 1rem;
    }
`

const ScoreSection = ({ player, hasVoted, score }) => {
    const player_color = PLAYER_COLOR[player.id];
    const colorStyle = {
        border: `0.3rem solid ${player_color}`,
        backgroundColor: (hasVoted ? `${player_color}` : "unset")
    }
    return (
        <div className={"namePlayer"} style={colorStyle}>
            {player.username + " " + score}
        </div >
    );
}


const Proposal = ({ id, value }) => {
    const A_code = 65;
    return (
        <div className={"proposal proposal-" + id} style={{ backgroundColor: `${PALETTE_COLOR[id]}` }}>
            <div className={"identifier"}>
                {String.fromCharCode(A_code + id)}
            </div>
            <div className={"value"}>
                {value}
            </div>
        </div>
    );
}

function initState(players, defaultValue) {
    let state = {};
    for (const player of players) {
        state[player.id] = defaultValue;
    }
    return state;
}

let game = null;

const rewards = [5, 3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]

const Screen = (props) => {
    console.log("[Unblind Test] REWRITE Game")

    const statusPlayers = useScreenSession((state) => state.statusPlayers);
    const setLocalScenario = useScreenSession((state) => state.setLocalScenario);

    const [votes, setVotes] = useState(initState(statusPlayers.players, -1))
    const [scores, setScores] = useState(initState(statusPlayers.players, 0))

    const [loaded, setLoaded] = useState(false)


    const messageViewer = useRef(null);
    const timer = useRef(null);
    const [proposals, setProposals] = useState([])
    const setProposalRef = useRef(setProposals);
    const [canVote, setCanVote] = useGameState('canVote', false)

    const udpateProposals = (proposals) => {
        setProposalRef.current(proposals)
        console.log(`[Unblind Test] setCanVote ${canVote}`)
        setCanVote(true);
    }

    const updateViewer = (text) => {
        messageViewer.current.innerHTML = text;
    }

    const updateTimer = (text) => {
        timer.current.innerHTML = text;
    }

    const updateVote = (player, vote) => {
        console.log("[Unblind Test][updateVote] Player " + player['username'] + " vote " + vote);
        let playerID = player['id']
        if (game.currentMusic != null) {
            setVotes((prevVote) => {
                const newVotes = { ...prevVote };
                if (prevVote[playerID] === -1) {
                    // newVotes[playerID] = vote;
                    if (game.currentMusic === vote) {
                        newVotes[playerID] = rewards[Object.keys(newVotes).filter((key) => newVotes[key] !== -1).length];
                    }
                    else {
                        newVotes[playerID] = 0;
                    }

                }
                return newVotes;
            })
        }
    }

    const updateScores = () => {
        console.log("[Unblind Test][updateScores]")
        setCanVote(false);
        setScores((prevScores) => {
            const newScores = { ...prevScores };
            setVotes((prevVote) => {
                for (const [id, score] of Object.entries(prevScores)) {
                    console.log("vote " + prevVote[id] + " - " + game.currentMusic)
                    // if (game.currentMusic === prevVote[id]) {
                    newScores[id] = score + Math.max(0, prevVote[id]);
                    // }
                }
                return initState(statusPlayers.players, -1);
            })
            return newScores;
        })
    }

    // Update Scenario to category selection
    useScreenScenario(GAME_NAME, "game");

    // Listen the players votes 
    useListener("vote", (data) => updateVote(data['player'], data['vote']))

    const callAtTheEnd = () => {
        setScores((prevScores) => {
            setLocalScenario(GAME_NAME, "endgame", <EndGame scores={prevScores} players={statusPlayers.players} />);
            return prevScores;
        });
    }

    useEffect(() => {
        console.log("[Unblind Test] LOAD Game")
        fetch(PUBLIC_ASSETS + "songs/songs.json")
            .then(res => res.json())
            .then(data => {
                setLoaded(true);
                game = new BlindTest(data).init(props.category, statusPlayers.players).run(updateTimer, updateViewer, udpateProposals, updateScores, callAtTheEnd);
            })
        return () => { console.log("[Unblind Test] UNLOAD Game"); game.clear(); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!loaded) return <RingLoader />
    return (
        <>
            <div id="game-content">
                <div id="music-player">
                    <div ref={messageViewer} id="messageViewer">{GAME_NAME}</div>
                    <div ref={timer} id="timer">?</div>
                    <StyledProposals>
                        {
                            proposals.map((proposal, i) => (
                                <Proposal key={i} id={i} value={proposal.artist} />)
                            )
                        }
                    </StyledProposals>
                </div>
                <div className="score-section">
                    {
                        statusPlayers.players.map((player, i) => (
                            <ScoreSection key={i} player={player} score={scores[player.id]} hasVoted={votes[player.id] !== -1} />
                        ))
                    }
                </div>
            </div>
        </>
    );
}

export default Screen;